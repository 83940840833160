import styled from 'styled-components';
import { white, primary } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 90%;
  height: 100% !important;
  max-height: 100% !important;
  box-sizing: border-box;
  padding: 30px;
  padding-left: 90px;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const Div = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .containerTitle {
    display: flex;
    width: 40%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 63vh !important;
  max-height: 63vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${primary};
    border-radius: 10px;
  }

  .partnerIcon {
    height: 20px;
    width: 20px;
    border-radius: 2px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;

export const ModalButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const CountArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  font-size: 12px;

  p {
    margin: 0px;
  }
`;

export const WidgetArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0px;
`;

export const Widget = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 110px;
  background-color: #fefefe;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 12px;
  border-radius: 5px;

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1 {
    margin: 0px;
  }

  p {
    margin: 0px;
  }
`;
