import styled from 'styled-components';
import { white, darkGray, middleGray, lightGray, middleGraySecondary } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  box-sizing: border-box;
  padding: 30px 50px;

  header {
    padding: 5px 30px;
    box-sizing: border-box;
  }

  section {
    /* border: 1px solid ${lightGray}; */
    border-radius: 30px;
    box-sizing: border-box;
    /* padding: 20px; */
    width: 100%;
    margin: 10px;
    margin-top: 10px;
    margin-block-end:20px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  h1 {
    color: ${middleGraySecondary};
    font-size: 20px;
    margin: 0px;
    margin-bottom: 20px;
  }

  h2 {
    color: ${darkGray};
    font-size: 18px;
    margin: 0px;
    margin-bottom: 30px;
  }

  p {
    color: ${middleGray};
    margin: 10px 0px;
    font-size: 14px;
    font-weight: normal;
  }

  strong {
    color: ${middleGraySecondary};
    margin: 10px 0px;
    font-size: 14px;
    font-weight: bold;
  }

  span {
    color: ${darkGray};
  }

  table {
    margin-bottom: 20px;
  }

  td {
    font-size: 14px;
  }

  thead {
    color: ${middleGraySecondary} !important;
    font-weight: bold;
  }

  hr {
    border: none;
    border-top: 1px solid ${middleGray};
    height: 3px;
  }

  .printBrake {
    @media print {
      margin-top: 100px;
      margin-bottom: 30px;
    }
  }

  .logo {
    margin-bottom: 30px;
    width: 100px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media print {
    display: none;
  }
`;
