export const IOF = 0.0738;
export const ADJUST_VALUE = 0.931272;

export const formatPolicyStatus = (status) => PolicyStatus[status] || '';
export const formatProposalStatus = (status) => ProposalStatus[status] || '';
export const formatPartnerProduct = (product) => PartnerProduct[product] || '';

const ProposalStatus = {
  awaiting_inspection: 'Aguardando vistoria',
  awaiting_vistei: 'Aguardando aprovação de fotos',
  awaiting_complementar: 'Aguardando fotos complementares',
  awaiting_registration: 'Aguardando cadastro',
  awaiting_complementar_report: 'Aguardando aprovação das fotos complementares',
  awaiting_payment: 'Aguardando pagamento',
  activated: 'Ativada',
  rejected: 'Rejeitada',
  cancelled: 'Cancelada',
  expired: 'Expirada',
};

const PolicyStatus = {
  active: 'Ativo',
  canceled: 'Cancelado',
  awaiting_cancellation: 'Aguardando cancelamento',
};

const PartnerProduct = {
  corporate: 'Corporate',
  dealer: 'Dealer',
  runoff: 'RunOff',
  franchise: 'Franquias',
  advisory: 'Assessorias',
  enterprise: 'Enterprise',
};
