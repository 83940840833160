/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import api from 'api';
import PartnersData from 'utils/corporateSettings.json';
import { Header, Input, Button, Select, ConfirmDialog } from 'components';
import { Container, ContentArea, Title, LoadingArea, Subtitle, Form, FormPermissions, SelectLine, ButtonLine } from './styles';
import { success, white } from 'styles/colorProvider';
import { useHistory } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { formatRoleCategory } from 'utils/userCategory';
import { toast } from 'react-toastify';

function UserData({ location }) {
  const history = useHistory();
  const userId = parseInt(location.pathname.split('/detalhes-do-usuario/')[1]);

  const [loading, setLoading] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingUpdatePermissions, setLoadingUpdatePermissions] = useState(false);
  const [userData, setUserData] = useState({});
  const [featureData, setFeatureData] = useState();
  const [rolesData, setRolesData] = useState([]);
  const [selectedAccesGroup, setSelectedAccessGroup] = useState();
  const [appliedAccessGroup, setAppliedAccessGroup] = useState();
  const currentAccessGroup = rolesData.find((i) => i.id === userData.id_access_group);
  const [modal, setModal] = useState(false);
  const [confirmAmbient, setConfirmAmbient] = useState(false);

  const handleRecoveryPassword = async () => {
    setLoadingReset(true);
    const response = await api({
      method: 'GET',
      url: `/reset-password-backoffice/${userId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });
    toast.success('Senha resetada com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
    setModal(false);
    setLoadingReset(false);
  };

  const loadData = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/splitrisk-user/${userId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      params: {
        backoffice: true,
      },
      json: true,
    });

    const features = await api({
      method: 'GET',
      url: `/list-features`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });

    const roles = await api({
      method: 'GET',
      url: `/all-access-groups`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });

    setFeatureData(features.data);
    setRolesData(roles.data);
    setUserData({ ...response.data, birthdate: moment(response.data.birthdate).format('DD/MM/YYYY') });
    setSelectedAccessGroup(response.data.id_access_group);
    setAppliedAccessGroup(response.data.id_access_group);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChangeFeature = (feature) => {
    const isChecked = userData?.features?.findIndex((i) => i === feature) > -1;
    const curretFeaturesList = [...userData?.features];

    if (isChecked) {
      curretFeaturesList.splice(
        curretFeaturesList.findIndex((listItem) => listItem === feature),
        1,
      );
      setUserData({ ...userData, features: curretFeaturesList });
    } else {
      curretFeaturesList.push(feature);
      setUserData({ ...userData, features: curretFeaturesList });
    }
  };

  const handleApplyAccessGroup = (group) => {
    const accessGroup = rolesData.filter((i) => i.id === parseInt(group))[0];
    const features = accessGroup.id_features.split(',');
    const curretFeaturesList = features.map((i) => parseInt(i));
    setUserData({ ...userData, features: curretFeaturesList });
    setAppliedAccessGroup(parseInt(group));
  };

  const handleUpdateUser = async () => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'PATCH',
        url: `/user/${userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        params: {
          backoffice: true,
        },
        data: {
          ...userData,
          cpf: userData.cpf.replaceAll('.', '').replace('-', ''),
          phone: userData.phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
          birthdate: moment(userData.birthdate, 'DD/MM/YYYY'),
          gender: parseInt(userData.gender),
        },
        json: true,
      });
      setLoadingUpdate(false);
      toast.success('Usuário atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateUserPermissions = async () => {
    setLoadingUpdatePermissions(true);
    try {
      await api({
        method: 'PATCH',
        url: `/user-permissions/${userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          accessGroup: appliedAccessGroup,
          features: userData.features,
        },
        json: true,
      });
      setLoadingUpdatePermissions(false);
      toast.success('Permissões alteradas com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdatePermissions(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateUserPartner = async () => {
    setLoadingUpdatePermissions(true);
    try {
      await api({
        method: 'PATCH',
        url: `/update-user-partner/${userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id_partner: userData?.id_partner,
        },
        json: true,
      });
      setLoadingUpdatePermissions(false);
      setConfirmAmbient(false);
      toast.success('Ambiente alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdatePermissions(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const categories = [...new Set(featureData?.map((feature) => feature.category))].filter((i) => i !== 'user').sort();

  const renderConfirmDialogArea = () => (
    <ConfirmDialog
      open={modal}
      onCancel={() => setModal(false)}
      content="Tem certeza que deseja resetar a senha desse usuário? Será enviado uma senha provisória para o e-mail."
      title="Resetar senha"
      onConfirm={handleRecoveryPassword}
      loading={loadingReset}
    />
  );

  const renderConfirmPartner = () => (
    <ConfirmDialog
      open={confirmAmbient}
      onCancel={() => setConfirmAmbient(false)}
      content="Tem certeza que deseja alterar o ambiente deste usuário? Todas as informações serão migradas para o novo ambiente."
      title="Alterar ambiente"
      onConfirm={handleUpdateUserPartner}
      loading={loadingUpdatePermissions}
    />
  );

  return (
    <>
      {renderConfirmDialogArea()}
      {renderConfirmPartner()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={success} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <Title>
              <IconButton size="small" style={{ marginRight: 15 }} onClick={() => history.push('/backoffice/usuarios')}>
                <MdChevronLeft size={24} />
              </IconButton>
              DETALHES DO USUÁRIO
            </Title>

            <Subtitle>Dados pessoais</Subtitle>

            <Divider />

            <Form>
              <div class="grid-item">
                <Input
                  height="35px"
                  label="Nome"
                  placeholder="Nome"
                  value={userData?.name}
                  onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                />
              </div>
              <div class="grid-item">
                <Input
                  height="35px"
                  label="E-mail"
                  placeholder="E-mail"
                  value={userData?.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                />
              </div>
              <div class="grid-item">
                <Input
                  height="35px"
                  mask="(99) 99999-9999"
                  label="Telefone"
                  placeholder="Telefone"
                  value={userData?.phone}
                  onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                />
              </div>
              <div class="grid-item">
                <Input
                  height="35px"
                  mask="999.999.999-99"
                  label="CPF"
                  placeholder="CPF"
                  value={userData?.cpf}
                  onChange={(e) => setUserData({ ...userData, cpf: e.target.value })}
                />
              </div>
              <div class="grid-item">
                <Input
                  height="35px"
                  mask="99/99/9999"
                  label="Data de nascimento"
                  placeholder="Data de nascimento"
                  value={userData?.birthdate}
                  onChange={(e) => setUserData({ ...userData, birthdate: e.target.value })}
                />
              </div>
              <div class="grid-item">
                <Select
                  label="Sexo"
                  height="35px"
                  value={userData?.gender}
                  onChange={(e) => setUserData({ ...userData, gender: e.target.value })}
                >
                  <option value={1}>Masculino</option>
                  <option value={2}>Feminino</option>
                </Select>
              </div>
            </Form>

            <ButtonLine>
              <Button style={{ marginLeft: 0 }} height="35px" outlined disabled={loading} onClick={() => setModal(true)}>
                Resetar senha
              </Button>

              <Button height="35px" disabled={loadingUpdate} onClick={handleUpdateUser}>
                {loadingUpdate ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Alterar informações'}
              </Button>
            </ButtonLine>

            <Subtitle>Ambiente</Subtitle>

            <Divider />
            <Form>
              <div class="grid-item">
                <Select
                  label="Parceiro"
                  height="35px"
                  value={userData?.id_partner}
                  onChange={(e) => setUserData({ ...userData, id_partner: e.target.value })}
                >
                  {PartnersData?.map((partner) => (
                    <option value={partner?.id_partner}>{partner?.name}</option>
                  ))}
                </Select>
              </div>
            </Form>

            <ButtonLine>
              <Button
                style={{ marginLeft: 0, marginTop: -20 }}
                height="35px"
                disabled={loadingUpdate}
                onClick={() => setConfirmAmbient(true)}
              >
                Alterar ambiente
              </Button>
            </ButtonLine>

            <Subtitle>Permissões</Subtitle>
            <Divider />

            <SelectLine>
              <Select
                label="Aplicar grupo de acesso"
                height="30px"
                value={selectedAccesGroup}
                onChange={(e) => setSelectedAccessGroup(parseInt(e.target.value))}
              >
                {!currentAccessGroup && <option value="">Selecione</option>}
                {rolesData?.map((role) => (
                  <option value={role.id}>{role.name}</option>
                ))}
              </Select>
              <Button
                disabled={!selectedAccesGroup || selectedAccesGroup === '' || selectedAccesGroup === appliedAccessGroup}
                height="30px"
                style={{ margin: 0, marginBottom: 3, marginTop: 20 }}
                onClick={() => handleApplyAccessGroup(selectedAccesGroup)}
              >
                Aplicar
              </Button>
            </SelectLine>

            <FormPermissions style={{ paddingTop: 10 }}>
              {categories?.map((category) => (
                <div class="permission-section">
                  <h3>{formatRoleCategory(category)}</h3>
                  <div className="items-list">
                    {featureData
                      ?.filter((f) => f.category === category)
                      ?.map((feature) => (
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id={feature?.id}
                            name={feature?.id}
                            checked={userData?.features?.findIndex((i) => i === feature.id) > -1}
                            onChange={() => handleChangeFeature(feature?.id)}
                          />
                          <label for={feature?.id}>{feature?.name}</label>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </FormPermissions>
            <ButtonLine style={{ marginTop: 20 }}>
              <Button
                outlined
                height="35px"
                disabled={loadingUpdate}
                style={{ margin: 0, marginBottom: 20, marginRight: 10, marginLeft: 0 }}
                onClick={() => setUserData({ ...userData, features: [] })}
              >
                Limpar todas
              </Button>
              <Button
                height="35px"
                disabled={loadingUpdatePermissions}
                style={{ margin: 0, marginBottom: 20 }}
                onClick={handleUpdateUserPermissions}
              >
                {loadingUpdatePermissions ? (
                  <ReactLoading color={white} height={24} width={24} type="spin" />
                ) : (
                  'Alterar permissões'
                )}
              </Button>
            </ButtonLine>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default UserData;
