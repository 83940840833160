/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Content, Line, LoadingArea, ButtonArea } from './styles';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import api from 'api';
import moment from 'moment';
import logo from 'assets/logo-default.png';
import { formatPlate, formatCurrency } from 'react-data-formatter';
import { formatPolicyStatus } from 'utils/policyStatus';
import { loadingColor, white, middleGraySecondary } from 'styles/colorProvider';
import { Button } from 'components';
import { MdChevronLeft } from 'react-icons/md';
import { BsPrinter } from 'react-icons/bs';
import { toast } from 'react-toastify';

function PrintProposal({ location, history }) {
  const proposalId = parseInt(location.pathname.split('/detalhes-da-proposta/')[1]);

  const [proposalData, setProposalData] = useState({});
  const [quotationData, setQuotationData] = useState({});

  const [loading, setLoading] = useState(true);
  const [quotationLoading, setQuotationLoading] = useState(true);

  const loadData = async () => {
    const proposalNumber = {
      method: 'GET',
      url: `/proposal/${proposalId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };

    const response = await api(proposalNumber);

    setProposalData(response.data);
    setLoading(false);

    const teste = {
      comission: response?.data?.comission,
      client: {
        document: response?.data?.user?.cpf,
        ...response?.data?.user,
      },
      vehicle: {
        type: response?.data?.vehicle?.id_vehicle_type,
        id_brand: response?.data?.vehicle?.TB_CONSTANT_VEHICLE_BRANDS?.code,
        brand: response?.data?.vehicle?.TB_CONSTANT_VEHICLE_BRANDS?.name,
        id_model: response?.data?.vehicle?.id_model,
        model: response?.data?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.name,
        year: response?.data?.vehicle?.year_model,
        value: response?.data?.vehicle?.value,
        id_fuel: response?.data?.vehicle?.id_fuel,
        fuel: response?.data?.vehicle?.TB_CONSTANT_VEHICLE_FUELS?.name,
        fipe_code: response?.data?.vehicle?.fipe_code,
        id_vehicle_use: response?.data?.vehicle?.id_vehicle_use,
        armored: response?.data?.vehicle?.armored,
        build_year: response?.data?.vehicle?.manufacture_year,
        color: response?.data?.vehicle?.TB_CONSTANT_VEHICLE_COLOR?.name,
        chassi: response?.data?.vehicle?.chassi,
        city: response?.data?.TB_ADDRESS?.city,
        state: response?.data?.TB_ADDRESS?.state,
      },
      address: {
        zip_code: response?.data?.TB_ADDRESS?.zip_code,
        street: response?.data?.TB_ADDRESS?.street,
        number: response?.data?.TB_ADDRESS?.number,
        complement: response?.data?.TB_ADDRESS?.complement,
        district: response?.data?.TB_ADDRESS?.district,
        city: response?.data?.TB_ADDRESS?.city,
        state: response?.data?.TB_ADDRESS?.state,
      },
      products: response?.data?.coverages?.map((i) => i.id),
    };

    const quotationResponse = {
      method: 'POST',
      url: `/quotation-new`,
      data: teste,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
    };

    const responseQuotation = await api(quotationResponse);

    setQuotationData(responseQuotation.data);
    setQuotationLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  //

  return (
    <>
      {!loading && !quotationLoading && (
        <ButtonArea>
          <Button
            style={{ backgroundColor: middleGraySecondary, color: white }}
            height="30px"
            width="130px"
            onClick={() => window.print()}
          >
            <BsPrinter size="15" style={{ marginRight: 10 }} />
            Imprimir
          </Button>
        </ButtonArea>
      )}

      {loading && !quotationLoading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}

      {!loading && !quotationLoading && (
        <Container>
          <Content>
            <div className="header">
              <div>{<img src={logo} className="logo" alt="Logo" />}</div>
              <div className="on-top">
                {/* <img src={logo} className="logo" alt="Logo" /> */}
                <p>Split Risk Seguradora S.A.</p>
                <p>Processo Susep: 15414.618377/2020-87</p>
                <p>CNPJ: 43.505.273/0001-09</p>
              </div>
            </div>
            <p></p>
            <Line>
              <section>
                <h1>Dados do Segurado/Proponente</h1>

                <p className="p-right">
                  <span>
                    <b>Proponente:</b> {proposalData?.user?.name}{' '}
                  </span>
                  <span>
                    <b>CPF/CNPJ:</b> {proposalData?.user?.cpf}{' '}
                  </span>
                </p>

                <p className="p-right">
                  <span>
                    <b>Endereço de correspondência:</b> {proposalData?.TB_ADDRESS?.street}{' '}
                  </span>
                  <span>
                    <b>Número:</b> {proposalData?.TB_ADDRESS?.number}
                  </span>
                  <span>
                    <b>Complemento:</b> {proposalData?.TB_ADDRESS?.complement}
                  </span>
                </p>

                <p className="p-right">
                  <span>
                    <b>Bairro:</b> {proposalData?.TB_ADDRESS?.district}
                  </span>
                  <span>
                    <b>Cidade:</b> {proposalData?.TB_ADDRESS?.city}
                  </span>
                  <span>
                    <b>Estado:</b> {proposalData?.TB_ADDRESS?.state}
                  </span>
                  <span>
                    <b>CEP:</b> {proposalData?.TB_ADDRESS?.zip_code}
                  </span>
                </p>
                <p className="p-right">
                  <span>
                    <b>Telefone:</b> {proposalData?.user?.phone}
                  </span>
                  <span>
                    <b>E-mail:</b> {proposalData?.user?.email}
                  </span>
                </p>
                <p>
                  <span>
                    <b>Principal Condutor:</b> {proposalData?.user?.name}{' '}
                  </span>
                </p>
              </section>
            </Line>

            <Line>
              <section>
                <h1>Dados do veículo</h1>
                <p>
                  <span>
                    <b>Placa:</b>
                  </span>{' '}
                  {proposalData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(proposalData?.vehicle?.plate)}
                </p>
                <p>
                  <span>
                    <b>Marca e modelo:</b>
                  </span>{' '}
                  {proposalData?.vehicle?.brand} {proposalData?.vehicle?.model?.split(' ')[0]}
                </p>
                <p>
                  <span>
                    <b>Ano fabricação/modelo:</b>
                  </span>{' '}
                  {proposalData?.vehicle?.manufacture_year}/{proposalData?.vehicle?.year_model}
                </p>
                <p>
                  <span>
                    <b>Chassi:</b>
                  </span>{' '}
                  {proposalData?.vehicle?.chassi}
                </p>
                <p>
                  <span>GNV:</span> {proposalData?.vehicle?.gnv ? 'Sim' : 'Não'}
                </p>
                <p>
                  <span>
                    <b>
                      Valor<sup>*</sup>:
                    </b>{' '}
                  </span>
                  {proposalData?.vehicle && formatCurrency(proposalData?.vehicle?.value)}
                </p>
                <small>* Valor da Tabela Fipe do veículo na data da contratação.</small>
              </section>
            </Line>

            <Line>
              <section>
                <h1>Local de risco </h1>
                <p>
                  <span>
                    <b>CEP:</b> {proposalData?.TB_ADDRESS?.zip_code}
                  </span>
                </p>
                <p>
                  <span>
                    <b>Observações:</b>{' '}
                  </span>
                </p>
              </section>
            </Line>

            <Line>
              <section>
                <h1>Dados da cobertura</h1>

                <table>
                  <thead>
                    <td width={200}>Cobertura</td>
                    <td width={200}>Limite Máximo Indenização</td>
                  </thead>
                  <tbody>
                    {quotationData?.coverages?.map((product) => (
                      <tr>
                        <td>{product.name}</td>
                        <td>{formatCurrency(product.ebaotech.SumInsured)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            </Line>

            <Line>
              <section>
                <h1>Valores </h1>
                <p>
                  <span>
                    <b>Valor do prêmio:</b>
                  </span>{' '}
                  {proposalData?.value && formatCurrency(proposalData?.value)}
                </p>
                <p>
                  <span>
                    <b>IOF:</b>
                  </span>{' '}
                  {proposalData?.value && formatCurrency(proposalData?.value * 0.0738)}
                </p>
                <p>
                  <span>
                    <b>Taxa de juros:</b>
                  </span>{' '}
                  0%
                </p>
                <strong>
                  <b>Valor total:</b>
                  {proposalData?.value && formatCurrency(proposalData?.value + proposalData?.value * 0.0738)}
                </strong>
                <p></p>
              </section>
            </Line>

            <Line>
              <section>
                <h1>Forma de envio</h1>
                <p>
                  <span>
                    <b>Forma envio Apólice:</b> E-mail{' '}
                  </span>
                </p>
                <p>
                  <span>
                    <b>E-mail:</b> {proposalData?.user?.email}{' '}
                  </span>
                </p>
                <small>* As informações sobre a apólice serão enviadas para o email cadastrado </small>
              </section>
            </Line>

            <Line>
              <section>
                <h1>Split Risk</h1>
                <p>
                  <span>Autorização: </span>A Split Risk é uma seguradora com autorização temporária, participante do Sandbox
                  Regulatório, sob o Processo Susep nº 15414.617648/2019-43.
                </p>
                <p>
                  <span>PORTARIA SUSEP Nº 7.828: </span>
                  https://www.in.gov.br/web/dou/-/portaria-susep-n-7.828-de-30-de-julho-de-2021-337522495{' '}
                </p>
              </section>
            </Line>

            <Line>
              <section>
                <h1>Condições Gerais</h1>
                {quotationData.coverages.findIndex((i) => i.name === 'Colisão') > -1 ? (
                  <p>
                    <span>COLISÃO:</span> Contratando a cobertura de colisão o SEGURADO terá direito a uma indenização em virtude
                    de prejuízos ocasionados ao veículo segurado provenientes de: a- Colisão ou capotagem acidentais; b- Queda
                    acidental em precipícios ou de pontes; c- Queda acidental de qualquer agente externo sobre o veículo segurado,
                    desde que tal agente não faça parte integrante do veículo e não esteja nele afixado; d- Queda, sobre o veículo
                    segurado, da carga por ele transportada, em decorrência de acidente de trânsito e não por simples freada; e-
                    Acidente durante seu transporte por meio apropriado, como, exemplificativamente, cegonha ou guincho; f- Atos
                    danosos praticados por terceiros, exceto os constantes no item “Prejuízos não indenizáveis para todas as
                    coberturas”; g- Despesas necessárias ao socorro e salvamento do veículo em consequência de um dos riscos
                    cobertos; h- Despesas referentes a danos materiais comprovadamente causados pelo Segurado e/ou por terceiros
                    na tentativa de evitar o sinistro, minorar o dano ou salvar a coisa.{' '}
                  </p>
                ) : (
                  ''
                )}
                {quotationData.coverages.findIndex((i) => i.name === 'Incêndio') > -1 ||
                quotationData.coverages.findIndex((i) => i.name === 'Roubo') > -1 ||
                quotationData.coverages.findIndex((i) => i.name === 'Fenômenos naturais') > -1 ? (
                  <p>
                    <span>INCÊNDIO, ROUBO, FURTO E FENÔMENOS NATURAIS:</span>Contratando a cobertura de roubo, furto e incêndio, o
                    SEGURADO terá direito a uma indenização em virtude de prejuízos ocasionados ao veículo segurado provenientes
                    de: a- Roubo ou furto total do veículo segurado; b- Danos sofridos pelo veículo segurado durante o tempo em
                    que, como consequência de roubo ou furto esteve em poder de terceiros, deduzida da indenização a franquia
                    estipulada na apólice para o veículo; c- Incêndio ou explosão acidental; d- Despesas necessárias ao socorro e
                    salvamento do veículo em consequência de um dos riscos cobertos e decorrente de intervenções não humanas; e-
                    Despesas referentes a danos materiais comprovadamente causados pelo Segurado e/ou por terceiros na tentativa
                    de evitar o sinistro, minorar o dano ou salvar a coisa; f- Roubo ou furto total exclusivo do rádio,
                    toca-fitas, toca cd’s, tacógrafo e kit gás, desde que tais itens façam parte do modelo original do veículo,
                    descontada do valor da indenização a franquia estipulada na apólice para o veículo.{' '}
                  </p>
                ) : (
                  ''
                )}
                {quotationData.coverages.findIndex((i) => i.name === 'Assistência 24 horas') > -1 ? (
                  <p>
                    <span>ASSISTÊNCIA 24 (VINTE E QUATRO) HORAS:</span> Prestação e serviço de assistência 24 horas aos SEGURADOS
                    em caráter emergencial mediante a impossibilidade de locomoção do veículo em acidentes, panes, roubos ou
                    furtos e fenômenos naturais, devendo ser solicitado através dos meios oficiais de comunicação, chat, WhatsApp
                    ou e-mail contato@splitrisk.com.br.{' '}
                  </p>
                ) : (
                  ''
                )}
                {quotationData.coverages.findIndex((i) => i.name === 'Faróis, lanternas e vidros ') > -1 ? (
                  <p>
                    <span>DANOS A VIDROS, FARÓIS E LANTERNAS:</span> Cobertura dos vidros laterais, traseiro, para-brisa, faróis,
                    lanternas e vidros dos retrovisores no valor máximo de R$ 1.500,00 (um mil e quinhentos reais) por item. A
                    franquia será de 20% (vinte por cento) do valor do item substituído, sendo no mínimo de R$ 60,00 (sessenta
                    reais), limitada a 2 (duas) indenizações no período de 12 (doze) meses, contados a partir da abertura do
                    primeiro evento.
                  </p>
                ) : (
                  ''
                )}
                {quotationData.coverages.findIndex((i) => i.name === 'APP - Morte') > -1 ? (
                  <p>
                    <span>APP (ACIDENTE PESSOAL POR PASSAGEIRO E DESPESAS MÉDICAS E HOSPITALARES):</span> APP (Acidente Pessoal
                    por Passageiro) Cobertura no valor de R$ 10.000,00 (dez mil reais) por passageiro do veículo integrante da
                    relação associativista, em caso de morte ou invalidez permanente. Em caso de invalidez parcial o pagamento
                    será proporcional respeitando tabela DPVAT ou similar. O APP é limitado ao valor de R$ 50.000,00 (cinquenta
                    mil reais) por veículo. O ressarcimento de despesas médicas/hospitalares é de até R$ 2.000,00 (dois mil reais)
                    por passageiro, limitado ao valor de R$ 10.000,00 (dez mil reais) por veículo, exceto passageiros de
                    motocicleta tendo em vista que os mesmos não fazem jus a referida cobertura. Os ressarcimentos serão avaliados
                    mediante comprovação documentada exigida pela empresa responsável pelo APP. Em caso de vistoria de carro 0
                    (zero) KM, ou seja, veículo novo, o APP somente ficará vigente após o efetivo emplacamento do veículo e envio
                    de nova vistoria, nos moldes deste regimento.{' '}
                  </p>
                ) : (
                  ''
                )}
                {quotationData.coverages.findIndex((i) => i.name === 'Danos a terceiros') > -1 ? (
                  <p>
                    <span>DANOS A TERCEIROS:</span> É limitado ao valor máximo de R$ 50.000,00 (cinquenta mil reais) por
                    acionamento para o veículo SEGURADO. Referido adicional, apenas se aplica, em caso de aprovação do sinistro,
                    que o SEGURADO tenha dado causa, não sendo aplicável, para caso de negativa de sinistro. Referidos valores,
                    dizem respeito apenas para danos materiais, não sendo possível reparação por danos morais, estéticos, médicos
                    e ou quaisquer outras finalidades, em conformidade com as condições gerais da SEGURADORA.{' '}
                  </p>
                ) : (
                  ''
                )}
                {quotationData.coverages.findIndex((i) => i.name === 'Carro reserva 15 dias') > -1 ||
                quotationData.coverages.findIndex((i) => i.name === 'Carro reserva 30 dias') > -1 ? (
                  <p>
                    <span>CARRO RESERVA:</span> Carro reserva só pode ser solicitado uma vez no período de 30 (trinta) dias em
                    casos de roubo, furto, colisão, incêndio ou fenômenos naturais indenizados pela SEGURADORA mediante aprovação
                    do sinistro e apresentação de boletim de ocorrência, respeitando todas as regras determinadas pelas locadoras
                    indicadas, em conformidade com as condições gerais da SEGURADORA.
                  </p>
                ) : (
                  ''
                )}
              </section>
            </Line>
          </Content>
        </Container>
      )}
    </>
  );
}

export default PrintProposal;
