/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BackofficeMenu from 'components/BackofficeMenu';
import moment from 'moment';
import api from 'api';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { Header } from 'components';
import {
  Container,
  ContentArea,
  Title,
  PolicyInformationArea,
  ItemPolicy,
  Subtitle,
  Text,
  LoadingArea,
  InspectionImage,
  PhotosArea,
  Card,
  ModalButton,
  Edit,
} from './styles';
import { BsShieldFillCheck, BsBoxArrowUpRight, BsShieldFillX, BsShieldFillExclamation } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { formatCPF, formatPlate, formatCurrency, formatPhone } from 'react-data-formatter';
import { loadingColor } from 'styles/colorProvider';
import { formatPolicyStatus } from 'utils/policyStatus';
import { ConfirmDialog, Button, Input, Dialog } from 'components';
import { getCardFlag } from 'utils/creditCard';
import { ADJUST_VALUE, IOF } from 'utils/general';
import { cpf } from 'cpf-cnpj-validator';
import { Alert } from '@material-ui/lab';

export const translateInspectionStatus = {
  approved: 'Aprovado',
  rejected: 'Rejeitada',
  pending: 'Pendente',
  pending_approval: 'Aguardando avaliação de fotos',
};

function PolicyDetails({ location }) {
  const policyId = parseInt(location.pathname.split('/backoffice/detalhes-da-apolice/')[1]);

  const [policyData, setPolicyData] = useState({});

  const [reportUrl, setReportUrl] = useState(null);
  const [loadingGetReport, setLoadingGetReport] = useState(false);

  const [confirmClaimPolicy, setConfirmClaimPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientActiveCard, setClientActiveCard] = useState();
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [userData, setUserData] = useState();
  const [policyInfo, setPolicyInfo] = useState({
    adjustmentFactor: '70%',
    adjustmentFactorValue: 70,
    userBetween18and25Years: false,
    franchiseValue: 'Normal-Obrigatória 10%',
  });

  const loadClientActiveCard = async () => {
    try {
      const response = await api({
        method: 'GET',
        url: `/policy-active-card/${policyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        json: true,
      });
      setClientActiveCard(response.data);
    } catch (error) {}
  };

  const loadData = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/splitrisk-policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });
    let valueService = 0;
    let valueCoverage = 0;
    let hasCoverages = false;
    let hasServices = false;

    for (const item of response.data?.arrayUnique || []) {
      if (item.isService) {
        hasServices = true;
        valueService += Number(item.value);
      } else {
        hasCoverages = true;
        valueCoverage += Number(item.value);
      }
    }
    if (response.data?.products) {
      for (const item of response.data?.products || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
    }
    if (response.data?.coverages) {
      for (const item of response.data?.coverages || []) {
        if (item.isService) {
          hasServices = true;
          valueService += Number(item.value);
        } else {
          hasCoverages = true;
          valueCoverage += Number(item.value);
        }
      }
    }

    if (response.data?.coverages?.length) {
      hasCoverages = true;
      setValueCoverage(response?.data?.value_final);

      if (response?.data?.TB_PROPOSAL) {
        response.data.TB_PROPOSAL.iof = response?.data?.TB_PROPOSAL?.value_final * IOF;
      }
    } else {
      setValueCoverage(valueCoverage);
    }

    setPolicyData(response.data);

    setUserData(response.data.user);

    setHasCoverages(hasCoverages);
    setHasServices(hasServices);
    setLoading(false);
    setValueService(valueService);
  };

  const handleCreateClaimPolicy = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'post',
        url: `/claim-policy-mark`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id: policyId,
        },
      });
      setConfirmClaimPolicy(resp);
      setLoading(false);
      toast.success('Apólice com sinistro!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setConfirmClaimPolicy(false);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
      setConfirmClaimPolicy(false);
    }
  };

  const handleUpdateProposal = async () => {
    setLoading(true);
    try {
      await api({
        method: 'PATCH',
        url: `/backoffice-update-user/${userData.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          ...userData,
          social_name: userData?.social_name?.toUpperCase(),
        },
        json: true,
      });
      setLoading(false);
      setOpenUser(false);
      toast.success('Usuário atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleOpenEditUser = () => {
    setOpenUser(true);
  };

  const handleGetReport = async (protocol) => {
    if (reportUrl) {
      window.open(reportUrl, '_blank');
      return;
    }
    setLoadingGetReport(true);
    try {
      const response = await api({
        method: 'GET',
        url: `/inspections/${protocol}/report`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      setLoadingGetReport(false);
      setReportUrl(response?.data.reportUrl);
      window.open(response?.data.reportUrl, '_blank');
    } catch (e) {
      toast.error('Essa vistoria não possui relatório na InfoVist', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingGetReport(false);
    }
  };

  const renderConfirmClaimPolicy = () => (
    <ConfirmDialog
      open={confirmClaimPolicy}
      onCancel={() => setConfirmClaimPolicy(false)}
      content="Ao confirmar a apólice não será renovada automáticamente"
      title="Apólice com Sinistro"
      onConfirm={handleCreateClaimPolicy}
      loading={loading}
    />
  );

  useEffect(() => {
    loadClientActiveCard();
    loadData();
  }, []);

  const iof = 0.078;

  useEffect(() => {
    // LMI LOGIC
    const auctionOrRemarked = policyData?.vehicle?.auction || policyData?.vehicle?.remarked;
    // Starts with 70% <- Auction or Remarked
    let adjustmentFactor = '70%';
    let adjustmentFactorValue = 70;

    if (!auctionOrRemarked) {
      const adjustmentsFactors =
        policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_FIPE_LMI_TB_QUOTATION_PROPOSAL?.filter(
          (item) => item?.active,
        );

      // If has valid LMI or 100%
      adjustmentFactor = adjustmentsFactors?.[0]?.TB_PRICING_FIPE_LMI?.name || '100%';
      adjustmentFactorValue = adjustmentsFactors?.[0]?.TB_PRICING_FIPE_LMI?.lmi || 100;
    }

    // Franchise Logic
    const showFranchiseValue =
      policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE_TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE
        ?.enabled;

    const franchiseValue =
      policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.TB_PRICING_DEDUCTIBLE_TB_QUOTATION_PROPOSAL?.filter(
        (c) => c?.active,
      )?.[0]?.TB_PRICING_DEDUCTIBLE?.name;
    // Apólices antigas
    const oldFranchiseValue = policyData.coverages?.find((c) => c?.name?.includes('Franquia'))?.name_complete;

    // User Between 18 and 25 years
    const userBetween18and25Years = policyData?.TB_PROPOSAL?.TB_QUOTATION_PROPOSAL?.[0]?.some_driver_18_25_years_old;

    setPolicyInfo({
      ...policyInfo,
      adjustmentFactor,
      adjustmentFactorValue,
      userBetween18and25Years,
      franchiseValue:
        showFranchiseValue && franchiseValue ? franchiseValue : oldFranchiseValue ? oldFranchiseValue : 'Normal-Obrigatória 10%',
    });
  }, [policyData]);

  const isCpf = cpf.isValid(policyData?.user?.cpf);

  return (
    <>
      {renderConfirmClaimPolicy()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}

        {!loading && (
          <ContentArea>
            <BackofficeMenu policyId={policyId} policyData={policyData} />
            <Title>DETALHES DA APÓLICE</Title>
            {policyData?.status === 'active' && policyData?.not_renew === true && (
              <Alert style={{ marginBottom: 20 }} severity="warning">
                Cancelamento solicitado - Sua vigência é válida até o próximo vencimento{' '}
                {moment(policyData.next_due).format('DD/MM/YYYY')}
              </Alert>
            )}

            <PolicyInformationArea>
              <ItemPolicy>
                <Subtitle>Dados da apólice - {policyData?.subscription}</Subtitle>
                {policyData?.claim_notice === true ? (
                  <>
                    <Text>
                      Apólice com sinistro: <span>Sim</span>
                    </Text>
                  </>
                ) : (
                  <>
                    <Text>
                      Apólice com sinistro: <span>Não</span>
                    </Text>
                  </>
                )}

                <Text>
                  Status: <span>{formatPolicyStatus(policyData?.status)?.label} </span>
                </Text>

                <Text>
                  Protocolo: <span>{policyData?.policy_number}</span>
                </Text>
                <Text>
                  Apólice: <span>{policyData?.code_ebao}</span>
                </Text>
                {hasCoverages && (
                  <Text>
                    Valor Coberturas:{' '}
                    <span>{`${formatCurrency(
                      (policyData?.isHinova ? policyData?.valueFinal : valueCoverage) - policyData?.TB_PROPOSAL?.iof,
                    )}`}</span>
                  </Text>
                )}
                {hasCoverages && (
                  <Text>
                    Valor IOF sobre Coberturas: <span>{`${formatCurrency(policyData?.TB_PROPOSAL?.iof || 0)}`}</span>
                  </Text>
                )}
                {hasServices && (
                  <Text>
                    Valor Serviços: <span>{`${formatCurrency(valueService)}`}</span>
                  </Text>
                )}
                <Text>
                  Valor Total do Seguros: <span>{`${formatCurrency(policyData?.valueFinal || 0)}`}</span>
                </Text>
                <Text>
                  Data final de vigência: <span>{moment(policyData?.expiration_date).format('DD/MM/YYYY')}</span>
                </Text>
                <Text>
                  Fator de ajuste LMI: <span>{policyInfo?.adjustmentFactor}</span>
                </Text>
                <Text>
                  Franquia: <span>{policyInfo?.franchiseValue}</span>
                </Text>
                <Text>
                  Estado de Circulação: <span>{policyData?.TB_ADDRESS?.state}</span>
                </Text>

                {policyData?.status !== 'canceled' && policyData?.claim_notice !== true && (
                  <>
                    <Button className="buttonCancelProposal" onClick={() => setConfirmClaimPolicy(true)}>
                      Apólice com sinistro
                    </Button>
                  </>
                )}
              </ItemPolicy>

              <ItemPolicy>
                {<Subtitle>Apólice gerada em:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.created_at).format('DD/MM/YYYY')}</Text>

                {policyData?.status !== 'active' ? <Subtitle>Vencido em</Subtitle> : <Subtitle>Inicio da vigência:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>
                  {moment
                    .utc(policyData?.expiration_date)
                    .subtract(1, 'months')
                    .set('date', moment.utc(policyData?.created_at).date())
                    .format('DD/MM/YYYY')}
                </Text>

                {policyData?.status !== 'active' ? <Subtitle>Vencido em</Subtitle> : <Subtitle>Fim da vigência:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.expiration_date).format('DD/MM/YYYY')}</Text>

                {policyData?.status !== 'active' ? <Subtitle>Vencido em</Subtitle> : <Subtitle>Próximo vencimento:</Subtitle>}
                <Text style={{ marginBottom: '1.3rem' }}>{moment.utc(policyData?.next_due).format('DD/MM/YYYY')}</Text>

                <Subtitle>Cobrança automática:</Subtitle>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                  <Text style={{ margin: '0 1px 0 0' }}>{policyData?.automatic_charge ? 'Ativa' : 'Inativa'}</Text>
                </div>

                {policyData?.automatic_charge && clientActiveCard && (
                  <Card>
                    <Text>
                      Cartão:
                      <span> **** **** **** {clientActiveCard?.last_four_digits}</span>
                    </Text>
                    <div>{getCardFlag(clientActiveCard?.last_four_digits, clientActiveCard?.brand).image}</div>
                  </Card>
                )}
              </ItemPolicy>

              <ItemPolicy>
                <Edit>
                  <Subtitle>Informações do segurado</Subtitle>
                  <Button height="25px" width="55px" onClick={handleOpenEditUser}>
                    Editar
                  </Button>
                </Edit>
                <Text>
                  Nome do segurado: <span>{policyData?.user?.name}</span>
                </Text>
                <Text>
                  Nome social: <span>{policyData?.user?.social_name}</span>
                </Text>
                <Text>
                  CPF: <span>{formatCPF(policyData?.user?.cpf)}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Data de nascimento: <span>{moment.utc(policyData?.user?.birthdate).format('DD/MM/YYYY')}</span>
                  </Text>
                )}
                <Text>
                  Telefone: <span>{formatPhone(policyData?.user?.phone)}</span>
                </Text>
                <Text>
                  E-mail: <span>{formatCPF(policyData?.user?.email)}</span>
                </Text>
                <Text>
                  Endereço:
                  <br />
                  {' - '}Estado:<span> {policyData?.TB_ADDRESS?.state}</span>
                  <br />
                  {' - '}Cidade:<span> {policyData?.TB_ADDRESS?.city}</span>
                </Text>

                <Dialog open={openUser} onClose={() => setOpenUser(false)} width="50%" title="Editar segurado">
                  <Input
                    widht="30%"
                    label="Nome social"
                    value={userData?.social_name}
                    onChange={(e) => setUserData({ ...userData, social_name: e.target.value.toUpperCase() })}
                    height="30px"
                  />

                  <ModalButton>
                    <Button
                      height="35px"
                      onClick={handleUpdateProposal}
                      disabled={!userData?.name || !userData?.cpf || !userData?.email || !userData?.phone || !userData?.birthdate}
                    >
                      Editar
                    </Button>
                  </ModalButton>
                </Dialog>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do condutor principal</Subtitle>
                <Text>
                  Nome do segurado: <span>{policyData?.proposal?.maindriver_name || policyData?.user?.name}</span>
                </Text>
                <Text>
                  CPF: <span>{formatCPF(policyData?.proposal?.maindriver_document || policyData?.user?.cpf)}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Data de nascimento:{' '}
                    <span>
                      {moment.utc(policyData?.proposal?.maindriver_birthdate || policyData?.user?.birthdate).format('DD/MM/YYYY')}
                    </span>
                  </Text>
                )}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do veículo</Subtitle>
                <Text>
                  Marca e modelo: <span>{`${policyData?.vehicle?.brand}/${policyData?.vehicle?.model}`}</span>
                </Text>
                <Text>
                  Placa:{' '}
                  <span>{policyData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(policyData?.vehicle?.plate)}</span>
                </Text>
                <Text>
                  Ano e modelo: <span>{`${policyData?.vehicle?.manufacture_year} / ${policyData?.vehicle?.year_model}`}</span>
                </Text>
                <Text>
                  Chassi: <span>{formatPlate(policyData?.vehicle?.chassi)}</span>
                </Text>
                <Text>
                  Uso do veículo: <span>{policyData?.vehicle?.vehicle_use?.name}</span>
                </Text>
                <Text>
                  Cor do veículo: <span>{policyData?.vehicle?.TB_CONSTANT_VEHICLE_COLOR?.name}</span>
                </Text>
                <Text>
                  Veículo de leilão: <span>{policyData?.vehicle?.auction ? 'SIM' : 'NÃO'}</span>
                </Text>
                <Text>
                  Chassis Remarcado/Veiculo Recuperado de Sinistro: <span>{policyData?.vehicle?.remarked ? 'Sim' : 'Não'}</span>
                </Text>
                <Text>
                  GNV: <span>{policyData?.vehicle?.gnv ? 'SIM' : 'NÃO'}</span>
                </Text>
                <Text>
                  Condutor entre 18 e 25 anos: <span>{policyInfo?.userBetween18and25Years ? 'SIM' : 'NÃO'}</span>
                </Text>
                <Text>
                  Valor FIPE: <span>{formatCurrency(policyData?.vehicle?.value || 0)}</span>
                </Text>
                <Text>
                  LMI {policyInfo?.adjustmentFactor}:{' '}
                  <span>{formatCurrency(policyData?.vehicle?.value * (policyInfo?.adjustmentFactorValue / 100) || 0)}</span>
                </Text>
              </ItemPolicy>

              <ItemPolicy>
                {hasCoverages && <Subtitle>Coberturas</Subtitle>}
                {policyData?.products?.length &&
                  policyData?.products
                    .filter((coverage) => coverage.isService === false)
                    .map((coverage) => <Text>• {coverage.name} </Text>)}
                {policyData?.coverages?.length &&
                  policyData?.coverages
                    .filter((product) => product.isService === false)
                    .map((product) => <Text>• {product.name} </Text>)}
                {policyData?.arrayUnique?.length &&
                  policyData?.arrayUnique
                    .filter((array) => array.isService === false)
                    .map((array) => <Text>• {array.name} </Text>)}
                {hasServices && <Subtitle style={{ paddingTop: '10px' }}>Serviços</Subtitle>}
                {policyData?.products?.length &&
                  policyData?.products
                    .filter((coverage) => coverage.isService === true)
                    .map((coverage) => <Text>• {coverage.name} </Text>)}
                {policyData?.coverages?.length &&
                  policyData?.coverages
                    .filter((product) => product.isService === true)
                    .map((product) => <Text>• {product.name} </Text>)}
                {policyData?.arrayUnique?.length &&
                  policyData?.arrayUnique
                    .filter((array) => array.isService === true)
                    .map((array) => <Text>• {array.name} </Text>)}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Vistoria</Subtitle>
                <Text>
                  Código: <span>{policyData?.inspection?.inspection_code || 'N/A'}</span>
                </Text>
                <Text>
                  Status: <span>{translateInspectionStatus[policyData?.inspection?.status] || 'N/A'}</span>
                </Text>
                <Text>
                  Solicitada em:{' '}
                  <span>
                    {policyData?.inspection?.created_at ? moment(policyData?.inspection?.created_at).format('DD/MM/YYYY') : 'N/A'}
                  </span>
                </Text>
                <Text>
                  Finalizada em:{' '}
                  <span>
                    {policyData?.inspection?.result_date
                      ? moment(policyData?.inspection?.result_date).format('DD/MM/YYYY')
                      : 'N/A'}
                  </span>
                </Text>
                {policyData?.inspection?.result_date && (
                  <Button
                    height="25px"
                    width="200px"
                    loading={loadingGetReport}
                    onClick={() => handleGetReport(policyData?.inspection?.inspection_code)}
                  >
                    &nbsp; Visualizar documento &nbsp; <IoDocumentTextOutline />
                  </Button>
                )}
              </ItemPolicy>

              {/* ENDEREÇO */}
              <ItemPolicy>
                <Subtitle>Endereço do segurado</Subtitle>

                <Text>{/* CEP: <span>{formatZipCode(policyData?.address?.zip_code)}</span> */}</Text>
                <Text>
                  Endereço: <span>{policyData?.TB_ADDRESS?.street}</span>
                </Text>
                <Text>
                  Número:{' '}
                  <span>
                    {policyData?.TB_ADDRESS?.number} {policyData?.address?.complement}
                  </span>
                </Text>
                <Text>
                  Bairro: <span>{policyData?.TB_ADDRESS?.district}</span>
                </Text>
                <Text>
                  Cidade/UF:{' '}
                  <span>
                    {policyData?.TB_ADDRESS?.city}/{policyData?.TB_ADDRESS?.state}
                  </span>
                </Text>
                <Text>
                  Complemento: <span>{policyData?.TB_ADDRESS?.complement}</span>
                </Text>
              </ItemPolicy>
            </PolicyInformationArea>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default PolicyDetails;
