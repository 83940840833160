import React, { useState } from 'react';
import { Container, Form } from './styles';
import { FiX } from 'react-icons/fi';
import PaymentMethod from '../PaymentMethod';
import Input from 'components/Input/index';
import Button from 'components/Button/index';

function Payment({ handleCloseModal }) {
  const [step, setStep] = useState('payment methods');

  const [cardNumber, setCardNumber] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [cardholderName, setCardholderName] = useState();

  return (
    <Container>
      {step === 'payment methods' && (
        <Form>
          <div className="closeArea">
            <FiX size="24" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
          </div>
          <div className="areaLogo">
            <h2>COMO VOCÊ QUER PAGAR?</h2>
          </div>
          <div className="paymentMethods">
            <PaymentMethod type="boleto" />
            <PaymentMethod type="pix" />
            <PaymentMethod type="cartão de débito" setStep={setStep} nextStep={'card payment details'} />
            <PaymentMethod type="cartão de crédito" setStep={setStep} nextStep={'card payment details'} />
          </div>
        </Form>
      )}

      {step === 'card payment details' && (
        <Form>
          <div className="closeAreaPaymentData">
            <FiX size="24" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
          </div>
          <div className="areaLogo">
            <h2>INSIRA SEUS DADOS DE PAGAMENTO</h2>
          </div>
          <div className="cardDataContainer">
            <Input
              label="Número do cartão"
              placeholder="1234 5678 9012 3456"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              mask={'9999 9999 9999 9999'}
              card="true"
            />
            <div className="twoInputArea">
              <Input
                label="Data de validade"
                placeholder="MM/AA"
                width="130px"
                value={expirationDate}
                onChange={(e) => setExpirationDate(e.target.value)}
                mask={'99/99'}
              />
              <Input
                label="CVC/CVV"
                placeholder="123"
                width="130px"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                mask={'999'}
              />
            </div>
            <Input
              label="Nome do titular do cartão"
              placeholder="Nome como no cartão"
              value={cardholderName}
              onChange={(e) => setCardholderName(e.target.value)}
            />
          </div>
          <Button onClick={() => {}}>Realizar pagamento</Button>
        </Form>
      )}
    </Container>
  );
}

export default Payment;
