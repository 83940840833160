import styled from 'styled-components';
import { middleGray, lightGray } from 'styles/colorProvider';
import logoReduced from 'assets/logo-reduced.png';
import backgroundLogin from 'assets/backgroundLogin.png';
import logoDefault from 'assets/logo-default.png';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 52%;
  height: 100%;
  background-image: url(${backgroundLogin});
  background-size: cover;

  div {
    height: 160px;
    width: 224px;
    background-image: url(${logoReduced});
    background-size: cover;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;

  .line {
    width: 139px;
    border-top: 1px solid ${lightGray};
    margin-top: 2%;

    @media (max-width: 1000px) {
      margin-top: 12%;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
  }

  .areaLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .logo {
    height: 36px;
    width: 209px;
    background-image: url(${logoDefault});
    background-size: cover;
  }

  .buttonArea {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    @media (max-width: 1000px) {
      margin-top: 20px;
      width: 100%;
    }
  }
`;

export const BackToLogin = styled.a`
  margin-top: 5%;
  color: ${middleGray};
  font-size: 14px;
  cursor: pointer;

  @media (max-width: 1000px) {
    margin-top: 30%;
  }
`;

export const ResetPassword = styled.div`
  .description {
    width: 320px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0;

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }

    h2 {
      font-weight: 700;
      font-size: 28px;
      color: ${middleGray};
      text-align: center;
      text-transform: uppercase;
      margin-top: 4%;
      margin-bottom: 1%;

      @media (max-width: 1000px) {
        font-size: 23px;
      }
    }

    p {
      @media (max-width: 1000px) {
        margin-top: 3%;
      }
    }
  }
`;
