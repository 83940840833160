import moment from 'moment';

export const dataColumns = [
  {
    name: 'Cód. vistoria',
    selector: (row) => row?.inspection_code,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => row?.status,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Solicitada em',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '20%',
    sortable: true,
  },
  {
    name: 'Finalizada em',
    selector: (row) => (row?.result_date ? moment(row?.result_date).format('DD/MM/YYYY HH:mm') : 'Não finalizada'),
    width: '20%',
    sortable: true,
  },
];
