/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import api from 'api';
import { Header, SideMenu, AgentItem, Search, Button, Input, Dialog, Select } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';
import { TiFilter } from 'react-icons/ti';

function Agents() {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [agentsList, setAgentsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();
  const [open, setOpen] = useState(false);
  const [selectStatus, setSelectStatus] = useState('all');
  const [startData, setStartdata] = useState();
  const [endData, setEndData] = useState();
  const [touchFilter, setTouchFilter] = useState();

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/backoffice-list-agents`,
      headers: {
        'Content-Type': 'application/json',
        // Authorization: Cookies.get('splitrisk-authtoken'),
      },
      params: {
        search,
        startdate: startData === '' ? undefined : startData,
        enddate: endData === '' ? undefined : endData,
        page: currentPage,
        take: 10,
      },
      json: true,
    });

    setAgentsList(resp?.data?.agents);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
    setTouchFilter(false);
  };

  const handleLoadMore = async () => {
    if (!touchFilter) {
      const page = currentPage + 1;
      if (hasNextPage && !loadingMore) {
        setLoadingMore(true);

        const resp = await api({
          method: 'GET',
          url: `/backoffice-list-agents`,
          headers: {
            'Content-Type': 'application/json',
            // Authorization: Cookies.get('splitrisk-authtoken'),
          },
          params: {
            search,
            startdate: startData === '' ? undefined : startData,
            enddate: endData === '' ? undefined : endData,
            page,
            take: 10,
          },
          json: true,
        });

        const data = agentsList;
        const ndata = data.concat(resp?.data?.agents);

        setCurrentPage(page);
        setAgentsList(ndata);
        setHasNextPage(resp?.data?.hasNextPage);
        setLoadingMore(false);
      }
    }
  };

  const handleClear = () => {
    setSelectStatus('all');
    setStartdata('');
    setEndData('');
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleOpenFilter = () => {
    setOpen(true);
  };

  const renderFilterDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} width="50%" title="Filtrar apólice">
      <Select height="30px" label="Filtrar por status" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
        <option value={'all'}>Todos os Status</option>
        <option value={'active'}>Ativo</option>
        <option value={'canceled'}>Cancelado</option>
      </Select>

      <Input
        value={startData}
        onChange={(e) => setStartdata(e.target.value)}
        widht="30%"
        label="Data inicial"
        mask="99/99/9999"
        height="30px"
      />
      <Input
        value={endData}
        onChange={(e) => setEndData(e.target.value)}
        widht="30%"
        label="Data final"
        mask="99/99/9999"
        height="30px"
      />
      <ModalButton>
        <Button
          height="35px"
          outlined
          disabled={selectStatus === 'all' && startData === '' && endData === ''}
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          height="35px"
          onClick={() => {
            setTouchFilter(true);
            loadData();
            setOpen(false);
          }}
        >
          Aplicar alterações
        </Button>
      </ModalButton>
    </Dialog>
  );

  return (
    <>
      <Header />
      <Container>
        {renderFilterDialog()}
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>CORRETORES</Title>
            <div className="containerTitle">
              {/* <Button title="Filtrar" height="30px" onClick={handleOpenFilter}>
                                <TiFilter size={18} />
                            </Button> */}
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData();
                }}
                onConfirm={() => loadData()}
              />
            </div>
          </Div>
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading && agentsList?.map((agent, key) => <AgentItem key={agent?.id} agent={agent} />)}
              </div>
            </InfiniteScroll>

            {!loading && !agentsList?.length && <p>Nenhum corretor encontrado</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {agentsList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default Agents;
