import React, { useState, useEffect } from 'react';
import api from 'api';
import Cookies from 'js-cookie';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FiDollarSign } from 'react-icons/fi';
import { MdChevronLeft } from 'react-icons/md';
import { BsPrinter, BsShieldCheck, BsShieldExclamation, BsShieldX } from 'react-icons/bs';
import { useLocation, useHistory } from 'react-router-dom';
import { BiMenuAltRight } from 'react-icons/bi';
import { Dialog, Select, Button } from 'components';
import { Container, ItemMenu, TextItem, Div, Subtitle, ModalButton, Description, MobileMenu } from './styles';
import { middleGray } from 'styles/colorProvider';

function PolicyMenu({ policyId, onCancel, onConfirm, policyData }) {
  const location = useLocation();
  const path = location.pathname;

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [listMotives, setListMotives] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectMotive, setSelectMotive] = useState('');
  const [textInput, setTextInput] = useState('');

  const motiveCancel = async () => {
    const numberMotive = {
      method: 'GET',
      url: `/motives-cancel`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(numberMotive);
    setListMotives(response.data.motives);
    setLoading(false);
  };
  const cancelPolicy = async () => {
    const policyNumber = {
      method: 'POST',
      url: `/cancel-policy`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        id: policyId,
        id_motive: selectMotive,
        motive_detail: textInput,
      },

      json: true,
    };
    setLoading(true);
    const response = await api(policyNumber);
    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    cancelPolicy();
    onConfirm(true);
    window.location.reload();
  };

  useEffect(() => {
    motiveCancel();
  }, []);

  return (
    <>
      <MobileMenu>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <BiMenuAltRight size={26} />
        </IconButton>

        <Menu
          style={{ marginTop: '50px', marginLeft: '-40px' }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            dense
            onClick={() => {
              history.push('/minhas-apolices');
            }}
          >
            <MdChevronLeft size="18" />
            <TextItem>Minha apólice</TextItem>
          </MenuItem>

          <MenuItem
            dense
            selected={path.includes('/detalhes-da-apolice')}
            onClick={() => {
              history.push(`/detalhes-da-apolice/${policyId}`);
            }}
          >
            <BsShieldCheck size="18" />
            <TextItem>Detalhes da apólice</TextItem>
          </MenuItem>

          <MenuItem
            dense
            selected={path.includes('/minhas-faturas')}
            onClick={() => {
              history.push(`/minhas-faturas/${policyId}`);
            }}
          >
            <FiDollarSign size="18" style={{ marginRight: '-3px' }} />
            <TextItem>Faturas</TextItem>
          </MenuItem>

          <MenuItem
            dense
            selected={path.includes('/meus-sinistros')}
            onClick={() => {
              history.push(`/meus-sinistros/${policyId}`);
            }}
          >
            <BsShieldExclamation size="18" />
            <TextItem>Sinistros</TextItem>
          </MenuItem>

          {policyData?.status === 'active' && policyData?.requested_cancellation === false && (
            <MenuItem onClick={onCancel}>
              <BsShieldX size="18" style={{ marginRight: '-3px' }} />
              <TextItem onClick={handleClickOpen}>Cancelar seguro</TextItem>
              <Dialog
                open={open}
                title="Solicitar cancelamento"
                width="450px"
                onConfirm={handleConfirm}
                onCancel={handleClose}
                loading={loading}
              >
                <Div>
                  <Subtitle>Para solicitar o cancelamento da sua apólice, por favor, preencha as informações abaixo:</Subtitle>
                  <Select
                    label="Motivo do cancelamento:"
                    height="30px"
                    width="50%"
                    value={selectMotive}
                    onChange={(e) => setSelectMotive(parseInt(e.target.value))}
                  >
                    <option value="">Selecione o motivo do cancelamento</option>
                    {listMotives?.map((option) => (
                      <option value={option.id}>{option.name}</option>
                    ))}
                  </Select>
                  <Description>
                    <TextareaAutosize
                      minRows={1}
                      onChange={(e) => setTextInput(e.target.value)}
                      value={textInput}
                      placeholder="Observações..."
                      style={{
                        padding: 10,
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        marginTop: '3%',
                        width: '100%',
                        height: '100px',
                        borderRadius: 5,
                        outline: 'none',
                        borderColor: middleGray,
                      }}
                    />
                  </Description>
                </Div>
                <ModalButton>
                  <Button
                    height="35px"
                    style={{ background: 'transparent', border: '1.5px solid #62ec83' }}
                    onClick={handleClose}
                  >
                    Voltar
                  </Button>
                  <Button
                    disabled={!selectMotive || selectMotive === ''}
                    height="35px"
                    style={{ background: '#62ec83', border: 'none' }}
                    onClick={handleConfirm}
                  >
                    Concluir cancelamento
                  </Button>
                </ModalButton>
              </Dialog>
            </MenuItem>
          )}

          {policyData?.requested_cancellation &&
            (policyData?.accepted_cancellation === null || policyData?.accepted_cancellation === false) && (
              <MenuItem dense onClick={onCancel}>
                <BsShieldX size="18" style={{ marginRight: '-3px' }} />
                <TextItem onClick={handleClickOpen}>Cancelar seguro</TextItem>
                <Dialog
                  open={open}
                  requestCancel
                  onCancel={handleClose}
                  title="Seu cancelamento já foi solicitado!"
                  onConfirm={handleClose}
                  loading={loading}
                  invertButtons
                >
                  <Subtitle>
                    Você já fez a solicitação de cancelamento desta apólice, ao fim da vigência atual, seu contrato não será
                    renovado.
                  </Subtitle>
                  <Subtitle>
                    A nossa equipe está fazendo o melhor para te responder em breve em relação a sua solicitação de cancelamento,
                    não se preocupe.
                  </Subtitle>
                  <ModalButton>
                    <Button
                      height="35px"
                      style={{ background: 'transparent', border: '1.5px solid #62ec83' }}
                      onClick={handleClose}
                    >
                      Voltar
                    </Button>
                    <Button height="35px" style={{ background: '#62ec83', border: 'none' }} onClick={handleClose}>
                      Confirmar
                    </Button>
                  </ModalButton>
                </Dialog>
              </MenuItem>
            )}

          {policyData?.status === 'active' &&
            policyData?.requested_cancellation === true &&
            policyData?.accepted_cancellation === true && (
              <MenuItem dense onClick={onCancel}>
                <BsShieldX size="18" style={{ marginRight: '-3px' }} />
                <TextItem onClick={handleClickOpen}>Cancelar seguro</TextItem>
                <Dialog
                  open={open}
                  requestCancel
                  onCancel={handleClose}
                  title="Seu cancelamento já foi confirmado!"
                  onConfirm={handleClose}
                  loading={loading}
                  invertButtons
                >
                  <Subtitle>
                    Você ainda está dentro do prazo para poder reverter seu cancelamento. Caso queira reverter, entre em contato
                    com a nossa equipe.
                  </Subtitle>
                  <Subtitle>suporte.splitrisk@splitrisk.com.br</Subtitle>
                  <Subtitle>+55 (34) 9 9898-5764</Subtitle>
                  <ModalButton>
                    <Button
                      height="35px"
                      style={{ background: 'transparent', border: '1.5px solid #62ec83' }}
                      onClick={handleClose}
                    >
                      Voltar
                    </Button>
                    <Button height="35px" style={{ background: '#62ec83', border: 'none' }} onClick={handleClose}>
                      Confirmar
                    </Button>
                  </ModalButton>
                </Dialog>
              </MenuItem>
            )}
        </Menu>
      </MobileMenu>

      <Container>
        <ItemMenu
          onClick={() => {
            history.push('/minhas-apolices');
          }}
        >
          <MdChevronLeft size="18" />
          <TextItem>Minhas apólice</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/detalhes-da-apolice') && 'select'}
          onClick={() => {
            history.push(`/detalhes-da-apolice/${policyId}`);
          }}
        >
          <BsShieldCheck size="18" />
          <TextItem>Detalhes da apólice</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/minhas-faturas') && 'select'}
          onClick={() => {
            history.push(`/minhas-faturas/${policyId}`);
          }}
        >
          <FiDollarSign size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Faturas</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/meus-sinistros') && 'select'}
          onClick={() => {
            history.push(`/meus-sinistros/${policyId}`);
          }}
        >
          <BsShieldExclamation size="18" />
          <TextItem>Sinistros</TextItem>
        </ItemMenu>

        {policyData?.status === 'active' && policyData?.requested_cancellation === false && (
          <ItemMenu onClick={onCancel}>
            <BsShieldX size="18" style={{ marginRight: '-3px' }} />
            <TextItem onClick={handleClickOpen}>Cancelar seguro</TextItem>
            <Dialog
              open={open}
              title="Solicitar cancelamento"
              width="450px"
              onConfirm={handleConfirm}
              onCancel={handleClose}
              loading={loading}
            >
              <Div>
                <Subtitle>Para solicitar o cancelamento da sua apólice, por favor, preencha as informações abaixo: </Subtitle>
                <Select
                  label="Motivo do cancelamento:"
                  height="30px"
                  width="50%"
                  value={selectMotive}
                  onChange={(e) => setSelectMotive(parseInt(e.target.value))}
                >
                  <option value="">Selecione o motivo do cancelamento</option>
                  {listMotives?.map((option) => (
                    <option value={option.id}>{option.name}</option>
                  ))}
                </Select>
                <Description>
                  <TextareaAutosize
                    minRows={1}
                    onChange={(e) => setTextInput(e.target.value)}
                    value={textInput}
                    placeholder="Observações..."
                    style={{
                      padding: 10,
                      fontFamily: 'Nunito',
                      fontSize: 14,
                      marginTop: '3%',
                      width: '100%',
                      height: '100px',
                      borderRadius: 5,
                      outline: 'none',
                      borderColor: middleGray,
                    }}
                  />
                </Description>
              </Div>
              <ModalButton>
                <Button height="35px" style={{ background: 'transparent', border: '1.5px solid #62ec83' }} onClick={handleClose}>
                  Voltar
                </Button>
                <Button
                  disabled={!selectMotive || selectMotive === ''}
                  height="35px"
                  style={{ background: '#62ec83', border: 'none' }}
                  onClick={handleConfirm}
                >
                  Concluir cancelamento
                </Button>
              </ModalButton>
            </Dialog>
          </ItemMenu>
        )}

        {policyData?.requested_cancellation &&
          (policyData?.accepted_cancellation === null || policyData?.accepted_cancellation === false) && (
            <ItemMenu onClick={onCancel}>
              <BsShieldX size="18" style={{ marginRight: '-3px' }} />
              <TextItem onClick={handleClickOpen}>Cancelar seguro</TextItem>
              <Dialog
                open={open}
                requestCancel
                onCancel={handleClose}
                title="Seu cancelamento já foi solicitado!"
                onConfirm={handleClose}
                loading={loading}
                invertButtons
              >
                <Subtitle>
                  Você já fez a solicitação de cancelamento desta apólice, ao fim da vigência atual, seu contrato não será
                  renovado.
                </Subtitle>
                <Subtitle>
                  A nossa equipe está fazendo o melhor para te responder em breve em relação a sua solicitação de cancelamento,
                  não se preocupe.
                </Subtitle>
                <ModalButton>
                  <Button
                    height="35px"
                    style={{ background: 'transparent', border: '1.5px solid #62ec83' }}
                    onClick={handleClose}
                  >
                    Voltar
                  </Button>
                  <Button height="35px" style={{ background: '#62ec83', border: 'none' }} onClick={handleClose}>
                    Confirmar
                  </Button>
                </ModalButton>
              </Dialog>
            </ItemMenu>
          )}

        {policyData?.status === 'active' &&
          policyData?.requested_cancellation === true &&
          policyData?.accepted_cancellation === true && (
            <ItemMenu onClick={onCancel}>
              <BsShieldX size="18" style={{ marginRight: '-3px' }} />
              <TextItem onClick={handleClickOpen}>Cancelar seguro</TextItem>
              <Dialog
                open={open}
                requestCancel
                onCancel={handleClose}
                title="Seu cancelamento já foi confirmado!"
                onConfirm={handleClose}
                loading={loading}
                invertButtons
              >
                <Subtitle>
                  Você ainda está dentro do prazo para poder reverter seu cancelamento. Caso queira reverter, entre em contato com
                  a nossa equipe.
                </Subtitle>
                <Subtitle>suporte.splitrisk@splitrisk.com.br</Subtitle>
                <Subtitle>+55 (34) 9 9898-5764</Subtitle>
                <ModalButton>
                  <Button
                    height="35px"
                    style={{ background: 'transparent', border: '1.5px solid #62ec83' }}
                    onClick={handleClose}
                  >
                    Voltar
                  </Button>
                  <Button height="35px" style={{ background: '#62ec83', border: 'none' }} onClick={handleClose}>
                    Confirmar
                  </Button>
                </ModalButton>
              </Dialog>
            </ItemMenu>
          )}
      </Container>
    </>
  );
}

export default PolicyMenu;
