import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import PartnersData from 'utils/corporateSettings.json';
import { formatProposalStatus } from 'utils/policyStatus';
import { formatCPF, formatPlate } from 'react-data-formatter';
import { formatCurrency } from 'react-data-formatter';

export const dataColumns = [
  {
    selector: (row) => (
      <Tooltip title={`${PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.id_author_partner)]?.name}`}>
        <img
          className="partnerIcon"
          src={PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.id_author_partner)]?.logos?.small}
          alt={PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.name}
        />
      </Tooltip>
    ),
    width: '5%',
    sortable: true,
  },
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Cliente',
    selector: (row) => row?.user?.name,
    width: '15%',
    sortable: true,
  },
  {
    name: 'CPF',
    selector: (row) => formatCPF(row?.user?.cpf),
    width: '15%',
    sortable: true,
  },
  {
    name: 'Placa',
    selector: (row) => formatPlate(row?.vehicle?.plate) || 'Sem placa',
    width: '10%',
    sortable: true,
  },
  {
    name: 'Valor',
    selector: (row) => formatCurrency(row?.valueFinal || 0),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => formatProposalStatus(row?.status)?.label,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Enviada em',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Parceiro',
    selector: (row) => PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.name,
    width: '10%',
    sortable: true,
  },
];
