/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, LeftBox, RightBox, Form, BackToLogin, Select, Option, Label, SelectGender } from './styles';
import { Button, Input } from 'components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { white } from 'styles/colorProvider';
import api from 'api';
import ReactLoading from 'react-loading';
import { isBirthDateValid } from 'utils/birthDate';

function CustomerRegistration({ location }) {
  const history = useHistory();
  const [inviteData, serInviteData] = useState();
  const [name, setName] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [gender, setGender] = useState();
  const [password, setPassword] = useState('');

  const invitetoken = location.search.split('?token=')[1];

  console.log({ invitetoken });

  const loadData = async () => {
    setLoadingData(true);
    try {
      const response = await api({
        method: 'GET',
        url: '/invite',
        params: { invitetoken },
        json: true,
      });
      serInviteData(response.data);
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const formatBirthdate = (date) => {
    const [day, month, year] = date?.split('/');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post('/invite-accept', {
        name,
        birthdate: formatBirthdate(birthdate),
        cpf,
        phone,
        token: inviteData.token,
        gender,
        password,
      });
      toast.success('Usuário criado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
      history.push('/');
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <LeftBox>
        <div />
      </LeftBox>
      <RightBox>
        <Form>
          <div className="areaLogo">
            <div className="logo" />
          </div>
          <div className="description">
            <h2>Criar Conta</h2>
            <p>Insira as informações abaixo para criar a sua conta.</p>
          </div>

          <Input label="Nome" placeholder="Digite seu nome" value={name} onChange={(e) => setName(e.target.value)} />

          <Input label="E-mail" placeholder="Digite seu e-mail" value={inviteData?.email} disabled />

          <Input
            label="Data de nascimento"
            placeholder="Digite sua data de nascimento"
            mask="99/99/9999"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
            success={isBirthDateValid(birthdate)}
            error={birthdate && !isBirthDateValid(birthdate)}
          />
          <Input
            label="CPF"
            placeholder="Digite seu CPF"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            mask="999.999.999-99"
          />
          <Input
            label="Telefone"
            placeholder="Digite seu Telefone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            mask={'(99) 99999-9999'}
          />
          <Input
            label="Senha"
            placeholder="Digite uma senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            password
            type="password"
          />

          <SelectGender>
            <Label>Sexo</Label>
            <Select onChange={(e) => setGender(e.target.value)}>
              <Option value="">Selecione o sexo </Option>
              <Option value="1">Masculino</Option>
              <Option value="2">Feminino </Option>
            </Select>
          </SelectGender>

          <div className="buttonArea">
            <Button
              disabled={
                loading ||
                !name ||
                !birthdate ||
                birthdate.length < 10 ||
                !cpf ||
                cpf.length < 14 ||
                !phone ||
                phone.length < 15 ||
                !gender ||
                gender === '' ||
                !isBirthDateValid(birthdate) ||
                !invitetoken ||
                !password
              }
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Criar conta'}
            </Button>
          </div>
        </Form>
        <BackToLogin onClick={() => history.push('/')}>Voltar para login</BackToLogin>
      </RightBox>
    </Container>
  );
}

export default CustomerRegistration;
