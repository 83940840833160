/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import { Header, SideMenu, Select, Search, Button, Dialog, Input } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton, ButtonTable } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';
import { formatCPF } from 'react-data-formatter';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

import { renderAddBlockCoverages, renderEditBlockCoverages, renderRemoveBlockCoverages } from './modals';
import { getCoveragesList, getCurrentPartners, handleLoadMore, loadData } from './requests';
import { useMediaQuery } from '../../../../../node_modules/@material-ui/core/index';

// import { dataColumns } from './helper';

function BackOfficeProductsBlockCoverages({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingDataTable, setLoadingDataTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState();
  const [selectStatus, setSelectStatus] = useState('all');
  const [startData, setStartdata] = useState();
  const [endData, setEndData] = useState();
  const [touchFilter, setTouchFilter] = useState();

  // Modals
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);

  const [selectedPartnerVendor] = useState('all');

  const [userPartners, setUserPartners] = useState([]);
  const [blockCoverageInfo, setBlockCoverageInfo] = useState({
    id: undefined,
    id_partner: undefined,
    id_pricing: undefined,
    enabled: false,
  });
  const [blockCoverageList, setBlockCoverageList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);

  // Lista as coberturas a partir do tipo (basic, deductible, fipe, glass, optional, replacement)
  const [coveragesList, setCoveragesList] = useState([]);
  // Atualiza o valor da cobertura na tabela

  const [coverageType, setCoverageType] = useState('basic');

  // pricing_id - Valor do pricing da tabela original que varia de acordo com a table block
  const coveragesConfig = [
    {
      type: 'basic',
      pricing_id: 'id_pricing_basic_coverage',
    },
    {
      type: 'deductible',
      pricing_id: 'id_pricing_deductible',
    },
    {
      type: 'fipelmi',
      pricing_id: 'id_pricing_fipe_lmi',
    },
    {
      type: 'glass',
      pricing_id: 'id_pricing_glass',
    },
    {
      type: 'optional',
      pricing_id: 'id_pricing_optional_coverage',
    },
    {
      type: 'replacement',
      pricing_id: 'id_pricing_replacement_car',
    },
    {
      type: 'support',
      pricing_id: 'id_pricing_support',
    },
  ];

  const handleClear = () => {
    setSelectStatus('all');
    setStartdata('');
    setEndData('');
  };

  useEffect(() => {
    getCurrentPartners({ setLoading, setPartnersList });
  }, []);

  useEffect(() => {
    const loadAllData = async () => {
      // Carrega as coberturas primeiro para ser identificada a cobertura que está sendo bloqueada na tabela
      await getCoveragesList({ setLoading, coverageType, setCoveragesList });

      // Depois puxa os bloqueios (Array que aparece na tabela)
      await loadData({
        setCurrentPage,
        setBlockCoverageList,
        setHasNextPage,
        setLoading,
        setTotalCount,
        setTouchFilter,
        loadingDataTable,
        setLoadingDataTable,
        coverageType,
      });
    };

    if (coverageType) loadAllData();
  }, [coverageType]);

  const dataColumns = [
    {
      name: 'ID',
      selector: (row) => row?.id,
      width: '5%',
      sortable: true,
    },
    {
      name: 'Cobertura',
      selector: (row) => {
        console.log({ coveragesList });

        // Obtém o nome do parâmetro de forma dinâmica (id_pricing...), varia de acordo com a tabela
        const pricingParameter = coveragesConfig?.find((e) => e.type === coverageType)?.pricing_id;

        console.log({ pricingParameter });

        // Obtém o ID do pricing
        const pricingId = row?.[pricingParameter];
        console.log({ pricingId });

        // Obtém o nome da cobertura
        const coverageName = coveragesList.find((i) => i.id === pricingId)?.name;
        console.log({ coverageName });

        // Retorna a cobertura
        return coverageName || '-';
      },
      width: '36%',
      sortable: true,
    },
    {
      name: 'Parceiro',
      selector: (row) => row?.TB_PARTNERS?.trade_name,
      width: '28%',
      sortable: true,
    },
    {
      name: 'Regra ativa?',
      selector: (row) =>
        row.enabled ? (
          <AiFillCheckCircle
            size={16}
            style={{
              color: '#62ec83',
            }}
          />
        ) : (
          <AiFillCloseCircle
            size={16}
            style={{
              color: 'red',
            }}
          />
        ),
      width: '12%',
      sortable: true,
    },
    {
      name: 'Editar',
      selector: (row) => (
        <ButtonTable
          onClick={() => {
            const pricingId = coveragesConfig?.find((e) => e.type === coverageType)?.pricing_id;

            setBlockCoverageInfo({
              id: row.id,
              id_partner: row.id_partner,
              id_pricing: pricingId,
              enabled: row.enabled,
            });
            setOpenModalEdit(true);
          }}
        >
          <BiPencil size={16} />
        </ButtonTable>
      ),
      width: '8%',
      sortable: false,
    },
    {
      name: 'Excluir',
      selector: (row) => (
        <ButtonTable
          onClick={() => {
            setBlockCoverageInfo({
              ...blockCoverageInfo,
              id: row.id,
            });
            setOpenModalRemove(true);
          }}
        >
          <BiTrash size={16} />
        </ButtonTable>
      ),
      width: '8%',
      sortable: false,
    },
  ];

  const matches = useMediaQuery('(min-width:1000px)');

  return (
    <>
      <Header />
      <Container>
        {renderRemoveBlockCoverages({
          openModalRemove,
          setOpenModalRemove,
          blockCoverageInfo,
          setBlockCoverageInfo,
          loading,
          setLoading,
          setCurrentPage,
          setBlockCoverageList,
          setHasNextPage,
          setTotalCount,
          setTouchFilter,
          setUserPartners,
          loadingDataTable,
          setLoadingDataTable,
          coverageType,
        })}
        {renderAddBlockCoverages({
          openModalAdd,
          setOpenModalAdd,
          userPartners,
          blockCoverageInfo,
          setBlockCoverageInfo,
          setLoading,
          loading,
          setCurrentPage,
          setBlockCoverageList,
          setHasNextPage,
          setTotalCount,
          setTouchFilter,
          setUserPartners,
          loadingDataTable,
          setLoadingDataTable,
          coverageType,
          partnersList,
          coveragesList,
        })}
        {renderEditBlockCoverages({
          openModalEdit,
          setOpenModalEdit,
          blockCoverageInfo,
          setBlockCoverageInfo,
          loading,
          setLoading,
          loadData,
          setCurrentPage,
          setBlockCoverageList,
          setHasNextPage,
          setTotalCount,
          setTouchFilter,
          loadingDataTable,
          setLoadingDataTable,
          coverageType,
        })}
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>BLOQUEIO DE COBERTURAS</Title>

            <div
              style={{
                display: 'flex',
                flexDirection: matches ? 'row' : 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  marginBottom: matches ? '26px' : 0,
                  width: '100%',
                }}
              >
                <Select
                  height="30px"
                  label="Tipo de cobertura"
                  value={coverageType}
                  onChange={(e) => setCoverageType(e.target.value)}
                >
                  <option value={'basic'}>Básica</option>
                  <option value={'deductible'}>Dedutível</option>
                  <option value={'fipelmi'}>FIPE LMI</option>
                  <option value={'glass'}>Vidro</option>
                  <option value={'optional'}>Opcional</option>
                  <option value={'replacement'}>Reposição</option>
                  <option value={'support'}>Suporte</option>
                </Select>
              </div>

              <Button
                title="Adicionar"
                height="30px"
                onClick={() => setOpenModalAdd(true)}
                disabled={loading || loadingDataTable}
              >
                Adicionar
              </Button>

              {/* <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData({
                    setCurrentPage,
                    setBlockCoverageList,
                    setHasNextPage,
                    setLoading,
                    setTotalCount,
                    setTouchFilter,
                    search,
                    loadingDataTable,
                    setLoadingDataTable,
                    coverageType,
                  });
                }}
                onConfirm={() =>
                  loadData({
                    setCurrentPage,
                    setBlockCoverageList,
                    setHasNextPage,
                    setLoading,
                    setTotalCount,
                    setTouchFilter,
                    search,
                    loadingDataTable,
                    setLoadingDataTable,
                    coverageType,
                  })
                }
              /> */}
            </div>
          </Div>
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={() => {
                handleLoadMore({
                  touchFilter,
                  search,
                  startData,
                  endData,
                  selectStatus,
                  selectedPartnerVendor,
                  currentPage,
                  hasNextPage,
                  loadingMore,
                  setLoadingMore,
                  blockCoverageList,
                  setCurrentPage,
                  setBlockCoverageList,
                  setHasNextPage,
                  coverageType,
                });
              }}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={0}
            >
              <DataTable
                // onRowClicked={(row) => history.push(`/backoffice/detalhes-da-proposta/${row?.id}`)}
                persistTableHead
                responsive
                columns={dataColumns}
                data={blockCoverageList}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading || loadingDataTable}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhum bloqueio encontrado</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {blockCoverageList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default BackOfficeProductsBlockCoverages;
