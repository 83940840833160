import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PartnersData from 'utils/corporateSettings.json';
import moment from 'moment';
import 'moment/locale/pt-br';
import { BsShieldCheck } from 'react-icons/bs';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { formatPolicyStatus } from 'utils/policyStatus';
import { formatCPF, formatPlate } from 'react-data-formatter';

function PolicyItem({ policy }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push(`/backoffice/detalhes-da-apolice/${policy?.id}`);
  };

  const partnerInfo = PartnersData[PartnersData.findIndex((i) => i.id_partner === policy?.partner?.id)];

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="partnerIcon">
            <Tooltip title={`Parceiro ${partnerInfo?.name}`}>
              <img className="partnerIcon" src={partnerInfo?.logos?.small} alt={partnerInfo?.name} />
            </Tooltip>
          </div>

          <Div>
            <Title>{policy?.user?.name}</Title>
            <Subtitle>{formatCPF(policy?.user?.cpf)}</Subtitle>
          </Div>

          <Div>
            <Title>
              {policy?.policy_number} ({formatPolicyStatus(policy?.status)?.label})
            </Title>
            <Subtitle>
              Placa: {policy?.vehicle?.plate !== null || undefined ? formatPlate(policy?.vehicle?.plate) : 'Sem placa'}
            </Subtitle>
          </Div>

          <Div>
            <Title>Parceiro: {partnerInfo?.name}</Title>
            <Subtitle>Emitida em {moment(policy?.created_at).format('DD/MM/YYYY HH:mm')}</Subtitle>
          </Div>

          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>

          <IconMobile>
            <BsShieldCheck size={40} color={formatPolicyStatus(policy?.status)?.color} />
          </IconMobile>
        </div>
      </Container>
    </>
  );
}

export default PolicyItem;
