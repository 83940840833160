import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { IoCopyOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { formatTicketStatus, formatTicketPriority } from 'utils/ticketStatus';

export const dataColumns = [
  {
    name: '',
    selector: (row) => <Tooltip title={`Prioridade ${row?.priority}`}>{formatTicketPriority(row?.priority)?.icon}</Tooltip>,
    width: '5%',
    sortable: false,
  },
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '5%',
    sortable: true,
  },
  {
    name: 'Protocolo',
    selector: (row) => (
      <p
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          navigator.clipboard.writeText(row?.protocol);
          toast.success('Protocolo copiado com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }}
      >
        <IoCopyOutline style={{ marginRight: 5 }} size={12} /> {row?.protocol}
      </p>
    ),
    width: '20%',
    sortable: true,
  },
  {
    name: 'Solicitante',
    selector: (row) => row?.client_name,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Descrição',
    selector: (row) => row.description?.slice(0, 20),
    width: '15%',
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => formatTicketStatus(row.status),
    width: '15%',
    sortable: true,
  },
  {
    name: 'Aberto em',
    selector: (row) => moment(row?.created_at).fromNow(),
    // selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Aberto por',
    selector: (row) => row?.author?.name,
    width: '15%',
    sortable: true,
  },
];
