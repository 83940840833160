const maskCPF = (value) => {
    const cleanValue = value?.replace(/\D/g, '');
    return cleanValue?.slice(0, 11).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

const maskCNPJ = (value) => {
    const cleanValue = value?.replace(/\D/g, '');
    return cleanValue?.slice(0, 14).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

const maskPhone = (value) => {
    const cleanValue = value?.replace(/\D/g, '');
    return cleanValue?.slice(0, 11).replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
};

const maskPlateOld = (value) => {
    const cleanValue = value?.toUpperCase().replace(/[^A-Z0-9]/g, '');
    return cleanValue?.slice(0, 7).replace(/^([A-Z]{3})(\d{4})$/, '$1-$2');
};

const maskPlateNew = (value) => {
    const cleanValue = value?.toUpperCase().replace(/[^A-Z0-9]/g, '');
    return cleanValue?.slice(0, 7).replace(/^([A-Z]{3})(\d{1})([A-Z]{1})(\d{2})$/, '$1$2$3$4');
};

export const isValidCPF = (cpf) => /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(maskCPF(cpf));
export const isValidCNPJ = (cnpj) => /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(maskCNPJ(cnpj));
export const isValidPhone = (phone) => /^\(\d{2}\) \d{5}-\d{4}$/.test(maskPhone(phone));
export const isValidEmail = (email) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
export const isValidPlate = (plate) =>
    /^[A-Z]{3}-\d{4}$/.test(maskPlateOld(plate)) || /^[A-Z]{3}\d{1}[A-Z]{1}\d{2}$/.test(maskPlateNew(plate));

export const formatValue = (input, selectedType) => {
    switch (selectedType) {
        case 'document':
            return input?.length > 11 ? maskCNPJ(input) : maskCPF(input);
        case 'phone':
            return maskPhone(input);
        case 'plate':
            return input?.length === 7 && /\d/.test(input[3]) ? maskPlateNew(input) : maskPlateOld(input);
        case 'email':
            return input;
        default:
            return input;
    }
};

export const validateInput = (input, selectedType) => {
    switch (selectedType) {
        case 'document':
            return input.length > 11 ? isValidCNPJ(input) : isValidCPF(input);
        case 'phone':
            return isValidPhone(input);
        case 'plate':
            return isValidPlate(input);
        case 'email':
            return isValidEmail(input);
        default:
            return true;
    }
};
