/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards-br';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';
import api from 'api';
import Cookies from 'js-cookie';
import moment from 'moment';

import { loadingColor } from 'styles/colorProvider';
import { Header, PaymentMethod, Input, Button } from 'components';
import { Container, ContentArea, PaymentArea, QrcodePix } from './styles';
import { toast } from 'react-toastify';
import { BsCheckCircle } from 'react-icons/bs';
import { FaBarcode } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import { AiOutlineDoubleRight } from 'react-icons/ai';
import { getCardFlag } from 'utils/creditCard';
import { formatCurrency } from 'react-data-formatter';
import './cardStyle.css';
import payingAnimation from 'assets/paying.json';
import successAnimation from 'assets/success.json';
import errorAnimation from 'assets/error.json';

import billetImage from 'assets/bill.png';
import cardImage from 'assets/debit-card.png';

const iof = 0.0738;

function Checkout({ history, location }) {
  const [loadingCharge, setLoadingCharge] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedPayMethod, setSelectedPayMethod] = useState();
  const [automaticPay, setAutomaticPay] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState();
  const [pixInfo, setPixInfo] = useState();
  const [billetInfo, setBilletInfo] = useState();
  const [debitInfo, setDebitInfo] = useState();
  const [newOrExisting, setNewOrExisting] = useState();
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState();

  const [cardData, setCardData] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    focused: '',
  });
  const [chargeData, setChargeData] = useState({});

  const chargeId = parseInt(location.pathname.split('/pagamento/')[1]);

  const expiryMonth = cardData.expiry.split('/')[0];
  const expiryYear = cardData.expiry.split('/')[1];

  const loadData = async () => {
    setLoadingCharge(true);
    try {
      const responseCharge = await api({
        method: 'GET',
        url: `/charge-detail/${chargeId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        json: true,
      });
      setChargeData(responseCharge?.data);

      if (responseCharge?.data) {
        setNewOrExisting('existing');
        setSelectedPaymentProfile(responseCharge?.data?.default_card?.payment_profile?.id);
      } else {
        setNewOrExisting('new');
      }
    } catch (e) {
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
    setLoadingCharge(false);
    setLoading(false);
  };

  const handleCopyBillet = () => {
    navigator.clipboard.writeText(billetInfo?.billet?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.barcode);
    toast.success('Código de barras copiado com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  };

  const handleOpenBillet = () => {
    window.open(billetInfo?.billet?.bill?.charges[0]?.print_url, '_blank').focus();
  };

  const handleCopyPix = () => {
    navigator.clipboard.writeText(
      pixInfo?.pix?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.qrcode_original_path
    );
    toast.success('Código PIX copiado com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  };

  const handleAcessDebitArea = () => {
    window.open(debitInfo?.debit?.bill?.url, '_blank').focus();
  };

  const handleSelectPayMethod = (method) => {
    setSelectedPayMethod(method);
    setStep(step + 1);

    if (method === 'pix') handleGeneratePix();
    if (method === 'billet') handleGenerateBillet();
    if (method === 'debit_card') handleGenerateDebitCard();
  };

  const handleGeneratePix = async () => {
    setLoading(true);
    const resp = await api({
      method: 'POST',
      url: `/charge-pix`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        id_charge: chargeId,
      },
      json: true,
    });
    setPixInfo(resp.data);
    setLoading(false);
  };

  const handleGenerateBillet = async () => {
    setLoading(true);
    const resp = await api({
      method: 'POST',
      url: `/charge-billet`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        id_charge: chargeId,
      },
      json: true,
    });
    setBilletInfo(resp.data);
    setLoading(false);
  };

  const handleGenerateDebitCard = async () => {
    setLoading(true);
    const resp = await api({
      method: 'POST',
      url: `/charge-debit`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        id_charge: chargeId,
      },
      json: true,
    });
    setDebitInfo(resp.data);
    setLoading(false);
  };

  const handlePayCreditCard = async () => {
    setStep(step + 1);

    setLoading(true);
    const resp = await api({
      method: 'POST',
      url: `/charge-credit`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        pay_method_type: newOrExisting,
        id_payment_profile: selectedPaymentProfile,
        id_charge: chargeId,
        holder_name: cardData?.name,
        card_expiration: `${expiryMonth}/20${expiryYear}`,
        card_number: cardData?.number.replaceAll(' ', ''),
        card_cvv: cardData?.cvc,
        automatic_charge: automaticPay,
      },
      json: true,
    });
    setPaymentStatus(resp.data?.bill?.charges[0]?.last_transaction?.status);
    setLoading(false);
  };

  useEffect(() => loadData(), []);

  return (
    <>
      <Header />
      <Container>
        <ContentArea>
          {loadingCharge && (
            <PaymentArea style={{ justifyContent: 'center', paddingTop: 50 }}>
              <ReactLoading color={loadingColor} height={50} width={50} type="spin" />
            </PaymentArea>
          )}

          {!loadingCharge && (
            <PaymentArea>
              <div className="descriptionArea">
                <h3>Detalhes da fatura</h3>
                <p className="text">Vencimento: {moment(chargeData?.due_at).format('DD/MM/YYYY')}</p>
                <p className="text detail">Mensalidade: {chargeData?.value && formatCurrency(chargeData?.value)}</p>
                {chargeData?.type === 'deductible' ? null : (
                  <p className="text detail">IOF: {chargeData?.value && formatCurrency(chargeData?.value * iof)}</p>
                )}

                <h3>Coberturas</h3>
                {chargeData?.products?.map((product) => (
                  <p className="text">{product}</p>
                ))}
                {chargeData?.coverages?.map((product) => (
                  <p className="text">{product}</p>
                ))}
              </div>

              <div className="paymentInfoArea">
                {step === 1 && (
                  <>
                    <h3>Por favor, escolha uma forma de pagamento:</h3>
                    <div className="paymentMethods">
                      <PaymentMethod type="boleto" onClick={() => handleSelectPayMethod('billet')} />
                      <PaymentMethod type="pix" onClick={() => handleSelectPayMethod('pix')} />
                      <PaymentMethod type="cartão de débito" onClick={() => handleSelectPayMethod('debit_card')} />
                      <PaymentMethod type="cartão de crédito" onClick={() => handleSelectPayMethod('credit_card')} />
                    </div>
                  </>
                )}

                {step === 2 && selectedPayMethod === 'credit_card' && (
                  <>
                    <div className="cardArea">
                      <div className="creditCardArea">
                        <h3>Dados do cartão</h3>

                        {/* EXISTING CARD */}
                        {chargeData?.default_card?.payment_profile && (
                          <div className="cardLineBox">
                            <input
                              id="existingCard"
                              type="radio"
                              className="radioOption"
                              checked={newOrExisting === 'existing'}
                              onChange={() => setNewOrExisting('existing')}
                            />
                            <label htmlFor="existingCard">
                              {getCardFlag(chargeData?.default_card?.payment_profile?.card_number_first_six)?.name} com final{' '}
                              {chargeData?.default_card?.payment_profile?.card_number_last_four}{' '}
                            </label>
                          </div>
                        )}

                        {/* NEW CARD */}
                        <div className="cardLineBox">
                          <input
                            id="newCard"
                            type="radio"
                            className="radioOption"
                            checked={newOrExisting === 'new'}
                            onChange={() => setNewOrExisting('new')}
                          />
                          <label htmlFor="newCard">Novo cartão</label>
                        </div>

                        {newOrExisting === 'new' && (
                          <div className="cardLine">
                            <div className="cardForm">
                              <Input
                                label="Número do cartão"
                                disableLabel
                                number
                                mask="9999 9999 9999 9999"
                                placeholder="Insira o número do cartão"
                                value={cardData?.number}
                                onChange={(e) => setCardData({ ...cardData, number: e.target.value })}
                                onFocus={() => setCardData({ ...cardData, focused: 'number' })}
                              />
                              <Input
                                label="Nome do titular"
                                disableLabel
                                placeholder="Insira o nome do titular"
                                value={cardData?.name}
                                onChange={(e) => setCardData({ ...cardData, name: e.target.value.toUpperCase() })}
                                onFocus={() => setCardData({ ...cardData, focused: 'name' })}
                              />
                              <Input
                                number
                                disableLabel
                                label="Validade do cartão"
                                mask="99/99"
                                placeholder="MM/AA"
                                value={cardData?.expiry}
                                onChange={(e) => setCardData({ ...cardData, expiry: e.target.value })}
                                onFocus={() => setCardData({ ...cardData, focused: 'expiry' })}
                              />
                              <Input
                                number
                                mask="999"
                                disableLabel
                                placeholder="CVC/CVV"
                                label="CVC/CVV"
                                value={cardData?.cvc}
                                onChange={(e) => setCardData({ ...cardData, cvc: e.target.value })}
                                onFocus={() => setCardData({ ...cardData, focused: 'cvc' })}
                              />
                            </div>
                            <div>
                              <div className="cardBox">
                                <Cards
                                  locale={{ valid: 'Validade' }}
                                  placeholders={{ name: 'NOME DO TITULAR' }}
                                  number={cardData?.number}
                                  name={cardData?.name}
                                  expiry={cardData?.expiry}
                                  cvc={cardData?.cvc}
                                  focused={cardData?.focused}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="checkboxArea">
                          <input
                            checked={automaticPay}
                            id="automaticPay"
                            type="checkbox"
                            onChange={() => setAutomaticPay(!automaticPay)}
                          />
                          <label htmlFor="automaticPay">Ativar cobrança mensal automática</label>
                        </div>

                        <div className="buttonArea">
                          <Button outlined height="35px" width="120px" disabled={step === 1} onClick={() => setStep(step - 1)}>
                            Voltar
                          </Button>
                          <Button
                            height="35px"
                            width="120px"
                            disabled={
                              (newOrExisting === 'existing' && !selectedPaymentProfile) ||
                              (newOrExisting === 'new' && parseInt(expiryMonth) <= 0) ||
                              (newOrExisting === 'new' && parseInt(expiryMonth) > 12) ||
                              (newOrExisting === 'new' && parseInt(expiryYear) < moment().format('YY')) ||
                              (newOrExisting === 'new' && !cardData.number) ||
                              (newOrExisting === 'new' && !cardData.name) ||
                              (newOrExisting === 'new' && !cardData.expiry) ||
                              (newOrExisting === 'new' && !cardData.cvc) ||
                              (newOrExisting === 'new' && cardData.number.length !== 19) ||
                              (newOrExisting === 'new' && cardData.expiry.length !== 5) ||
                              (newOrExisting === 'new' && cardData.cvc.length !== 3)
                            }
                            onClick={() => setStep(step + 1)}
                          >
                            Avançar
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {step === 2 && selectedPayMethod === 'billet' && (
                  <>
                    {loading && (
                      <div className="loadingArea">
                        <ReactLoading color={loadingColor} height={50} width={50} type="spin" />
                        <h3>Por favor, aguarde. Estamos processando o seu pagamento!</h3>
                      </div>
                    )}
                    {!loading && (
                      <div className="billetArea">
                        <img src={billetImage} alt="Boleto" />
                        <h3>O seu boleto está pronto!</h3>
                        <div className="buttonArea" style={{ flexDirection: 'column', alignItems: 'center' }}>
                          <Button height="35px" width="220px" onClick={handleOpenBillet}>
                            <FaBarcode style={{ marginRight: 10 }} />
                            Visualizar boleto
                          </Button>
                          <Button height="35px" width="220px" onClick={handleCopyBillet}>
                            <FiCopy style={{ marginRight: 10 }} />
                            Copiar código de barras
                          </Button>
                          <Button outlined height="35px" width="220px" disabled={step === 1} onClick={() => setStep(step - 1)}>
                            Voltar
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {step === 2 && selectedPayMethod === 'pix' && (
                  <>
                    {loading && (
                      <div className="loadingArea">
                        <ReactLoading color={loadingColor} height={50} width={50} type="spin" />
                        <h3>Por favor, aguarde. Estamos processando o seu pagamento!</h3>
                      </div>
                    )}
                    {!loading && pixInfo?.pix?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.qrcode_path && (
                      <div className="pixArea">
                        <h3>Pagar com PIX</h3>
                        <QrcodePix
                          src={`${pixInfo?.pix?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.qrcode_path}`}
                        />
                        <small>
                          Para realizar o pagamento, escaneie o QR Code acima ou copie o código PIX e cole no APP do seu banco. O
                          pagamento pode levar até 30 minutos para ser compensado.
                        </small>
                        <div className="buttonArea">
                          <Button outlined height="35px" width="120px" disabled={step === 1} onClick={() => setStep(step - 1)}>
                            Voltar
                          </Button>
                          <Button height="35px" width="220px" onClick={handleCopyPix}>
                            <FiCopy style={{ marginRight: 10 }} />
                            PIX Copia e cola
                          </Button>
                        </div>
                      </div>
                    )}
                    {!loading && !pixInfo?.pix?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.qrcode_path && (
                      <div className="pixArea">
                        <Lottie
                          autoplay
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: errorAnimation,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height={150}
                          width={150}
                        />
                        <p>
                          Oops! Infelizmente houve um erro. <br /> Por favor, tente novamente!
                        </p>
                        <Button outlined height="35px" width="120px" disabled={step === 1} onClick={() => setStep(step - 1)}>
                          Voltar
                        </Button>
                      </div>
                    )}
                  </>
                )}

                {step === 2 && selectedPayMethod === 'debit_card' && (
                  <>
                    {loading && (
                      <div className="loadingArea">
                        <ReactLoading color={loadingColor} height={50} width={50} type="spin" />
                        <h3>Por favor, aguarde. Estamos processando o seu pagamento!</h3>
                      </div>
                    )}
                    {!loading && (
                      <div className="debitCardArea">
                        <img src={cardImage} alt="Cartão de débito" />
                        <h3>Pagar com cartão de débito</h3>

                        <small>
                          Para concluir o seu pagamento com cartão de débito você será redirecionado para um ambiente externo, mas
                          não se preocupe que seus dados continuarão seguros.
                        </small>

                        <div className="buttonArea">
                          <Button outlined height="35px" width="120px" disabled={step === 1} onClick={() => setStep(step - 1)}>
                            Voltar
                          </Button>
                          <Button height="35px" width="220px" onClick={handleAcessDebitArea}>
                            Continuar
                            <AiOutlineDoubleRight style={{ marginLeft: 10 }} />
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {step === 3 && (
                  <>
                    <h3>Revise as informações</h3>
                    <p className="text">
                      Valor do pagamento:{' '}
                      {chargeData?.type === 'other' || chargeData?.type === 'deductible' ? (
                        <b>{chargeData?.value && formatCurrency(chargeData?.value)}</b>
                      ) : (
                        <b>{chargeData?.value && formatCurrency(chargeData?.value)}</b>
                      )}
                    </p>
                    <p className="text">
                      Cobrança mensal automática <b>{automaticPay ? 'ativada' : 'desativada'}</b>.
                    </p>
                    {newOrExisting === 'new' && (
                      <p className="text resumeCardLine">
                        Forma de pagamento: &nbsp; {getCardFlag(cardData?.number)?.image} {getCardFlag(cardData?.number)?.name}{' '}
                        com final {cardData?.number?.slice(cardData?.number?.length - 4)}.
                      </p>
                    )}

                    {newOrExisting === 'existing' && (
                      <p className="text resumeCardLine">
                        Forma de pagamento: &nbsp;{' '}
                        {getCardFlag(chargeData?.default_card?.payment_profile?.card_number_first_six)?.name} com final{' '}
                        {chargeData?.default_card?.payment_profile?.card_number_last_four}.
                      </p>
                    )}

                    <div className="buttonArea" style={{ marginTop: 150 }}>
                      <Button outlined height="35px" width="120px" disabled={step === 1} onClick={() => setStep(step - 1)}>
                        Voltar
                      </Button>
                      <Button height="35px" width="150px" onClick={handlePayCreditCard}>
                        <BsCheckCircle style={{ marginRight: 10 }} />
                        Pagar agora
                      </Button>
                    </div>
                  </>
                )}

                {step === 4 && (
                  <>
                    {loading && (
                      <div className="payLoading">
                        <Lottie
                          autoplay
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: payingAnimation,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height={180}
                          width={280}
                        />
                        <h3>Por favor, aguarde...</h3>
                        <p>Estamos processando o seu pagamento.</p>
                      </div>
                    )}

                    {!loading && paymentStatus === 'success' && (
                      <div className="payStatus">
                        <Lottie
                          autoplay
                          options={{
                            loop: false,
                            autoplay: true,
                            animationData: successAnimation,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height={150}
                          width={150}
                        />
                        <h3>Sucesso!</h3>
                        {chargeData?.type === 'subscription' && (
                          <>
                            <p>O seu pagamento foi aprovado. Falta pouco para o seu seguro estar ativo.</p>
                            <p>Você será notificado quando a ativação for concluída.</p>
                            <Button height="30px" onClick={() => history.push('/minhas-apolices')}>
                              {' '}
                              Continuar
                            </Button>
                          </>
                        )}

                        {chargeData?.type === 'resubscription' && (
                          <>
                            <p>O seu pagamento foi aprovado e o seu seguro continuará ativo!</p>
                          </>
                        )}
                      </div>
                    )}

                    {!loading && (paymentStatus === 'rejected' || !paymentStatus) && (
                      <div className="payStatus">
                        <Lottie
                          autoplay
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: errorAnimation,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height={150}
                          width={150}
                        />
                        <h3 style={{ marginTop: -20 }}>Oops!</h3>
                        <p>Infelizmente o seu pagamento não foi aprovado.</p>
                        <p>Verifique as informações e tente novamente ou escolha outra forma de pagamento.</p>
                        <div className="buttonArea" style={{ marginTop: 20 }}>
                          <Button height="35px" width="150px" onClick={() => setStep(step - 1)}>
                            Voltar
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </PaymentArea>
          )}
        </ContentArea>
      </Container>
    </>
  );
}

export default Checkout;
