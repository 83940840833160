/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import Cookies from 'js-cookie';

import { Container } from './styles';
import { Button } from 'components';
import cookiesImg from 'assets/cookies.png';

function CookiesPermission() {
  const [accepted, setAccepted] = useState(Cookies.get('splitrisk-acceptCookies'));

  const handleAccept = () => {
    Cookies.set('splitrisk-acceptCookies', 'true');
    setAccepted('true');
  };

  return (
    <>
      {!accepted && (
        <Container>
          <div>
            <img className="icon" src={cookiesImg} />
          </div>
          <div>
            <h1 className="title">Este site utiliza cookies!</h1>
            <p className="text">
              Armazenamos alguns dados de navegação temporariamente para melhorar a sua experiência no nosso portal. Ao utilizar
              nossos serviços, você concorda com a nossa{' '}
              <a className="link" href="https://splitrisk.com.br/termos" target="_blank">
                política de privacidade
              </a>
              .
            </p>
          </div>
          <div>
            <Button width="100px" height="28px" onClick={handleAccept}>
              Aceitar
            </Button>
          </div>
        </Container>
      )}
    </>
  );
}

export default CookiesPermission;
