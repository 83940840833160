import React from 'react';
import 'moment/locale/pt-br';
import { CardsArea, ButtonModal } from './styles';
import Cards from 'react-credit-cards-br';
import { BiTrashAlt } from 'react-icons/bi';
import { Button } from 'components';

function Card({ cardData, remove, selectCard, addCard }) {
  const cardNumber = `**** **** **** ${cardData?.card_number_last_four}`
  return (
    <>
      <CardsArea>
        <Cards
          preview
          number={cardNumber}
          issuer={cardData?.payment_company?.code}
          locale={{ valid: '', expiry: '' }}
          placeholders={{ name: '', valid: '', expiry: '' }}
        />
        <ButtonModal>
          <Button
            style={{ marginTop: 20, width: '180px', height: '30px', borderRadius: '10px' }}
            onClick={addCard}
          >
            Substituir
          </Button>
          {cardData?.default === false && (
            <Button style={{ marginTop: 20, width: '50px', height: '30px', borderRadius: '10px' }} onClick={remove}>
              <BiTrashAlt />
            </Button>
          )}
        </ButtonModal>
      </CardsArea>
    </>
  );
}

export default Card;
