
import AgentMenu from 'components/AgentMenu';
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import api from 'api';
import Cookies from 'js-cookie';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { loadingColor, warn } from 'styles/colorProvider';
import Divider from '@mui/material/Divider';
import pdf from 'assets/pdfIcon.png';
import { Header, Button, Select, ConfirmDialog, Dialog, Input } from 'components';
import {
    Container,
    ContentArea,
    Title,
    PolicyResume,
    Text,
    Footer,
    LoadingArea,
    DivImage,
    Image,
    Form
} from './styles';
import { useHistory } from 'react-router-dom';
import { success, lightGray, error } from 'styles/colorProvider';

function AgentDetails({ location }) {
    const agentId = parseInt(location.pathname.split('/backoffice/detalhes-do-corretor/')[1]);

    const history = useHistory();

    const [agentData, setAgentData] = useState({});
    const [agentDataV2, setAgentDataV2] = useState({});
    const [personal, setPersonal] = useState('');
    const [address, setAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [userData, setUserData] = useState();

    const formatBirthdate = (date) => {
        const [day, month, year] = date?.split('/');
        return `${year}-${month}-${day}`;
    };

    const loadData = async () => {
        const agentNumber = {
            method: 'GET',
            url: `/backoffice-get-agent/${agentId}`,
            headers: {
                'Content-Type': 'application/json',
                // Authorization: Cookies.get('splitrisk-authtoken'),
            },
            json: true,
        };
        console.log('agentNumber', agentNumber)
        setLoading(true);
        const response = await api(agentNumber);
        setUserData(response.data.user);
        // setUpdateChassi(response.data.vehicle.chassi);
        setAgentData(response.data);
        setLoading(false);
    };

    const handleSubmit = async () => {
        setLoadingSubmit(true);

        const data = {
            id_agent: parseInt(agentId),
            name: personal?.name,
            trade_name: personal?.tradeName,
            cnpj: personal?.cnpj,
            susep_number: personal?.susep,
            phone: personal?.phone,
            email: personal?.email,
            birthdate: formatBirthdate(personal?.birthdate),
            address: {
                zip_code: address?.cep,
                street: address?.logradouro,
                number: address?.number,
                complement: address?.complemento,
                district: address?.bairro,
                city: address?.localidade,
                state: address?.uf,
            }
        };
        console.log('data', data)

        try {
            await api({
                method: 'POST',
                url: `/backoffice-update-agent`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data,
                json: true,
            });
            setLoadingSubmit(false);
            window.location.reload();
            toast.success('Cadastro enviado com sucesso!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
            });
        } catch (error) {
            setLoadingSubmit(false);
            toast.error('Erro ao enviar cadastro!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
            });
        }
    };

    useEffect(() => loadData(), []);

    return (
        <>
            {/* {renderConfirmDialog()} */}
            {/* {renderConfirmCancel(false)} */}
            <Header />
            <Container>
                {loading && (
                    <LoadingArea>
                        <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                    </LoadingArea>
                )}
                {!loading && (
                    <ContentArea>
                        <AgentMenu agentId={agentId} agentData={agentData} />
                        <Title>
                            DETALHES DO CORRETOR
                        </Title>

                        <Divider />

                        <Form>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Nome"
                                    placeholder={agentData?.name}
                                    value={agentDataV2?.name}
                                    onChange={(e) => setPersonal({ ...personal, name: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Nome Comercial"
                                    placeholder={agentData?.trade_name}
                                    value={agentDataV2?.trade_nme}
                                    onChange={(e) => setPersonal({ ...personal, tradeName: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    mask="(99) 99999-9999"
                                    label="Telefone"
                                    placeholder={agentData?.phone}
                                    value={agentDataV2?.phone}
                                    onChange={(e) => setPersonal({ ...personal, phone: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    mask="99.999.999/9999-99"
                                    label="CNPJ"
                                    placeholder={agentData?.cnpj}
                                    value={agentDataV2?.cnpj}
                                    onChange={(e) => setPersonal({ ...personal, cnpj: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    mask="99/99/9999"
                                    label="Data de nascimento"
                                    placeholder={agentData?.birthdate}
                                    value={agentDataV2?.birthdate}
                                    onChange={(e) => setPersonal({ ...personal, birthdate: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Email"
                                    placeholder={agentData?.email}
                                    value={agentDataV2?.email}
                                    onChange={(e) => setPersonal({ ...personal, email: e.target.value })}
                                />
                            </div>
                        </Form>

                        <Title>
                            ENDEREÇO
                        </Title>

                        <Divider />

                        <Form>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="CEP"
                                    placeholder={agentData?.address?.zip_code}
                                    value={agentDataV2?.address?.zip_code}
                                    onChange={(e) => setAddress({ ...address, cep: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Rua"
                                    placeholder={agentData?.address?.street}
                                    value={agentDataV2?.address?.street}
                                    onChange={(e) => setAddress({ ...address, logradouro: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Numero"
                                    placeholder={agentData?.address?.number}
                                    value={agentDataV2?.address?.number}
                                    onChange={(e) => setAddress({ ...address, number: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Cidade"
                                    placeholder={agentData?.address?.city}
                                    value={agentDataV2?.address?.city}
                                    onChange={(e) => setAddress({ ...address, localidade: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Bairro"
                                    placeholder={agentData?.address?.district}
                                    value={agentDataV2?.address?.district}
                                    onChange={(e) => setAddress({ ...address, bairro: e.target.value })}
                                />
                            </div>
                        </Form>

                        <Title>
                            DADOS BANCÁRIOS
                        </Title>

                        <Divider />

                        <Form>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Nome do Banco"
                                    placeholder={agentData?.bank?.bank_name}
                                    value={agentDataV2?.bank?.bank_name}
                                // onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Agencia"
                                    placeholder={agentData?.bank?.agency_code}
                                    value={agentDataV2?.bank?.agency_code}
                                // onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Conta"
                                    placeholder={agentData?.bank?.current_account}
                                    value={agentDataV2?.bank?.current_account}
                                // onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                />
                            </div>
                            <div class="grid-item">
                                <Input
                                    height="35px"
                                    label="Digito conta"
                                    placeholder={agentData?.bank?.digit}
                                    value={agentDataV2?.bank?.digit}
                                // onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                />
                            </div>
                        </Form>

                        <Title>
                            FORMULÁRIO
                        </Title>
                        <Divider />

                        <div>
                            {agentData?.questions?.map((question, index) => {
                                return (<Text> {index + 1} - {question?.label}  <span> {question?.value} </span></Text>)
                            })}

                        </div>

                        <Title>
                            DOCUMENTOS
                        </Title>
                        <Divider />

                        <PolicyResume>
                            {!!agentData?.documents?.length ? (
                                <>
                                    <h3 className="section-title">Arquivos do corretor</h3>
                                    <DivImage>
                                        {agentData?.documents.map((photo) => (
                                            <>
                                                {photo?.url.split('.')[1] === 'pdf' ? (
                                                    <Image onClick={() => window.open(photo?.signedUrl, '_blank')} image={pdf} alt={photo?.description} />
                                                ) : (
                                                    <Image
                                                        onClick={() => window.open(photo?.signedUrl, '_blank')}
                                                        image={photo?.signedUrl}
                                                        alt={photo?.description}
                                                    />
                                                )}
                                            </>
                                        ))}
                                    </DivImage>
                                </>
                            ) : (
                                <>
                                    <h3 className="section-title">Arquivos do corretor</h3>
                                    <Alert style={{ marginBottom: 20 }} severity="warning">
                                        Sem arquivos!
                                    </Alert>
                                </>
                            )}
                        </PolicyResume>

                        {agentData?.status === 'created' && (
                            <Footer>
                                <div className="area-button">
                                    {/* <Button
                                        disabled={
                                            loadingSubmit
                                        }
                                        onClick={(e) => handleSubmit(e)}
                                        style={{
                                            width: '125px',
                                            borderRadius: '10px',
                                            height: '35px',
                                            backgroundColor: `${success}`,
                                            colsor: 'black',
                                        }}
                                    >
                                        Atualizar dados
                                    </Button> */}
                                </div>
                            </Footer>
                        )}
                    </ContentArea>
                )}
            </Container>
        </>
    );
}

export default AgentDetails;
