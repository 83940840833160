/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Header, ProposalMenu, Button, Dialog } from 'components';
import {
  Container,
  ContentArea,
  Title,
  ItemPartner,
  Subtitle,
  Text,
  LoadingArea,
  SliderContent,
  ModalButton,
  ModalError,
} from './styles';
import Cookies from 'js-cookie';
import api from 'api';
import ReactLoading from 'react-loading';
import { loadingColor } from 'styles/colorProvider';
import { toast } from 'react-toastify';
import { SideMenu } from 'components/index';

function BackOfficeProductsPartners({ history, location }) {
  const proposalId = parseInt(location.pathname.split('/detalhes-da-proposta/')[1]);

  const [partnerData, setPartnerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [values, setValues] = useState({
    commercialization: 0,
    loading: 0,
    // max_loading: 0,
  });

  const getPartnerInfo = async () => {
    try {
      setLoading(true);

      const proposalNumber = {
        method: 'GET',
        url: `/list-backoffice-partner`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        json: true,
      };

      const { data } = await api(proposalNumber);

      setValues({
        commercialization: data.commercialization,
        loading: data.loading_partner,
        max_loading: data.max_loading_partner,
      });
      setPartnerData(data);
    } catch (error) {
      console.log('Erro listando!');
    } finally {
      setLoading(false);
    }
  };

  const applyModalChanges = async () => {
    try {
      setModalLoading(true);

      await api({
        method: 'POST',
        url: `/update-backoffice-partner`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          commercialization: values.commercialization,
          loading_partner: values.loading,
        },
        json: true,
      });

      setOpenModalEdit(false);
      await getPartnerInfo();
    } catch (error) {
      // console.log('Erro atualizando os valores!');

      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } finally {
      setModalLoading(false);
    }
  };

  useEffect(() => getPartnerInfo(), []);

  const renderEditPartner = () => (
    <Dialog open={openModalEdit} onClose={() => setOpenModalEdit(false)} width="100%" title="Editar parceiro">
      <p>Comercialização</p>
      <SliderContent
        track={false}
        aria-labelledby="track-false-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(e) => {
          return `${Math.round(e)}%`;
        }}
        getAriaValueText={(value) => `${value}`}
        // defaultValue={grievanceValue || 0}
        defaultValue={values.commercialization || 0}
        value={values.commercialization}
        onChange={(event) => {
          setValues({
            ...values,
            commercialization: event.target.value,
          });
        }}
        onChangeCommitted={() => {
          // setDiscountValue(0);
          // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
        }}
        min={0}
        max={100}
        step={1}
        marks={[
          {
            value: 0,
            label: `0%`,
          },
          {
            value: 100,
            label: `100%`,
          },
        ]}
      />
      <p>Carregamento</p>
      <SliderContent
        track={false}
        aria-labelledby="track-false-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(e) => {
          return `${Math.round(e)}%`;
        }}
        getAriaValueText={(value) => `${value}`}
        // defaultValue={grievanceValue || 0}
        defaultValue={values.loading || 0}
        value={values.loading}
        onChange={(event) => {
          setValues({
            ...values,
            loading: event.target.value,
          });
        }}
        onChangeCommitted={() => {
          // setDiscountValue(0);
          // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
        }}
        min={0}
        max={values.max_loading}
        step={1}
        marks={[
          {
            value: 0,
            label: `0%`,
          },
          {
            value: values.max_loading,
            label: `${values.max_loading}%`,
          },
        ]}
      />

      <ModalError showError={values.loading > values.commercialization || values.max_loading > values.commercialization}>
        A comercialização não pode ser menor que o carregamento {values.loading > values.commercialization ? 'atual' : 'máximo'}
      </ModalError>

      {/* <p>Carregamento máximo</p>
      <SliderContent
        track={false}
        aria-labelledby="track-false-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(e) => {
          return `${Math.round(e)}%`;
        }}
        getAriaValueText={(value) => `${value}`}
        // defaultValue={grievanceValue || 0}
        defaultValue={values.commercialization || 0}
        value={values.commercialization}
        onChange={(event) => {
          setValues({
            ...values,
            commercialization: event.target.value,
          });
        }}
        onChangeCommitted={() => {
          // setDiscountValue(0);
          // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
        }}
        min={0}
        max={100}
        step={1}
        marks={marks}
      /> */}
      <ModalButton>
        {modalLoading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

        <Button
          height="35px"
          disabled={values.loading > values.commercialization || modalLoading || loading}
          onClick={applyModalChanges}
        >
          Aplicar alterações
        </Button>
      </ModalButton>
    </Dialog>
  );

  return (
    <>
      {/* <Header /> */}
      <Header />
      <Container>
        {renderEditPartner()}
        {/* {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )} */}
        {!loading && (
          <>
            <SideMenu />
            <ContentArea>
              <Title>CONDIÇÕES DO PARCEIRO</Title>
              <Button onClick={() => setOpenModalEdit(true)}>Editar valores</Button>

              <ItemPartner>
                <Subtitle>Valores das condições</Subtitle>
                <Text>
                  Comercialização: <span>{partnerData?.commercialization}%</span>
                </Text>
                <Text>
                  Carregamento máximo: <span>{partnerData?.max_loading_partner}%</span>
                </Text>
                <Text>
                  Carregamento atual: <span>{partnerData?.loading_partner}%</span>
                </Text>
              </ItemPartner>
            </ContentArea>
          </>
        )}
      </Container>
    </>
  );
}

export default BackOfficeProductsPartners;
