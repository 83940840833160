import styled from 'styled-components';
import backgroundLogin from 'assets/backgroundLogin.png';
import logoDefault from 'assets/logo-default.png';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ContainerArea = styled.div`
  display: flex;
  margin-top: 10vh;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: flex-start;
  text-align: ${(props) => props.textAlign || 'flex-start'};
  align-items: flex-start;
  width: 88%;
  height: 100%;

  h1 {
    font-size: 28px;
    color: #000;
    text-transform: uppercase;

    @media (max-width: 1000px) {
      margin-top: 50px;
    }
  }
  p {
    color: black;
    font-weight: 600;
    white-space: pre-line;
  }

  span {
    font-size: 16px;
    color: #868686;
    font-weight: 400;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 85%;
    margin: 15px 0 30px 0;
    padding-bottom: 100px;
  }
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 318px 318px;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  .grid-items {
    justify-content: center;
    align-items: center;

    .delete {
      border: none !important;
      color: #22305a;
      align-items: center;
      justify-content: center;
      background: transparent;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      :hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 52%;
  height: 100%;
  background-image: url(${backgroundLogin});
  background-size: cover;
  overflow-y: hidden;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 65%;
  height: 100vh;
  /* background-color: #11882d; */
  overflow-y: auto;
  position: relative;
  /* input:focus {outline:none!important;} */

  .areaLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .logo {
    height: 36px;
    width: 209px;
    background-image: url(${logoDefault});
    background-size: contain;
    background-repeat: no-repeat;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .div-separator {
    display: flex;
    width: 93%;
    margin-top: 50px;
    border: 1px solid #eee;
  }

  .text-button {
    cursor: pointer;
    font-size: 14px;
    transition: all ease-in-out 0.3s;
    color: #f2bb36;
    &:hover {
      opacity: 0.6;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;

    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .add {
    background-color: transparent;
    font-size: 15px;
    border-radius: 3px;
    border: 1px solid black;
    padding: 5px;
    color: black;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    :hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    height: 100%;
    overflow: none;
  }

  .modalButton {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    button {
      display: flex;
      width: 180px;
      height: 40px;
      border: none;
      border-radius: 10px;
      background-color: #22305a;
      font-size: 15px;
      color: white;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all ease-in-out 0.2s;

      :hover {
        opacity: 0.9;
      }

      :disabled {
        opacity: 0.6;
        cursor: default;
      }

      @media (max-width: 1000px) {
        width: 50%;
      }
    }
  }

  h3 {
    font-size: 25px;
    color: white;
    margin: 20px 0 10px 0;
  }
`;

export const ContainerUpload = styled.div`
  display: flex;
  width: 30%;
  height: 100px;
  flex-direction: column;
  margin: 8px;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid green;
  border-radius: 12px;

  div {
    display: flex;
    cursor: pointer;
  }
`;

export const InputUpload = styled.input`
  display: flex;
  border: none;
`;

export const Upload = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .fileName {
    font-size: 12px;
    margin-right: 25px;
    color: white;
    font-weight: 500;
    margin-left: 15px;
    width: 100%;

    @media (max-width: 1000px) {
      margin: 10px 0 0 0;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    width: 150px;
    border-radius: 5px;
    border: none;
    font-size: 12px;
    color: rgb(59, 125, 192);
    cursor: pointer;
    background-color: #f2bb36;

    :hover {
      transition: all 0.3s ease-in-out;
      opacity: 0.8;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const Footer = styled.footer`
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    width: 100%;
    height: 25vh;
    background: #22305a;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -40px;

    .socialMidia {
      font-size: 16px;
      color: white;
      margin: 50px 0 -50px 0;
    }

    .socialIcon {
      margin-top: 75px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;
      width: 55%;
    }
  }
`;

export const DialogBody = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 30px;

  button {
    display: flex;
    width: 180px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: #22305a;
    font-size: 15px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    :hover {
      opacity: 0.9;
    }

    :disabled {
      opacity: 0.6;
      cursor: default;
    }

    @media (max-width: 1000px) {
      width: 50%;
    }
  }
`;

export const ExperienceItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  margin: 15px 0px;

  p {
    margin: 2px;
  }

  b {
    margin-right: 10px;
  }

  button {
    width: 100%;
    margin: 5px 0px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    transition: all ease-in-out 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px 0px;
    &:hover {
      opacity: 0.5;
    }
  }
`;
