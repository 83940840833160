import React, { useState } from 'react';
import api from 'api';
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';
import { Container, Form } from './styles';
import { Button, Input } from 'components';
import { toast } from 'react-toastify';
import { FiX } from 'react-icons/fi';
import { white } from 'styles/colorProvider';

function ChangePassword({ handleCloseModal }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await api({
        method: 'PATCH',
        url: `/user-password`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          oldPassword: currentPassword,
          newPassword,
        },
        json: true,
      });
      toast.success('Senha alterada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
      handleCloseModal();
    } catch (e) {
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form>
        <div className="inputs">
          <Input
            label="Senha atual"
            placeholder="Digite sua senha atual"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            password
            type="password"
          />
          <Input
            label="Nova senha"
            placeholder="Digite sua nova senha"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            password
            type="password"
          />
        </div>
        <div className="buttonArea">
          <Button disabled={loading || !currentPassword || !newPassword || newPassword.length < 6} onClick={handleChangePassword}>
            {loading ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Alterar senha'}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default ChangePassword;
