/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from 'api';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { Header, Button, Input } from 'components';
import { Container, ContentArea, PaymentArea, LoadingArea, TitleArea } from './styles';
import { loadingColor } from 'styles/colorProvider';
import barCode from 'assets/barcode.svg';
import Lottie from 'react-lottie';
import { FaBarcode } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import successAnimation from 'assets/success.json';

function Billet({ location }) {
  const chargeId = parseInt(location.pathname.split('/boleto/')[1]);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingBillet, setLoadingBillet] = useState(false);
  const [chargeData, setChargeData] = useState([]);
  const [billetPaymant, setBilletPayment] = useState();
  const [description, setDescription] = useState('');
  const [step, setStep] = useState(1);

  const loadData = async () => {
    setLoading(true);
    const responseCharge = await api({
      method: 'GET',
      url: `/charge-client/${chargeId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });
    setChargeData(responseCharge?.data);
    setLoading(false);
  };

  const handleGenerateBillet = async () => {
    setStep(step + 1);
    setLoadingBillet(true);
    const resp = await api({
      method: 'POST',
      url: `/company-create-billet`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        id_charge: chargeId,
        description: description,
      },
      json: true,
    });
    setBilletPayment(resp.data);
    setLoadingBillet(false);
  };

  const handleCopyBillet = () => {
    navigator.clipboard.writeText(billetPaymant?.billet?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.barcode);
    toast.success('Código de barras copiado com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  };

  const handleOpenBillet = () => {
    window.open(billetPaymant?.billet?.bill?.charges[0]?.print_url, '_blank').focus();
  };

  useEffect(() => loadData(), []);

  return (
    <>
      <Header />
      <Container>
        <ContentArea>
          {loading ? (
            <LoadingArea>
              <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
            </LoadingArea>
          ) : (
            <>
              <TitleArea>
                {step === 1 ? (
                  <h1>
                    <IconButton
                      size="small"
                      style={{ marginRight: 10 }}
                      onClick={() => history.push(`/backoffice/faturas/${chargeData?.id_policy}`)}
                    >
                      <MdChevronLeft size={24} />
                    </IconButton>
                    GERAR BOLETO
                  </h1>
                ) : null}
              </TitleArea>
              <PaymentArea>
                {step === 1 && (
                  <>
                    {' '}
                    <img src={barCode} alt="Boleto" />
                    <Input
                      label="Descrição do boleto"
                      placeholder="Ex: veículo placa AAA-9999"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <Button onClick={handleGenerateBillet} style={{ marginTop: '20px' }}>
                      Gerar boleto
                    </Button>
                  </>
                )}
                {step === 2 && (
                  <>
                    {loadingBillet && (
                      <div className="loadingArea" style={{ marginTop: '50px' }}>
                        <ReactLoading color={loadingColor} height={50} width={50} type="spin" />
                        <h3>Por favor, aguarde. Estamos gerando o boleto!</h3>
                      </div>
                    )}
                    {!loadingBillet && (
                      <div className="billetArea" style={{ marginTop: 50 }}>
                        <Lottie
                          autoplay
                          options={{
                            loop: false,
                            autoplay: true,
                            animationData: successAnimation,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height={180}
                          width={180}
                        />
                        <h3>O seu boleto está pronto!</h3>
                        <div className="buttonArea" style={{ flexDirection: 'column', alignItems: 'center' }}>
                          <Button height="35px" width="220px" onClick={handleOpenBillet}>
                            <FaBarcode style={{ marginRight: 10 }} />
                            Visualizar boleto
                          </Button>
                          <Button height="35px" width="220px" onClick={handleCopyBillet}>
                            <FiCopy style={{ marginRight: 10 }} />
                            Copiar código de barras
                          </Button>
                          <Button
                            outlined
                            height="35px"
                            width="220px"
                            onClick={() => history.push(`/backoffice/faturas/${chargeData?.id_policy}`)}
                          >
                            Sair
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </PaymentArea>
            </>
          )}
        </ContentArea>
      </Container>
    </>
  );
}

export default Billet;
