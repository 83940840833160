import styled from 'styled-components';
import { lighterGray, secondary, primary } from 'styles/colorProvider';

export const Container = styled.div`
  position: relative;
  height: 88vh;
  width: ${(props) => (props.collapsed ? '50px' : '200px')};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.collapsed ? 'center' : 'flex-start')};
  justify-content: flex-start;
  background-color: ${lighterGray};
  box-sizing: border-box;
  padding: 20px 10px;
  transition: all ease-in-out 0.3s;

  .select {
    color: ${primary};
    background: ${secondary};

    :hover {
      color: ${secondary};
    }
  }
`;

export const MenuItem = styled.div`
  height: 40px;
  width: ${(props) => (props.collapsed ? '40px' : '150px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.collapsed ? 'center' : 'flex-start')};
  transition: all ease-in-out 0.2s;
  border-radius: 5px;
  margin: 5px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.selected ? secondary : primary)};
  padding: ${(props) => (props.collapsed ? '0px' : '0px 15px')};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  &&:hover {
    background-color: ${(props) => !props.disabled && '#fff'};
    box-shadow: ${(props) => !props.disabled && 'rgba(0, 0, 0, 0.05) -4px 9px 25px -6px'};
    color: ${(props) => !props.disabled && secondary};
  }

  .menuText {
    display: ${(props) => (props.collapsed ? 'none' : 'flex')};
    text-align: left;
    margin-left: ${(props) => (props.collapsed ? '0px' : '15px')};
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
  }
`;

export const MenuItemCollapsable = styled.div`
  height: 40px;
  width: ${(props) => (props.collapsedSidebar ? '40px' : '150px')};
  justify-content: ${(props) => (props.collapsedSidebar ? 'center' : 'flex-start')};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all ease-in-out 0.2s;
  border-radius: 5px;
  margin: 5px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.selected ? secondary : primary)};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  padding: ${(props) => (props.collapsedSidebar ? '0px' : '0px 15px')};

  .select {
    color: ${primary};
    background: ${secondary};

    :hover {
      color: ${secondary};
    }
  }

  &&:hover {
    background-color: ${(props) => !props.disabled && '#fff'};
    box-shadow: ${(props) => !props.disabled && 'rgba(0, 0, 0, 0.05) -4px 9px 25px -6px'};
    color: ${(props) => !props.disabled && secondary};
  }

  .menuText {
    display: ${(props) => (props.collapsedSidebar ? 'none' : 'flex')};
    text-align: left;
    margin-left: ${(props) => (props.collapsedSidebar ? '0px' : '15px')};
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    user-select: none;
  }

  .arrow {
    font-size: 14px;
    display: ${(props) => (props.collapsedSidebar ? 'none' : 'flex')};
    transform: ${(props) => (props.collapsedItem ? 'rotate(0deg)' : 'rotate(90deg)')};
    transition: transform 0.3s ease-in-out;
    margin-left: 10px;
  }
`;

export const SubItems = styled.div`
  display: ${(props) => (props.collapsedItem ? 'none' : 'flex')};
  width: ${(props) => (props.collapsedSidebar ? '40px' : '180px')};
  margin: 0 0 5px 5px;
  flex-direction: column;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  transition: all ease-in-out 0.2s;
  opacity: ${(props) => (props.collapsedItem ? '0' : '1')};
  overflow-y: auto;
  overflow-x: hidden;

  /* Estilize a barra de rolagem */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${secondary};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${secondary}AA;
  }
`;

export const SubItem = styled.div`
  height: 40px;
  width: ${(props) => (props.collapsedSidebar ? '40px' : '130px')};
  justify-content: ${(props) => (props.collapsedSidebar ? 'center' : 'flex-start')};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all ease-in-out 0.2s;
  border-radius: 5px;
  margin: 5px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.selected ? secondary : primary)};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  padding: ${(props) => (props.collapsedSidebar ? '0px' : '0px 15px')};

  &&:hover {
    color: ${(props) => !props.disabled && secondary};
  }

  .menuText {
    display: ${(props) => (props.collapsedSidebar ? 'none' : 'flex')};
    text-align: left;
    margin-left: ${(props) => (props.collapsedSidebar ? '0px' : '15px')};
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    user-select: none;

    &:hover {
      box-shadow: ${(props) => !props.disabled && 'rgba(0, 0, 0, 0.05) -4px 9px 25px -6px'};
      color: ${(props) => !props.disabled && secondary};
    }
  }
`;

export const MenuButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: ${(props) => (props.collapsed ? '40px' : '150px')};
  justify-content: ${(props) => (props.collapsed ? 'center' : '150px')};
  padding: ${(props) => (props.collapsed ? '0px' : '0px 20px')};
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &&:hover {
    opacity: 0.6;
  }
`;
