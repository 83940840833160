import styled from 'styled-components';
import { primary, secondary, white } from 'styles/colorProvider';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px;

  .select {
    background-color: ${secondary};
    border-radius: 50px;
    padding: 1.2%;
    height: 10%;
    color: ${primary};
    transition: all ease-in-out 0.3s;

    :hover {
      color: ${white};
    }
  }

  div:nth-child(1) {
    margin-left: 0;
  }

  @media (max-width: 1000px) {
    overflow-x: auto;
    margin-top: 10%;
    margin-bottom: -2%;

    ::-webkit-scrollbar {
      width: 0;
    }

    .select {
      padding: 4%;
    }
  }
`;

export const ItemMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 2%;

  :hover {
    color: ${secondary};
  }

  @media (max-width: 1000px) {
    margin-right: 5%;
  }
`;

export const TextItem = styled.p`
  font-size: 16px;
  margin-left: 8px;
`;
