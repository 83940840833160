import styled from 'styled-components';
import { primary, secondary, white, lighterGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;

  .select {
    background-color: ${secondary};
    border-radius: 50px;
    padding: 5px 20px;
    height: 30px;
    color: ${primary};

    :hover {
      color: ${white};
    }
  }

  .extractArea {
    @media (max-width: 1000px) {
      width: 90%;
    }
  }
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const MenuArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ItemMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 2%;
  transition: all ease-in-out 0.3s;

  :hover {
    color: ${secondary};
  }

  @media (max-width: 1000px) {
    margin-right: 5%;
  }
`;

export const TextItem = styled.p`
  font-size: 16px;
  margin-left: 8px;
`;

export const ContainerCards = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

`
export const CardClient = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: -15px;
    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
      flex-wrap:nowrap;
    }

`
export const CardsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  h3 {
    margin-top: 0px;
  }

  .cardLine {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 15px;
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      width: 100%;
    }
  }

  .cardLineBox {
    background-color: ${lighterGray};
    height: 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 20px;
    margin-bottom: 3px;
    width: 100%;
    label {
      color: ${primary};
      font-size: 12px;
      cursor: pointer;
    }
  }

  .cardForm {
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .cardBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-left: 30px;
    height: 100%;
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      margin-bottom: 30px;
    }
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin: 0px;
    padding-right: 50px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  
  @media (max-width: 1000px) {
    margin: 0px 0 80px 0;
  }

  h3{
    font-size: 22px;
    color: ${primary};
  }
`