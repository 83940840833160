import Tooltip from '@mui/material/Tooltip';
import PartnersData from 'utils/corporateSettings.json';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { formatPhone } from 'react-data-formatter';

export const dataColumns = [
  {
    selector: (row) => (
      <Tooltip title={`${PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.name}`}>
        <img
          className="partnerIcon"
          src={PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.logos?.small}
          alt={PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.name}
        />
      </Tooltip>
    ),
    width: '5%',
    sortable: true,
  },
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Usuário',
    selector: (row) => row?.name || 'Não informado',
    width: '20%',
    sortable: true,
  },
  {
    name: 'CPF',
    selector: (row) => row?.cpf,
    width: '15%',
    sortable: true,
  },
  {
    name: 'E-mail',
    selector: (row) => row?.email,
    width: '30%',
    sortable: true,
  },
  {
    name: 'Telefone',
    selector: (row) => formatPhone(row?.phone) || 'Não informado',
    width: '15%',
    sortable: true,
  },
  {
    selector: (row) => <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />,
    width: '5%',
    sortable: true,
  },
];
