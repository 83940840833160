/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';
import { MdChevronLeft } from 'react-icons/md';
import { Header, ClaimFileItem, Button } from 'components';
import { Container, ContentArea, ContainerHeader, LoadingArea, ContentFile, Image } from './styles';
import { useHistory } from 'react-router-dom';
import { loadingColor, success } from 'styles/colorProvider';
import { saveFilesBucket } from 'utils/aws';
import { toast } from 'react-toastify';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import FilesAssets from 'assets/filesImage.svg';

function ThirdPartyFile({ location }) {
  const thirdPartyId = parseInt(location.pathname.split('/backoffice/terceiro-arquivo/')[1]);
  const fileInputRef = useRef();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [thirdPartyData, setThirdPartyData] = useState();
  const [protocol, setProtocol] = useState();
  const [upFiles, setupFiles] = useState([]);
  const [counter, setCounter] = useState(1);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const loadData = async () => {
    setLoading(true);
    const policyNumber = {
      method: 'GET',
      url: `/claim-third/${thirdPartyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };
    const response = await api(policyNumber);
    setThirdPartyData(response.data);
    setProtocol(response.data?.TB_CLAIM?.protocol);
    setLoading(false);
  };

  const handleAddFile = (e) => {
    const oldList = upFiles;
    oldList.push(e.target.files[0]);
    setupFiles(oldList);
    setCounter(counter + 1);
  };

  const handleRemoveFile = (index) => {
    const oldList = upFiles;
    oldList.splice(index, 1);
    setupFiles(oldList);
    setCounter(counter + 1);
  };

  const handleUploadFiles = async (resp) => {
    setLoading(true);
    upFiles.map((i) => {
      i.file_type = null;
      i.description = null;
    });

    const allFiles = upFiles;

    for (const file of allFiles) {
      const ext = file.name.replaceAll(' ', '').toLowerCase().split('.').pop();
      saveFilesBucket({
        Bucket: process.env.AWS_CLAIMS_FILES_BUCKET,
        Body: file,
        Key: `${protocol}/${file.file_type}/${new Date().getTime()}.${ext}`,
        onEnd: async (err, data) => {
          const list = [...uploadedFiles, { type_file: file.file_type, description: file.description, url: data?.file }];
          setUploadedFiles(list);

          await api({
            method: 'POST',
            url: `/send-file-third`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: Cookies.get('splitrisk-authtoken'),
            },
            data: {
              files: list,
              id_claim: thirdPartyData?.TB_CLAIM?.id,
              id_claim_third: thirdPartyId,
            },
            json: true,
          });
          setLoading(false);
          toast.success('Arquivo enviado com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          history.push(`/backoffice/detalhes-do-sinistro/${thirdPartyData?.TB_CLAIM?.id}`);
        },
      });
    }
  };

  useEffect(() => loadData(), []);

  return (
    <>
      <Header />
      <Container>
        {loading ? (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        ) : (
          <ContentArea>
            <ContainerHeader>
              <h1>
                <IconButton
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={() => history.push(`/backoffice/detalhes-do-sinistro/${thirdPartyData?.TB_CLAIM?.id}`)}
                >
                  <MdChevronLeft size={24} />
                </IconButton>
                ARQUIVOS DE TERCEIROS
              </h1>
            </ContainerHeader>
            <Image>
              <img src={FilesAssets} alt="" />
              <div className="description">
                <h3>Descrição do terceiro:</h3>
                <p>
                  Nome: <span>{thirdPartyData?.name}</span>
                </p>
                <p>
                  Telefone: <span>{thirdPartyData?.phone}</span>
                </p>
                <p>
                  Placa:{' '}
                  <span>
                    {thirdPartyData?.plate === '' || thirdPartyData?.plate === null ? 'Sem placa' : thirdPartyData?.plate}
                  </span>
                </p>
                <p>
                  Protocolo do sinistro: <span>{thirdPartyData?.TB_CLAIM?.protocol}</span>
                </p>
                <label
                  style={{ cursor: 'pointer', color: `${success}`, marginTop: '50px' }}
                  className="new-third-button"
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current.click();
                  }}
                >
                  <AiOutlinePlusCircle size={15} style={{ marginRight: 5 }} /> Enviar arquivo
                </label>
                <input
                  type="file"
                  accept="image/*, application/pdf"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={(e) => handleAddFile(e)}
                />
              </div>
            </Image>
            {upFiles.length >= 1 ? (
              <ContentFile>
                <h3>Arquivos</h3>
                {upFiles?.map((report, index) => (
                  <ClaimFileItem key={index} data={report} onClick={() => handleRemoveFile(index)} />
                ))}
                <Button onClick={handleUploadFiles} style={{ margin: 0, marginTop: 30 }}>
                  {loading ? <ReactLoading color="white" height={24} width={24} type="spin" /> : 'Enviar'}
                </Button>
              </ContentFile>
            ) : null}
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default ThirdPartyFile;
