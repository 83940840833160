import { MdChevronLeft } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { Container, ItemMenu, TextItem } from './styles';

function ProposalBackMenu() {
  const history = useHistory();

  return (
    <Container>
      <ItemMenu
        onClick={() => {
          history.push('/backoffice/propostas');
        }}
      >
        <MdChevronLeft size="18" />
        <TextItem>Voltar</TextItem>
      </ItemMenu>
    </Container>
  );
}

export default ProposalBackMenu;
