import styled from 'styled-components';
import { primary, white, middleGray, lightGray, darkGray, error } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100vw;
  margin-bottom: 100px;
  display: flex;
  align-items: top;
  justify-content: center;
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 360px 360px 350px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
  `;

export const DivImage = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
width: 98%;
padding: 20px;
border: 1px solid ${lightGray};
margin-right: 30px;
border-radius: 5px;

hr {
  width: 95%;
  margin: -5px 5% 0 0;
  background-color: ${lightGray};
  border: none;
  height: 1px;
}

@media (max-width: 1000px) {
  width: 80%;
  margin: 20px 0;
}
`;

export const Image = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 100px;
  margin: 5px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &&:hover {
    opacity: 0.8;
  }
`;


export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 1000px) {
    align-items: center;
    width: 95%;
  }

  .div-separator {
    display: flex; 
    width: 93%; 
    margin-top: 50px;
    border: 0.1px solid gray;
  }

  .div-details {
    flex-direction: row;
    display: flex;
    width: 100%;
  }

  .buttonCancelProposal {
width: 150px;
display: flex;
height:30px;
margin-left: 90%;
margin-top: -60px;
background-color: ${error}
  }
`;

export const Title = styled.h1`
  font-size: ${(props) => props.fontSize || '28px'};
  color: ${(props) => props.color || primary};

  @media (max-width: 1000px) {
    text-align: center;
    margin-top: 10%;
    width: 100%;
  }
`;

export const PolicyResume = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  /* margin-top: px; */
  /* background-color: blue; */
  /* width: 60%;  */

`;

export const PolicyInformationArea = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  margin-top: 1%;

  p {
    margin: 0px;
  }

  

  .tempo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;

    @media (max-width: 1000px) {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  .lastItem {
    height: 9%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const ItemPolicy = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  width: 41%;
  padding: 2.2% 3%;
  margin-top: 20px;

  .imageArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .buttonArea {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 10%;
    margin-top: 15%;
  }
`;
export const Edit = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    width: 55px;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${darkGray};

  span {
    color: ${middleGray};
  }
`;
export const Line = styled.hr`
  background-color: #eee;
  width: 100%;
  height: 1px;
  border: none;
  margin: 0;
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ModalButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const Footer = styled.div`
  display: flex;
  position: fixed;
  height: 8vh;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: ${lightGray};
  color: white;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1000px) {
    height: 10vh;
  }

  .area-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 20vw;
    margin-right: 40px;
    height: 100%;
    align-items: center;

    @media (max-width: 1000px) {
      width: 100vw;
      margin: 0;
      justify-content: space-around;
    }
  }
`;

export const InspectionImage = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  margin: 5px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  &&:hover {
    opacity: 0.8;
  }
`;
