/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from 'api';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import Divider from '@mui/material/Divider';
import { toast } from 'react-toastify';
import {
  Header,
  SideMenu,
  ConfirmDialog,
  CoverageItem,
  ExclusionRuleItem,
  CoverageAreaItem,
  Button,
  Dialog,
  Input,
  Select,
} from 'components';
import { Container, ContentArea, Div, DataArea, Title, Subtitle, LoadingArea, TitleArea } from './styles';
import { loadingColor } from 'styles/colorProvider';

function Settings() {
  const [loading, setLoading] = useState(false);
  const [coverageList, setCoverageList] = useState([]);
  const [coverageAreaList, setCoverageAreaList] = useState([]);
  const [exclusionRuleList, setExclusionRuleList] = useState([]);

  const [createCoverageData, setCreateCoverageData] = useState({});
  const [createAreaCoverageData, setCreateAreaCoverageData] = useState({});
  const [createRejectModelData, setCreateRejectModelData] = useState({});

  const [deleteCoverageData, setDeleteCoverageData] = useState(false);
  const [deleteAreaCoverageData, setDeleteCreateAreaCoverageData] = useState(false);
  const [deleteRejectModelData, setDeleteRejectModelData] = useState(false);

  const [openCreateCoverage, setOpenCoverage] = useState(false);
  const [openCreateAreaCoverage, setOpenCreateAreaCoverage] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);

  const handleCreateProduct = async (e) => {
    setLoading(true);
    try {
      await api({
        method: 'POST',
        url: `/create-product`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          ebao_code: createCoverageData?.ebao_code,
          name: createCoverageData?.name,
          type: createCoverageData?.type,
          lmi: parseFloat(createCoverageData?.lmi),
          value: parseFloat(createCoverageData?.value),
          plan: 0,
          active: 1,
          default_deductible_type: createCoverageData?.default_deductible_type,
          default_deductible_value: parseFloat(createCoverageData?.default_deductible_value),
          reduced_deductible_type: createCoverageData?.reduced_deductible_type,
          reduced_deductible_value: parseFloat(createCoverageData?.reduced_deductible_value),
          deductible_min: parseFloat(createCoverageData?.deductible_min),
          deductible_max: parseFloat(createCoverageData?.deductible_max),
          category: createCoverageData?.category,
        },

        json: true,
      });
      setLoading(false);
      setOpenCoverage(false);
      window.location.reload();
    } catch (e) {
      setLoading(false);

      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleDeleteProduct = async (e) => {
    setLoading(true);
    try {
      await api({
        method: 'POST',
        url: `/delete-product`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id: deleteCoverageData?.id,
        },

        json: true,
      });
      handleCloseProduct();
      setLoading(false);
      window.location.reload();
    } catch (e) {
      setLoading(false);

      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleCreateAddressRules = async (e) => {
    setLoading(true);
    try {
      await api({
        method: 'POST',
        url: `/create-address-rules`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          zipcode_from: createAreaCoverageData?.zipcode_from,
          zipcode_to: createAreaCoverageData?.zipcode_to,
          type: 'accept',
          description: createAreaCoverageData?.description,
        },

        json: true,
      });
      setLoading(false);
      window.location.reload();
    } catch (e) {
      setLoading(false);

      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleDeleteAddressRules = async (e) => {
    setLoading(true);
    try {
      await api({
        method: 'POST',
        url: `/delete-address-rules`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id: deleteAreaCoverageData?.id,
        },

        json: true,
      });
      setLoading(false);
      handleCloseArea();
      window.location.reload();
    } catch (e) {
      setLoading(false);

      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleCreateVehicleRules = async (e) => {
    setLoading(true);
    try {
      await api({
        method: 'POST',
        url: `/create-vehicle-rules`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          parameter: createRejectModelData?.parameter,
          condition: createRejectModelData?.condition,
          value: createRejectModelData?.value,
          description: createRejectModelData?.description,
        },

        json: true,
      });
      setLoading(false);
      window.location.reload();
    } catch (e) {
      setLoading(false);

      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleDeleteVehicleRules = async (e) => {
    setLoading(true);
    try {
      await api({
        method: 'POST',
        url: `/delete-vehicle-rules`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id: deleteRejectModelData?.id,
        },

        json: true,
      });
      setLoading(false);
      handleCloseVehicle();
      window.location.reload();
    } catch (e) {
      setLoading(false);

      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/get-settings`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });

    setCoverageList(resp?.data?.coverages);
    setCoverageAreaList(resp?.data?.coverageAreas);
    setExclusionRuleList(resp?.data?.exclusionRules);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderConfirmDialogProduct = () => (
    <ConfirmDialog
      open={deleteCoverageData}
      onCancel={handleCloseProduct}
      content="Tem certeza de que deseja excluir esta cobertura?"
      title="Excluir cobertura"
      onConfirm={handleDeleteProduct}
    />
  );
  const renderConfirmDialogArea = () => (
    <ConfirmDialog
      open={deleteAreaCoverageData}
      onCancel={handleCloseArea}
      content="Tem certeza de que deseja excluir esta área de cobertura?"
      title="Excluir área de cobertura"
      onConfirm={handleDeleteAddressRules}
    />
  );
  const renderConfirmDialogVehicle = () => (
    <ConfirmDialog
      open={deleteRejectModelData}
      onCancel={handleCloseVehicle}
      content="Tem certeza de que deseja remover esta regra de exclusão?"
      title="Excluir regra"
      onConfirm={handleDeleteVehicleRules}
    />
  );

  const handleCloseProduct = () => {
    setDeleteCoverageData(false);
  };
  const handleCloseArea = () => {
    setDeleteCreateAreaCoverageData(false);
  };
  const handleCloseVehicle = () => {
    setDeleteRejectModelData(false);
  };

  return (
    <>
      {renderConfirmDialogProduct()}
      {renderConfirmDialogArea()}
      {renderConfirmDialogVehicle()}
      <Header />
      <Container>
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>CONFIGURAÇÕES</Title>
          </Div>

          {loading && (
            <LoadingArea>
              <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
            </LoadingArea>
          )}

          {!loading && (
            <DataArea>
              <TitleArea>
                <Subtitle>Coberturas</Subtitle>
                <Button height="25px" onClick={() => setOpenCoverage(true)}>
                  Adicionar
                </Button>
              </TitleArea>

              {coverageList?.sort()?.map((item) => (
                <CoverageItem item={item} onConfirm={handleDeleteProduct} deleteItem={() => setDeleteCoverageData(item)} />
              ))}

              <TitleArea style={{ marginTop: 30 }}>
                <Subtitle>Área de cobertura</Subtitle>
                <Button height="25px" onClick={() => setOpenCreateAreaCoverage(true)}>
                  Adicionar
                </Button>
              </TitleArea>

              {coverageAreaList?.map((item) => (
                <CoverageAreaItem
                  item={item}
                  onConfirm={handleDeleteAddressRules}
                  deleteItem={() => setDeleteCreateAreaCoverageData(item)}
                />
              ))}

              <TitleArea style={{ marginTop: 30 }}>
                <Subtitle>Regras de exclusão</Subtitle>
                <Button height="25px" onClick={() => setOpenRejectModel(true)}>
                  Adicionar
                </Button>
              </TitleArea>

              {exclusionRuleList?.map((item) => (
                <ExclusionRuleItem
                  item={item}
                  onConfirm={handleDeleteVehicleRules}
                  deleteItem={() => setDeleteRejectModelData(item)}
                />
              ))}
            </DataArea>
          )}
        </ContentArea>

        {/* -----------------------------MODAL DE ADICIONAR COBERTURA-------------------------------------*/}
        <>
          <Dialog open={openCreateCoverage} onClose={() => setOpenCoverage(false)} width="80%" title="Adicionar cobertura">
            <div>
              <Select
                label="Tipo de cobertura"
                height="30px"
                value={createCoverageData?.category}
                onChange={(e) => setCreateCoverageData({ ...createCoverageData, category: e.target.value })}
              >
                <option value="">Selecionar</option>
                <option value="basic">Básica</option>
                <option value="additional">Adicional</option>
              </Select>

              <Input
                label="Nome da cobertura"
                height="30px"
                value={createCoverageData?.name}
                onChange={(e) => setCreateCoverageData({ ...createCoverageData, name: e.target.value })}
              />

              <Input
                label="Código EbaoTech"
                height="30px"
                value={createCoverageData?.ebao_code}
                onChange={(e) => setCreateCoverageData({ ...createCoverageData, ebao_code: e.target.value })}
                mask={'a9999'}
              />

              <Select
                label="Tipo de cobrança cobertura "
                height="30px"
                value={createCoverageData?.type}
                onChange={(e) => setCreateCoverageData({ ...createCoverageData, type: e.target.value })}
              >
                <option value="">Selecionar</option>
                <option value="percentage">Porcentagem</option>
                <option value="value">Valor</option>
              </Select>

              <Input
                label={`${createCoverageData?.type === 'value' ? 'Valor' : 'Percentual do valor'} da cobertura`}
                height="30px"
                value={createCoverageData?.value}
                onChange={(e) => setCreateCoverageData({ ...createCoverageData, value: e.target.value })}
                numeric
              />

              <Input
                label="Limite máximo de indenização"
                height="30px"
                value={createCoverageData?.lmi}
                onChange={(e) => setCreateCoverageData({ ...createCoverageData, lmi: e.target.value })}
                price
                numeric
                disabled={createCoverageData.lmiFipe}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  name="checkbox"
                  type="checkbox"
                  value={createCoverageData?.lmiFipe}
                  onChange={(e) => setCreateCoverageData({ ...createCoverageData, lmiFipe: !createCoverageData.lmiFipe })}
                />
                <label style={{ fontSize: 12 }} htmlFor="checkbox">
                  Valor do veículo
                </label>
              </div>

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <Select
                label="Tipo franquia "
                height="30px"
                value={createCoverageData?.default_deductible_type}
                onChange={(e) => setCreateCoverageData({ ...createCoverageData, default_deductible_type: e.target.value })}
              >
                <option value="">Selecionar</option>
                <option value="percentage">Porcentagem</option>
                <option value="value">Valor</option>
              </Select>

              <Input
                label={`${createCoverageData?.default_deductible_type === 'value' ? 'Valor' : 'Percentual'} da franquia`}
                height="30px"
                value={createCoverageData?.default_deductible_value}
                onChange={(e) => setCreateCoverageData({ ...createCoverageData, default_deductible_value: e.target.value })}
                price={createCoverageData?.default_deductible_type === 'value'}
                percentage={createCoverageData?.default_deductible_type === 'percentage'}
                numeric
              />

              {createCoverageData?.default_deductible_type === 'percentage' && (
                <Input
                  label="Valor mínimo da franquia"
                  height="30px"
                  value={createCoverageData?.deductible_min}
                  onChange={(e) => setCreateCoverageData({ ...createCoverageData, deductible_min: e.target.value })}
                  price
                  numeric
                />
              )}

              {createCoverageData?.default_deductible_type === 'percentage' && (
                <Input
                  label="Valor máximo da franquia"
                  height="30px"
                  value={createCoverageData?.deductible_max}
                  onChange={(e) => setCreateCoverageData({ ...createCoverageData, deductible_max: e.target.value })}
                  price
                  numeric
                />
              )}

              <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                <input
                  name="checkbox2"
                  type="checkbox"
                  value={createCoverageData?.handleReducedDeductible}
                  onChange={(e) =>
                    setCreateCoverageData({
                      ...createCoverageData,
                      handleReducedDeductible: !createCoverageData.handleReducedDeductible,
                    })
                  }
                />
                <label style={{ fontSize: 12 }} htmlFor="checkbox2">
                  Adicionar franquia reduzida?
                </label>
              </div>

              {createCoverageData?.handleReducedDeductible && (
                <Select
                  label="Tipo franquia reduzida"
                  height="30px"
                  value={createCoverageData?.reduced_deductible_type}
                  onChange={(e) => setCreateCoverageData({ ...createCoverageData, reduced_deductible_type: e.target.value })}
                >
                  <option value="">Selecionar</option>
                  <option value="percentage">Porcentagem</option>
                  <option value="value">Valor</option>
                </Select>
              )}

              {createCoverageData?.handleReducedDeductible && (
                <Input
                  label={`${
                    createCoverageData?.reduced_deductible_type === 'value' ? 'Valor' : 'Percentual'
                  } da franquia reduzida`}
                  height="30px"
                  value={createCoverageData?.reduced_deductible_value}
                  onChange={(e) => setCreateCoverageData({ ...createCoverageData, reduced_deductible_value: e.target.value })}
                  price={createCoverageData?.reduced_deductible_type === 'value'}
                  percentage={createCoverageData?.reduced_deductible_type === 'percentage'}
                  numeric
                />
              )}

              <Button
                height="30px"
                style={{ margin: 0, marginTop: 20, marginBottom: 30 }}
                onClick={handleCreateProduct}
                disabled={
                  loading ||
                  !createCoverageData?.category ||
                  createCoverageData?.category === '' ||
                  !createCoverageData?.name ||
                  !createCoverageData?.ebao_code ||
                  createCoverageData?.type === '' ||
                  createCoverageData?.default_deductible_type === '' ||
                  createCoverageData?.reduced_deductible_type === ''
                }
              >
                Adicionar cobertura
              </Button>
            </div>
          </Dialog>
        </>

        {/* ---------------------------MODAL DE ADICIONAR AREA DE COBERTURA-------------------------------*/}
        <>
          <Dialog
            open={openCreateAreaCoverage}
            onClose={() => setOpenCreateAreaCoverage(false)}
            width="80%"
            title="Adicionar área de cobertura"
          >
            <div>
              <Input
                label="Descrição"
                height="30px"
                value={createAreaCoverageData?.description}
                onChange={(e) => setCreateAreaCoverageData({ ...createAreaCoverageData, description: e.target.value })}
              />

              <Input
                label="CEP inicial"
                mask="99.999-999"
                height="30px"
                value={createAreaCoverageData?.zipcode_from}
                onChange={(e) => setCreateAreaCoverageData({ ...createAreaCoverageData, zipcode_from: e.target.value })}
              />
              <Input
                label="CEP final"
                mask="99.999-999"
                height="30px"
                value={createAreaCoverageData?.zipcode_to}
                onChange={(e) => setCreateAreaCoverageData({ ...createAreaCoverageData, zipcode_to: e.target.value })}
              />

              <Button
                height="30px"
                style={{ margin: 0, marginTop: 20, marginBottom: 30 }}
                onClick={handleCreateAddressRules}
                disabled={
                  loading ||
                  !createAreaCoverageData?.description ||
                  !createAreaCoverageData?.zipcode_from ||
                  !createAreaCoverageData?.zipcode_to
                }
              >
                Adicionar cobertura
              </Button>
            </div>
          </Dialog>
        </>

        {/* -------------------------------MODAL DE REJEITAR VEICULOS-------------------------------------*/}
        <>
          <Dialog open={openRejectModel} onClose={() => setOpenRejectModel(false)} width="80%" title="Regras de exclusão">
            <div>
              <Input
                label="Nome da regra de exclusão"
                placeholder="Ex.: Veículos com valor maior que R$ 100 mil"
                height="30px"
                value={createRejectModelData?.description}
                onChange={(e) => setCreateRejectModelData({ ...createRejectModelData, description: e.target.value })}
              />
              <Select
                label="Parâmetro"
                height="30px"
                value={createRejectModelData?.parameter}
                onChange={(e) => setCreateRejectModelData({ ...createRejectModelData, parameter: e.target.value })}
              >
                <option value="">Selecione</option>
                <option value="vehicle_type">Tipo de veículo</option>
                <option value="brand">Marca</option>
                <option value="id_brand">ID da marca</option>
                <option value="model">Modelo</option>
                <option value="id_model">ID do modelo</option>
                <option value="year">Ano</option>
                <option value="fuel">Combustível</option>
                <option value="fipe_code">Código FIPE</option>
                <option value="value">Valor</option>
                <option value="vehicle_use">Uso do veíuclo</option>
              </Select>

              <Select
                label="Condição"
                height="30px"
                value={createRejectModelData?.condition}
                onChange={(e) => setCreateRejectModelData({ ...createRejectModelData, condition: e.target.value })}
              >
                <option value="">Selecione</option>
                <option value="equal">Igual a</option>
                <option value="bigger">Maior que</option>
                <option value="smaller">Menor que</option>
                <option value="bigger_equal">Maior ou igual a</option>
                <option value="smaller_equal">Menor ou igual a</option>
                <option value="contain">Contém</option>
                <option value="not_contain">Não contém</option>
              </Select>
              <Input
                label="Valor"
                height="30px"
                value={createRejectModelData?.value}
                onChange={(e) => setCreateRejectModelData({ ...createRejectModelData, value: e.target.value })}
              />

              <Button
                height="30px"
                style={{ margin: 0, marginTop: 20, marginBottom: 30 }}
                onClick={handleCreateVehicleRules}
                disabled={
                  loading ||
                  !createRejectModelData?.value ||
                  !createRejectModelData?.condition ||
                  createRejectModelData?.condition === '' ||
                  !createRejectModelData?.parameter ||
                  createRejectModelData?.parameter === '' ||
                  !createRejectModelData?.description
                }
              >
                Adicionar cobertura
              </Button>
            </div>
          </Dialog>
        </>
      </Container>
    </>
  );
}

export default Settings;
