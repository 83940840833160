import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import { Container, StatusText, MonthText, Value, ColumnItem } from './styles';
import { useHistory } from 'react-router-dom';
import { formatCurrency } from 'react-data-formatter';
import { formatPayMethod } from 'utils/payMethod';

function ExtractItem({ data }) {
  const history = useHistory();

  return (
    <>
      <Container
        onClick={() => data?.status.includes('awaiting_') && history.push(`/pagamento/${data?.id}`)}
        clickable={data?.clickable}
      >
        <div className="description">
          <ColumnItem width="15%">
            <small>Data da transação:</small>
            <MonthText>{moment(data?.created_at).format('DD/MM/YYYY HH:mm')}</MonthText>
          </ColumnItem>
          <ColumnItem width="10%">
            <small>Tipo:</small>
            {data?.policy?.policy_number ? 'Mensalidade' : 'Ativação'}
          </ColumnItem>
          <ColumnItem width="12%">
            <small>Status:</small>
            <StatusText>{data?.status === 'failed' ? '❌ Falha' : '✅ Sucesso'}</StatusText>
          </ColumnItem>
          <ColumnItem width="13%">
            <small>Valor:</small>
            <Value>{formatCurrency(data?.value)}</Value>
          </ColumnItem>
          <ColumnItem width="20%">
            <small>Forma de pagamento:</small>
            {formatPayMethod(data?.id_pay_method)}
          </ColumnItem>
          <ColumnItem width="30%">
            <small>{data?.policy?.policy_number ? 'Apólice:' : 'Protocolo:'}</small>
            {data?.policy?.policy_number || data?.proposal?.protocol}
          </ColumnItem>
        </div>
      </Container>
    </>
  );
}

export default ExtractItem;
