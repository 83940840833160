import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import PartnersData from 'utils/corporateSettings.json';
import { formatPolicyStatus } from 'utils/policyStatus';
import { formatCPF, formatPlate } from 'react-data-formatter';
import { formatCurrency } from 'react-data-formatter';

export const dataColumns = [
  {
    selector: (row) => (
      <Tooltip title={`${PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.name}`}>
        <img
          className="partnerIcon"
          src={PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.logos?.small}
          alt={PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.name}
        />
      </Tooltip>
    ),
    width: '5%',
    sortable: true,
  },
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '7%',
    sortable: true,
  },
  {
    name: 'Cliente',
    selector: (row) => row?.user?.name,
    width: '13%',
    sortable: true,
  },
  {
    name: 'CPF',
    selector: (row) => formatCPF(row?.user?.cpf),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Placa',
    selector: (row) => formatPlate(row?.vehicle?.plate) || 'Sem placa',
    width: '10%',
    sortable: true,
  },
  {
    name: 'Valor',
    selector: (row) => formatCurrency(row?.value_final || 0),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Protocolo',
    selector: (row) => row?.policy_number,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => formatPolicyStatus(row?.status)?.label,
    width: '10%',
    sortable: true,
  },

  {
    name: 'Enviada em',
    selector: (row) => moment.utc(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Parceiro',
    selector: (row) => PartnersData[PartnersData.findIndex((i) => i.id_partner === row?.partner?.id)]?.name,
    width: '10%',
    sortable: true,
  },
];
