/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import Tooltip from '@mui/material/Tooltip';
import api from 'api';
import { Header, SideMenu, Search, Button, Input, Dialog, Select } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';
import { TiFilter } from 'react-icons/ti';
import { toast } from 'react-toastify';
import { dataColumns } from './helper';
import { RiFileExcel2Fill } from 'react-icons/ri';
import PartnersData from 'utils/corporateSettings.json';
import ExportToExcel from 'components/ExportToExcel/index';

function Policies({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [policiesList, setPoliciesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();
  const [open, setOpen] = useState(false);
  const [selectStatus, setSelectStatus] = useState('all');
  const [startData, setStartdata] = useState();
  const [endData, setEndData] = useState();
  const [touchFilter, setTouchFilter] = useState();
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportData, setExportData] = useState();
  const [openExport, setOpenExport] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState('all');

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/splitrisk-policies`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      params: {
        search,
        startdate: startData === '' ? undefined : startData,
        enddate: endData === '' ? undefined : endData,
        status: selectStatus === 'all' ? undefined : selectStatus,
        page: currentPage,
        take: 20,
        partner: selectedPartner,
      },
      json: true,
    });

    setPoliciesList(resp?.data?.policies);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
    setTouchFilter(false);
  };

  const handleLoadMore = async () => {
    if (!touchFilter) {
      const page = currentPage + 1;
      if (hasNextPage && !loadingMore) {
        setLoadingMore(true);

        const resp = await api({
          method: 'GET',
          url: `/splitrisk-policies`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitrisk-authtoken'),
          },
          params: {
            search,
            startdate: startData === '' ? undefined : startData,
            enddate: endData === '' ? undefined : endData,
            status: selectStatus === 'all' ? undefined : selectStatus,
            page,
            take: 10,
            partner: selectedPartner,
          },
          json: true,
        });

        const data = policiesList;
        const ndata = data.concat(resp?.data?.policies);

        setCurrentPage(page);
        setPoliciesList(ndata);
        setHasNextPage(resp?.data?.hasNextPage);
        setLoadingMore(false);
      }
    }
  };

  const handleClear = () => {
    setSelectStatus('all');
    setStartdata('');
    setEndData('');
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleOpenFilter = () => {
    setOpen(true);
  };

  const renderFilterDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} width="50%" title="Filtrar apólice">
      <Select
        height="30px"
        label="Filtrar por canal"
        value={selectedPartner}
        onChange={(e) => setSelectedPartner(e.target.value)}
      >
        <option value={'all'}>Todos os canais</option>
        {PartnersData?.sort((a, b) => a.name.localeCompare(b.name))?.map((partner) => (
          <option key={partner?.id_partner} value={partner?.id_partner}>
            {partner?.name}
          </option>
        ))}
      </Select>

      <Select height="30px" label="Filtrar por status" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
        <option value={'all'}>Todos os Status</option>
        <option value={'active'}>Ativo</option>
        <option value={'canceled'}>Cancelado</option>
        <option value={'awaiting_cancellation'}>Aguardando cancelamento</option>
      </Select>

      <Input
        value={startData}
        onChange={(e) => setStartdata(e.target.value)}
        widht="30%"
        label="Data inicial"
        mask="99/99/9999"
        height="30px"
      />
      <Input
        value={endData}
        onChange={(e) => setEndData(e.target.value)}
        widht="30%"
        label="Data final"
        mask="99/99/9999"
        height="30px"
      />

      <Input
        value={endData}
        onChange={(e) => setEndData(e.target.value)}
        widht="30%"
        label="Cobrança automática ativa"
        mask="99/99/9999"
        height="30px"
      />

      <ModalButton>
        <Button
          height="35px"
          outlined
          disabled={selectStatus === 'all' && startData === '' && endData === ''}
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          height="35px"
          onClick={() => {
            setTouchFilter(true);
            loadData();
            setOpen(false);
          }}
        >
          Aplicar alterações
        </Button>
      </ModalButton>
    </Dialog>
  );

  const handleExport = async () => {
    setLoadingExport(true);

    try {
      const resp = await api({
        method: 'GET',
        url: `/report/policies-report`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
          feature: 'policies-report',
        },
        json: true,
      });
      setExportData(resp.data);
      setOpenExport(true);
      setLoadingExport(false);
    } catch (error) {
      toast.error("Não foi possível exportar apolices", {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingExport(false);
    }
  };

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loadingExport && setOpenExport(false);

        setExportData();
      }}
      width="50%"
      title="Exportar propostas"
    >
      {exportData?.hasData ? (
        <>
          <p>Seu arquivo está pronto!</p>
          <ExportToExcel exportData={exportData?.data} fileName={`split_risk_apolices_${new Date().toLocaleDateString()}`} />
        </>
      ) : (
        <p>{exportData?.message}</p>
      )}
    </Dialog>
  );

  return (
    <>
      <Header />
      <Container>
        {renderFilterDialog()}
        {renderExportDialog()}
        <SideMenu />

        <ContentArea>
          <Div>
            <Title>APÓLICES</Title>
            <div className="containerTitle">
              <Tooltip title="Relatório de apólices">
                <Button height="30px" width="200px" onClick={handleExport} disabled={loadingExport}>
                  {loadingExport ? (
                    <ReactLoading color="#fff" height={25} width={25} type="spin" />
                  ) : (
                    <RiFileExcel2Fill size={18} />
                  )}
                </Button>
              </Tooltip>
              <Button title="Filtrar" height="30px" onClick={handleOpenFilter}>
                <TiFilter size={18} />
              </Button>
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData();
                }}
                onConfirm={() => loadData()}
              />
            </div>
          </Div>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <DataTable
                onRowClicked={(row) => history.push(`/backoffice/detalhes-da-apolice/${row?.id}`)}
                persistTableHead
                responsive
                columns={dataColumns}
                data={policiesList}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhuma apólice encontrada</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {policiesList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default Policies;
