import styled from 'styled-components';
import { white, primary, darkGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 90%;
  height: 100% !important;
  max-height: 100% !important;
  box-sizing: border-box;
  padding: 30px;
  padding-left: 90px;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const Div = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .containerTitle {
    display: flex;
    width: 38%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1000px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const MenuArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
  margin-top: 15px; ;
`;

export const MenuItem = styled.div`
  display: flex;
  font-size: 14px;
  margin-right: 25px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  color: ${(props) => props.selected && primary};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};

  &:hover {
    opacity: 0.5;
  }
`;

export const WidgetArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px; ;
`;

export const Widget = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 20px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  height: 90px;
  min-width: 150px;

  p {
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;
  }

  h2 {
    margin: 10px 0px 0px 0px;
    color: ${primary};
    font-size: 26px;
  }
`;

export const TableArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  padding-bottom: 50px;
`;

export const Toolbar = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background-color: #fafafa;
  padding-bottom: 5px;
  margin-top: 15px; ;
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65vh !important;
  max-height: 65vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 10px;
  }

  .partnerIcon {
    height: 20px;
    width: 20px;
    border-radius: 2px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;
