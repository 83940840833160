import styled from 'styled-components';
import { white, darkGray, middleGray, lightGray, middleGraySecondary, black } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  box-sizing: border-box;
  padding: 10px 25px;
  margin-inline-end: 15px;

  @media print{
    position: absolute;
    height: 95%;
  }

  header {
    padding: 5px 30px;
    box-sizing: border-box;
  }

  section {
    /* border: 1px solid ${lightGray}; */
    border-radius: 30px;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    margin: 10px;
/*     
    @media print {
        page-break-after: avoid;
    
} */
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  h1 {
    color: ${middleGraySecondary};
    font-size: 20px;
    margin: 0px;
    margin-bottom: 20px;
  }

  h2 {
    color: ${darkGray};
    font-size: 18px;
    margin: 0px;
    margin-bottom: 30px;
  }

  p {
    color: ${middleGray};
    margin: 10px 0px;
    font-size: 14px;
    font-weight: normal;   
  }

  strong {
    color: ${middleGraySecondary};
    margin: 10px 0px;
    font-size: 14px;
    font-weight: bold;
  }

  span {
    color: ${black};
  
} 

.p-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.on-top {
  margin-right: 30%;
}

.header{
      display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 100%; */
}


  table {
    margin-bottom: 20px;
  }

  td {
    font-size: 14px;
  }

  thead {
    color: ${middleGraySecondary} !important;
    font-weight: bold;
  }

  hr {
    border: none;
    border-top: 1px solid ${middleGray};
    height: 3px;
  }

  
 
  .logo {
    margin-bottom: 30px;
    width: 100px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: justify;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  
  @media print {
    display: none;
  }
`;
