import React, { useState } from 'react';
import api from 'api';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { Container, LeftBox, RightBox, Form, ForgotText } from './styles';
import { Button, Input } from 'components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { white } from 'styles/colorProvider';
import { inputFieldsLogin } from 'utils/inputFieldsLogin';

function Login() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({
    emailOrCpf: '',
    password: '',
  });

  const handleChangeLogin = (e, field) => {
    setLogin({ ...login, [field]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const emailOrCpf = login.emailOrCpf;
      const password = login.password;
      const response = await api.post('/login', { emailOrCpf, password });
      const { token } = response?.data;
      Cookies.set('splitrisk-authtoken', token);
      setLoading(false);
      history.push('/minhas-apolices');
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  return (
    <Container>
      <LeftBox>
        <div />
      </LeftBox>
      <RightBox>
        <Form>
          <div className="areaLogo">
            <div className="logo" />
          </div>

          <h2>Login</h2>
          {inputFieldsLogin?.map((input) => (
            <Input
              label={input.label}
              placeholder={input.placeholder}
              value={login[input.field]}
              onChange={(e) => handleChangeLogin(e, input.field)}
              type={input.type}
              password={input.field === 'password' && true}
            />
          ))}

          <div className="buttonArea">
            <Button onClick={handleLogin} disabled={loading || !login.emailOrCpf || !login.password}>
              {loading ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Entrar'}
            </Button>
          </div>
        </Form>
        <ForgotText onClick={() => history.push('/recuperar-senha')}>Esqueci minha senha</ForgotText>
      </RightBox>
    </Container>
  );
}

export default Login;
