/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@material-ui/core/IconButton';
import { MdChevronLeft } from 'react-icons/md';
import moment from 'moment';
import api from 'api';

import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { Header, Button, Dialog } from 'components';
import { Container, ContentArea, Title, LoadingArea, Toolbar, ItemPolicy, Text, TimelineDataBody, ModalButton } from './styles';
import { loadingColor } from 'styles/colorProvider';
import { formatTicketStatus, formatTicketPriority } from 'utils/ticketStatus';
import { toast } from 'react-toastify';

function SupportDetails({ location, history }) {
  const ticketId = parseInt(location.pathname.split('/backoffice/suporte/')[1]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);

  const [openCreate, setOpenCreate] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);

  const [createData, setCreateData] = useState([]);
  const [finishData, setFinishData] = useState([]);

  const loadData = async () => {
    setLoading(true);
    const response = await api({
      method: 'GET',
      url: `/ticket/${ticketId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });
    setData(response.data);
    setLoading(false);
  };

  const handleCreate = async () => {
    setLoadingCreate(true);
    await api({
      method: 'POST',
      url: `/create-ticket-history/${ticketId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        description: createData?.description,
      },
      json: true,
    });
    toast.success('Atendimento atualizado com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
    setLoadingCreate(false);
    setCreateData(false);
    setOpenCreate();
    loadData();
  };

  const handleFinish = async () => {
    setLoadingFinish(true);
    await api({
      method: 'POST',
      url: `/finish-ticket/${ticketId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        description: finishData?.description,
      },
      json: true,
    });
    toast.success('Atendimento encerrado com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
    setLoadingFinish(false);
    setFinishData(false);
    setOpenFinish();
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  const TimelineData = ({ data }) => {
    return (
      <TimelineDataBody>
        {formatTicketStatus(data?.status)}
        <p className="author">
          {data?.initial ? 'Aberto' : data?.status === 'finished' ? 'Encerrado' : 'Atualizado'} por: {data?.author?.name}
        </p>
        <p className="description">{data?.description}</p>
      </TimelineDataBody>
    );
  };

  const renderAddDialog = () => (
    <Dialog open={openCreate} onClose={() => setOpenCreate(false)} width="50%" title="Atualizar chamado">
      <TextareaAutosize
        minRows={1}
        value={createData?.description}
        onChange={(e) => setCreateData({ ...createData, description: e.target.value })}
        placeholder="Insira a descrição da solicitação..."
        style={{
          padding: 10,
          fontFamily: 'Nunito',
          fontSize: 14,
          width: '280px',
          height: '100px',
          borderRadius: 10,
          borderColor: '#d1d2d4',
          marginTop: 20,
          outline: 'none',
        }}
      />

      <ModalButton>
        <Button
          style={{ marginTop: 30 }}
          height="35px"
          onClick={handleCreate}
          loading={loadingCreate}
          disabled={loadingCreate || !createData?.description}
        >
          {loadingCreate ? <ReactLoading color={'#fff'} height={24} width={24} type="spin" /> : 'Registrar atualização'}
        </Button>
      </ModalButton>
    </Dialog>
  );

  const renderFinishDialog = () => (
    <Dialog open={openFinish} onClose={() => setOpenFinish(false)} width="50%" title="Encerrar chamado">
      <TextareaAutosize
        minRows={1}
        value={finishData?.description}
        onChange={(e) => setFinishData({ ...finishData, description: e.target.value })}
        placeholder="Insira a solução da solicitação..."
        style={{
          padding: 10,
          fontFamily: 'Nunito',
          fontSize: 14,
          width: '95%',
          height: '100px',
          borderRadius: 10,
          borderColor: '#d1d2d4',
          marginTop: 20,
          outline: 'none',
        }}
      />

      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
        <input
          name="checkbox"
          type="checkbox"
          value={finishData?.confirm_finish}
          onChange={() => setFinishData({ ...finishData, confirm_finish: !finishData?.confirm_finish })}
        />
        <label style={{ fontSize: 12 }} htmlFor="checkbox">
          Tenho certeza de que desejo encerrar este chamado. Esta ação não poderá ser desfeita.
        </label>
      </div>

      <ModalButton>
        <Button
          style={{ marginTop: 30 }}
          height="35px"
          onClick={handleFinish}
          loading={loadingFinish}
          disabled={loadingFinish || !finishData?.description || !finishData?.confirm_finish}
        >
          {loadingFinish ? <ReactLoading color={'#fff'} height={24} width={24} type="spin" /> : 'Encerrar'}
        </Button>
      </ModalButton>
    </Dialog>
  );

  return (
    <>
      {renderAddDialog()}
      {renderFinishDialog()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea style={{ paddingTop: 40 }}>
            <Title>
              <IconButton style={{ marginRight: 10 }} size={'small'} onClick={() => history.goBack()}>
                <MdChevronLeft />
              </IconButton>
              DETALHES DO CHAMADO
            </Title>

            <Toolbar>
              <Button disabled={data?.status === 'finished'} height="35px" onClick={() => setOpenCreate(true)}>
                Atualizar chamado
              </Button>
              <Button disabled={data?.status === 'finished'} onClick={() => setOpenFinish(true)} height="35px">
                Encerrar chamado
              </Button>
            </Toolbar>

            <h3 style={{ marginTop: 20 }}>Dados do chamado</h3>
            <ItemPolicy>
              {formatTicketStatus(data?.status)}
              <Text>
                Protocolo: <span>{data?.protocol}</span>
              </Text>
              <Text>
                Prioridade: <span>{formatTicketPriority(data?.priority)?.label}</span>
              </Text>
              <Text>
                Solicitado por: <span>{data?.client_name}</span>
              </Text>
              <Text>
                Data abertura: <span>{moment(data?.created_at).format('DD/MM/YYYY HH:mm')}</span>
              </Text>
              <Text>
                Última atualização: <span>{moment(data?.updated_at).format('DD/MM/YYYY HH:mm')}</span>
              </Text>
            </ItemPolicy>

            <h3 style={{ marginTop: 40 }}>Histórico do chamado</h3>

            <ItemPolicy>
              <Timeline>
                {data?.history?.map((item, index) => (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent style={{ fontSize: 12 }}>
                      {moment(item?.created_at)?.format('DD/MM/YYYY')} <br />
                      <p style={{ margin: 0, fontSize: 12, color: '#868686' }}>{moment(item?.created_at)?.format('HH:mm')}</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      {index !== data.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <TimelineData data={item} />
                    </TimelineContent>
                  </TimelineItem>
                ))}

                <TimelineItem>
                  <TimelineOppositeContent style={{ fontSize: 12 }}>
                    {moment(data?.created_at)?.format('DD/MM/YYYY')} <br />
                    <p style={{ margin: 0, fontSize: 12, color: '#868686' }}>{moment(data?.created_at)?.format('HH:mm')}</p>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <TimelineData data={{ ...data, status: 'pending', initial: true }} />
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </ItemPolicy>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default SupportDetails;
