import React from 'react';
import 'moment/locale/pt-br';
import { BsShield } from 'react-icons/bs';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container, Div, Title, Icon } from './styles';
import { formatCurrency } from 'react-data-formatter';

function CoverageItem({ item, deleteItem }) {
  return (
    <>
      <Container>
        <div className="description">
          <div className="iconCar">
            <BsShield size={15} />
          </div>

          <Div>
            <Title>{item?.name}</Title>
          </Div>

          <Div>
            <Title>{item?.category === 'basic' ? 'Cobertura básica' : 'Cobertura adicional'}</Title>
          </Div>

          <Div>
            <Title>
              {item?.type === 'percentage'
                ? (item?.value * 100).toFixed(1) + '% do valor do veículo'
                : formatCurrency(item?.value)}
            </Title>
          </Div>

          <Icon>
            <DeleteIcon
              style={{ cursor: 'pointer' }}
              onMouseOver={({ target }) => (target.style.color = '#e74c3c')}
              onMouseOut={({ target }) => (target.style.color = '#121212')}
              onClick={deleteItem}
            />
          </Icon>
        </div>
      </Container>
    </>
  );
}

export default CoverageItem;
