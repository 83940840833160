/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from 'api';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';

import { Header, PolicyMenu, ChargeItem } from 'components';
import { Container, ContentArea, Title, ListPaymentStatus, EmptyArea, LoadingArea } from './styles';
import { loadingColor } from 'styles/colorProvider';

import emptyAnimation from 'assets/empty.json';

function FinancialStatement({ location }) {
  const [loading, setLoading] = useState(false);
  const [chargeData, setChargeData] = useState([]);

  const policyId = parseInt(location.pathname.split('/minhas-faturas/')[1]);

  const loadData = async () => {
    setLoading(true);
    const responsePolicies = await api({
      method: 'GET',
      url: `/policy-charges/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });
    setChargeData(responsePolicies.data);
    setLoading(false);
  };

  useEffect(() => loadData(), []);

  return (
    <>
      <Header />
      <Container>
        <ContentArea>
          <PolicyMenu policyId={policyId} />
          <Title>MINHAS FATURAS</Title>
          <ListPaymentStatus>
            {loading && (
              <LoadingArea>
                <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
              </LoadingArea>
            )}

            {!loading &&
              chargeData?.map((charge) => (
                <ChargeItem
                  id={charge?.id}
                  status={!charge?.status || charge?.status.includes('awaiting_') ? 'awaiting_payment' : charge?.status}
                  month={charge.created_at}
                  value={charge?.value}
                  message={charge.paid_at ? charge.paid_at : charge.due_at}
                  clickable={charge?.status === null || charge?.status.includes('awaiting_')}
                  type={charge?.type}
                  isHinova={charge?.TB_BILLS?.some((item) => item?.id_bill_hinova) || charge?.gateway === 'hinova'}
                  billet={false}
                />
              ))}

            {!loading && !chargeData.length && (
              <EmptyArea>
                <Lottie
                  autoplay
                  style={{ marginTop: 50, opacity: 0.6 }}
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: emptyAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={140}
                  width={240}
                />
                <p>Nenhuma fatura encontrada.</p>
              </EmptyArea>
            )}
          </ListPaymentStatus>
        </ContentArea>
      </Container>
    </>
  );
}

export default FinancialStatement;
