/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import { Header, SideMenu, Search, Button } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ButtonTable } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';
import { BiPencil, BiTrash } from 'react-icons/bi';
import api from 'api';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  RenderAddBlacklistQuotation,
  RenderEditBlacklistQuotation,
  renderRemoveBlacklistQuotation,
  RenderAddBlacklistBatchQuotation,
} from './modals';
import { toast } from 'react-toastify';
import { formatValue } from 'utils/masks';

function BackOfficeProductsBlacklistQuotation({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataTable, setLoadingDataTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();
  const [blacklist, setBlackList] = useState([]);
  const [editId, setEditId] = useState(null);
  const [removeId, setRemoveId] = useState(null);
  const [atualValue, setAtualValue] = useState(null);
  const [atualType, setAtualType] = useState(null);

  //modals
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalAddBatch, setOpenModalAddBatch] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);

  const formatType = {
    document: 'Documento',
    plate: 'Placa',
    chassi: 'Chassi',
    email: 'Email',
    phone: 'Telefone',
  };

  const loadData = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const resp = await api({
        method: 'GET',
        url: `/blacklist`,
        params: {
          search,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        json: true,
      });
      setBlackList(resp?.data?.response);
      setTotalCount(resp?.data?.totalCount);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error('Não foi possível carregar as informações', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    loadData({});
  }, []);

  const dataColumns = [
    {
      name: 'ID',
      selector: (row) => row?.id,
      width: '10%',
      sortable: true,
    },
    {
      name: 'Tipo',
      selector: (row) => formatType[row?.type],
      width: '15%',
      sortable: true,
    },
    {
      name: 'Valor',
      selector: (row) => formatValue(row?.value, row?.type),
      width: '15%',
      sortable: true,
    },
    {
      name: 'Criado em',
      selector: (row) => moment(row.created_at).format('DD/MM/YYYY'),
      width: '20%',
      sortable: true,
    },
    {
      name: 'Última atualização',
      selector: (row) => moment(row.updated_at).format('DD/MM/YYYY'),
      width: '20%',
      sortable: true,
    },
    {
      name: 'Editar',
      selector: (row) => (
        <ButtonTable
          onClick={() => {
            setEditId(row.id);
            setAtualValue(row.value);
            setAtualType(row.type);
            setOpenModalEdit(true);
          }}
        >
          <BiPencil size={16} />
        </ButtonTable>
      ),
      width: '7%',
      sortable: false,
    },
    {
      name: 'Excluir',
      selector: (row) => (
        <ButtonTable
          onClick={() => {
            setRemoveId(row.id);
            setOpenModalRemove(true);
          }}
        >
          <BiTrash size={16} />
        </ButtonTable>
      ),
      width: '7%',
      sortable: false,
    },
  ];

  return (
    <>
      <Header />
      <Container>
        {RenderAddBlacklistBatchQuotation({
          loadData,
          openModalAddBatch,
          setOpenModalAddBatch,
          setLoading,
          loading,
          loadingData,
          setLoadingData,
        })}
        {RenderAddBlacklistQuotation({
          loadData,
          openModalAdd,
          setOpenModalAdd,
          setLoading,
          loading,
          loadingData,
          setLoadingData,
        })}

        {RenderEditBlacklistQuotation({
          id: editId,
          type: atualType,
          value: atualValue,
          openModalEdit,
          setOpenModalEdit,
          setLoading,
          loading,
          loadData,
          loadingData,
          setLoadingData,
        })}

        {renderRemoveBlacklistQuotation({
          id: removeId,
          loadData,
          openModalRemove,
          setOpenModalRemove,
          setLoading,
          loading,
          loadingData,
          setLoadingData,
        })}

        <SideMenu />
        <ContentArea>
          <Div>
            <Title>BLACKLIST</Title>
            <div className="containerTitle">
              <Button
                title="Adicionar"
                height="30px"
                onClick={() => setOpenModalAdd(true)}
                disabled={loading || loadingDataTable}
              >
                Adicionar
              </Button>

              <Button
                title="Adicionar em lote"
                height="30px"
                onClick={() => setOpenModalAddBatch(true)}
                disabled={loading || loadingDataTable}
              >
                Adicionar em lote
              </Button>

              <Search
                value={search}
                placeholder={'Buscar por valor'}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData({
                    setCurrentPage,
                    setHasNextPage,
                    setLoading,
                    setTotalCount,
                    search,
                    loadingDataTable,
                    setLoadingDataTable,
                  });
                }}
                onConfirm={() =>
                  loadData({
                    setCurrentPage,
                    setHasNextPage,
                    setLoading,
                    setTotalCount,
                    search,
                    loadingDataTable,
                    setLoadingDataTable,
                  })
                }
              />
            </div>
          </Div>
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={() => {}}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={0}
            >
              <DataTable
                persistTableHead
                responsive
                columns={dataColumns}
                data={blacklist}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading || loadingDataTable}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhuma informação encontrada</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {blacklist?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default BackOfficeProductsBlacklistQuotation;
