import React from 'react';
import Routes from './routes';
import store from './stores';

import { GlobalStyle } from 'styles/globalStyle';
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CookiesPermission } from 'components';
import { primary } from 'styles/colorProvider';
import { StoreProvider } from 'easy-peasy';
import 'react-toastify/dist/ReactToastify.css';
import './styles/fontProvider.css';

const theme = createTheme({
  palette: {
    primary: { main: primary },
  },
});

const App = () => (
  <div className="App">
    <StoreProvider store={store}>
      <CookiesPermission />
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <GlobalStyle />
        <Routes />
      </ThemeProvider>
    </StoreProvider>
  </div>
);

export default App;
