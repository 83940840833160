/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import CsvDownload from 'react-json-to-csv';
import api from 'api';
import { toast } from 'react-toastify';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { Header, SideMenu, Select, Search, Button, Dialog, Input } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton, DialogDiv } from './styles';
import { loadingColor, primary, white, secondary } from 'styles/colorProvider';
import { TiFilter } from 'react-icons/ti';
import { dataColumns, formatOperation, formatProvider, providerStatus } from './helper';
import PartnersData from 'utils/corporateSettings.json';

import moment from 'moment';
import { formatPlate } from 'react-data-formatter';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import ExportToExcel from 'components/ExportToExcel/index';

function AssistanceList({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [assistanceList, setAssistanceList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [open, setOpen] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openInfo, setOpenInfo] = useState('');
  const [search, setSearch] = useState();
  const [selectStatus, setSelectStatus] = useState('all');
  const [exportStatus, setExportStatus] = useState();
  const [exportPartner, setExportPartner] = useState();
  const [startData, setStartdata] = useState();
  const [endData, setEndData] = useState();
  const [exportData, setExportData] = useState();
  const [touchFilter, setTouchFilter] = useState();
  const [selectedPartner, setSelectedPartner] = useState('all');
  const [selectedProvider, setSelectedProvider] = useState('all');

  const loadData = async () => {
    setLoading(true);
    setCurrentPage(1);
    try {
      const resp = await api({
        method: 'GET',
        url: `/list-support-policies`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        params: {
          search,
          startdate: startData === '' ? undefined : startData,
          enddate: endData === '' ? undefined : endData,
          status: selectStatus === 'all' ? undefined : selectStatus,
          page: 1,
          take: 30,
          partner: selectedPartner === 'all' ? undefined : selectedPartner,
          provider: selectedProvider === 'all' ? undefined : selectedProvider,
        },
        json: true,
      });
      setAssistanceList(resp?.data?.histories);
      setTotalCount(resp?.data?.totalCount);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoading(false);
      setTouchFilter(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMore = async () => {
    if (!touchFilter) {
      const page = currentPage + 1;
      if (hasNextPage && !loadingMore) {
        setLoadingMore(true);
        const resp = await api({
          method: 'GET',
          url: `/list-support-policies`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitrisk-authtoken'),
          },
          params: {
            search,
            startdate: startData === '' ? undefined : startData,
            enddate: endData === '' ? undefined : endData,
            status: selectStatus === 'all' ? undefined : selectStatus,
            page,
            take: 10,
            partner: selectedPartner === 'all' ? undefined : selectedPartner,
            provider: selectedProvider === 'all' ? undefined : selectedProvider,
          },
          json: true,
        });
        const data = assistanceList;
        const ndata = data.concat(resp?.data?.histories);
        setCurrentPage(page);
        setAssistanceList(ndata);
        setHasNextPage(resp?.data?.hasNextPage);
        setLoadingMore(false);
      }
    }
  };

  const handleClear = () => {
    setSelectStatus('all');
    setSearch('');
    setSelectedPartner('all');
    setSelectedProvider('all');
    setStartdata('');
    setEndData('');
  };

  const handleExport = async () => {
    setLoadingExport(true);

    try {
      const resp = await api({
        method: 'GET',
        url: `/export-support-policies`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        params: {
          status: !exportStatus || exportStatus === '' || exportStatus === 'all' ? 'undefined' : exportStatus,
          partner: !exportPartner || exportPartner === '' || exportPartner === 'all' ? 'undefined' : exportPartner,
        },
        json: true,
      });
      setExportData(resp.data);
      setLoadingExport(false);
    } catch (error) {
      toast.error("Não foi possível exportar assistências", {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setOpenExport(false);
      setLoadingExport(false);
    }
  };

  const handleResend = async (id) => {
    try {
      setLoadingResend(true);

      const resp = await api({
        method: 'PUT',
        url: `/resend-support/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        json: true,
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } finally {
      setLoadingResend(false);
    }
  };

  const loadProviders = async () => {
    try {
      const resp = await api({
        method: 'GET',
        url: `/list-providers`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        json: true,
      });
      setProviderList(resp.data);
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingExport(false);
    }
  };

  useEffect(() => {
    loadData();
    loadProviders();
  }, []);

  const handleOpenFilter = () => {
    setOpen(true);
  };

  const renderFilterDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} width="50%" title="Filtrar assistência">
      <Select
        height="30px"
        label="Filtrar por parceiro"
        value={selectedPartner}
        onChange={(e) => setSelectedPartner(e.target.value)}
      >
        <option value={'all'}>Todos os parceiros</option>
        {PartnersData?.sort((a, b) => a.name.localeCompare(b.name))?.map((partner) => (
          <option key={partner?.id_partner} value={partner?.id_partner}>
            {partner?.name}
          </option>
        ))}
      </Select>

      <Select
        height="30px"
        label="Filtrar por fornecedor"
        value={selectedProvider}
        onChange={(e) => setSelectedProvider(e.target.value)}
      >
        <option value={'all'}>Todos os fornecedores</option>
        {providerList?.map((provider) => (
          <option key={provider?.id} value={provider?.id}>
            {provider?.name}
          </option>
        ))}
      </Select>

      <Select height="30px" label="Filtrar por status" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
        <option value={'all'}>Todos os Status</option>
        <option value={'add'}>Adicionado</option>
        <option value={'update'}>Editado</option>
        <option value={'remove'}>Removido</option>
      </Select>

      <Input
        value={startData}
        onChange={(e) => setStartdata(e.target.value)}
        widht="30%"
        label="Data inicial"
        mask="99/99/9999"
        height="30px"
      />
      <Input
        value={endData}
        onChange={(e) => setEndData(e.target.value)}
        widht="30%"
        label="Data final"
        mask="99/99/9999"
        height="30px"
      />
      <ModalButton>
        <Button
          height="35px"
          outlined
          disabled={
            selectStatus === 'all' &&
            startData === '' &&
            endData === '' &&
            selectedPartner === 'all' &&
            selectedProvider === 'all'
          }
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          height="35px"
          onClick={() => {
            setTouchFilter(true);
            loadData();
            setOpen(false);
          }}
        >
          Aplicar alterações
        </Button>
      </ModalButton>
    </Dialog>
  );

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loadingExport && setOpenExport(false);
        setExportStatus();
        setExportPartner();
        setExportData();
      }}
      width="50%"
      title="Exportar assistências"
    >
      {!exportData && (
        <>
          <Button height="35px" style={{ margin: 0, marginTop: 30 }} onClick={handleExport} disabled={loadingExport}>
            {loadingExport ? <ReactLoading color={white} height={25} width={25} type="spin" /> : 'Exportar'}
          </Button>
        </>
      )}

      {exportData && (
        <>
          <p>Seu arquivo está pronto!</p>

          <ExportToExcel exportData={exportData} fileName={`split_risk_assistencias_${new Date().toLocaleDateString()}`} />
        </>
      )}
    </Dialog>
  );
  const renderInfoDialog = () => (
    <Dialog
      open={openInfo}
      onClose={() => {
        setOpenInfo('');
      }}
      width="50%"
      title="Informação erro"
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <DialogDiv>
        <p>
          <b>Mensagem:</b> {openInfo}
        </p>
      </DialogDiv>
    </Dialog>
  );

  const dataColumns = [
    {
      name: 'Nome Cliente',
      selector: (row) => row?.TB_POLICIES?.TB_USERS?.name,
      width: '11%',
      sortable: true,
    },
    {
      name: 'Placa',
      selector: (row) => formatPlate(row?.TB_POLICIES?.TB_VEHICLE?.plate) || 'Sem placa',
      width: '11%',
      sortable: true,
    },
    {
      name: 'Nome Parceiro',
      selector: (row) => row?.TB_PARTNERS?.trade_name,
      width: '11%',
      sortable: true,
    },
    {
      name: 'Nome Fornecedor',
      selector: (row) => formatProvider(row?.TB_PROVIDER_SUPPORT?.name),
      width: '11%',
      sortable: true,
    },
    {
      name: 'Operação',
      selector: (row) => formatOperation(row?.operation),
      width: '11%',
      sortable: true,
    },
    {
      name: 'Referência Externa',
      selector: (row) => row?.external_id,
      width: '11%',
      sortable: true,
    },
    {
      name: 'Data de Envio',
      selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
      width: '15%',
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) =>
        row?.status === providerStatus.success ? (
          <AiFillCheckCircle
            size={16}
            style={{
              color: '#62ec83',
            }}
          />
        ) : (
          <AiFillCloseCircle
            size={16}
            style={{
              color: 'red',
            }}
          />
        ),
      width: '10%',
      sortable: true,
    },
    {
      name: 'Operações',
      selector: (row) => (
        <>
          {row?.error_msg && (
            <Button
              height="35px"
              width="100px"
              marginVertical={0}
              style={{ background: '#62ec83', border: 'none' }}
              onClick={() => {
                setOpenInfo(row?.error_msg);
              }}
            >
              Informação
            </Button>
          )}
          <Button
            height="35px"
            width="80px"
            style={{ background: '#62ec83', border: 'none' }}
            onClick={() => handleResend(row?.id)}
            disabled={loadingResend}
          >
            {loadingResend ? <ReactLoading color={white} height={25} width={25} type="spin" /> : 'Reenvio'}
          </Button>
        </>
      ),
      width: '25%',
      sortable: true,
    },
  ];

  return (
    <>
      <Header />
      <Container>
        {renderFilterDialog()}
        {renderExportDialog()}
        {renderInfoDialog()}
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>ASSISTÊNCIAS</Title>
            <div className="containerTitle">
              <Button title="Exportar" height="30px" style={{ marginRight: -5 }} onClick={() => setOpenExport(true)}>
                <RiFileExcel2Fill size={18} />
              </Button>
              <Button title="Filtrar" height="30px" onClick={handleOpenFilter}>
                <TiFilter size={18} />
              </Button>

              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData();
                }}
                onConfirm={() => loadData()}
              />
            </div>
          </Div>
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <DataTable
                persistTableHead
                responsive
                columns={dataColumns}
                data={assistanceList}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhuma assistência encontrada</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {assistanceList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default AssistanceList;
