import React from 'react';
import 'moment/locale/pt-br';
import { BiMapPin } from 'react-icons/bi';
import { Container, Div, Title, Icon } from './styles';
import { formatZipCode } from 'react-data-formatter';
import DeleteIcon from '@mui/icons-material/Delete';

function CoverageAreaItem({ item, deleteItem }) {
  return (
    <>
      <Container>
        <div className="description">
          <div className="iconCar">
            <BiMapPin size={15} />
          </div>

          <Div>
            <Title>{item?.description}</Title>
          </Div>

          <Div>
            <Title>
              De {formatZipCode(item?.zipcode_from)} até {formatZipCode(item?.zipcode_to)}
            </Title>
          </Div>

          <Div>
            <Title>Aceitar</Title>
          </Div>

          <Icon>
            <DeleteIcon
              style={{ cursor: 'pointer' }}
              onMouseOver={({ target }) => (target.style.color = '#e74c3c')}
              onMouseOut={({ target }) => (target.style.color = '#121212')}
              onClick={deleteItem}
            />
          </Icon>
        </div>
      </Container>
    </>
  );
}

export default CoverageAreaItem;
