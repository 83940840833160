import styled from 'styled-components';
import logoWhite from '../../assets/logo-white.png';
import { primary, white } from 'styles/colorProvider';

export const Container = styled.header`
  background-color: ${primary};
  width: 100vw;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.div`
  width: 170px;
  height: 30px;
  background-image: url(${logoWhite});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const ContainerMenu = styled.div`
  display: flex;
  align-items: center;
  width: 11 0px;
  justify-content: space-between;
  cursor: pointer;

  .name {
    color: ${white};
    font-size: 12px;
    margin-right: 10px;
  }
`;

export const Foto = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: cover;
`;
