import { success, error, warn, middleGray } from 'styles/colorProvider';
import {
  BsCircle,
  BsCircleHalf,
  BsCircleFill,
  BsShieldCheck,
  BsShieldX,
  BsShieldLock,
  BsChatSquareText,
  BsShieldExclamation,
  BsShieldPlus,
} from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FiDollarSign } from 'react-icons/fi';
import { BiCheck } from 'react-icons/bi';

export const formatPolicyStatus = (status) => {
  if (!status) return;
  if (status === 'awaiting_payment') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_pix') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_creditcard') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_debitcard') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'awaiting_billet') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'active') return { label: 'Ativo', color: success, condition: 'Seguro' };
  if (status === 'canceled') return { label: 'Cancelado', color: error, condition: 'Cancelada' };
  if (status === 'overdue') return { label: 'Atrasado', color: error, condition: 'Não seguro' };
  if (status === 'awaiting_activation') return { label: 'Aguardando ativação', color: warn, condition: 'Aguardando ativação' };
  if (status === 'awaiting_cancellation') return { label: 'Aguard. cancelamento', color: warn, condition: 'Aguardando cancelamento' };
};

export const formatProposalStatus = (status) => {
  if (!status) return;

  if (status === 'awaiting_inspection')
    return {
      label: 'Aguardando vistoria',
      color: middleGray,
      condition: 'Aguardando vistoria',
      icon: <BsCircle size={10} />,
      owner: 'Vistoriador',
    };

  if (status === 'awaiting_inspection_evaluation')
    return {
      label: 'Aguardando avaliação de fotos',
      color: middleGray,
      condition: 'Aguardando avaliação de fotos',
      icon: <BsCircle size={10} />,
      owner: 'Vistoriador',
    };

  if (status === 'evaluating_inspection')
    return {
      label: 'Em avaliação de fotos',
      color: middleGray,
      condition: 'Em avaliação de fotos',
      icon: <BsCircle size={10} />,
      owner: 'Vistoriador',
    };

  if (status === 'awaiting_complementar')
    return {
      label: 'Aguardando fotos complementares',
      color: warn,
      condition: 'Aguardando fotos complementares',
      icon: <BsCircleHalf size={10} />,
      owner: 'Backoffice',
    };

  if (status === 'awaiting_vistei')
    return {
      label: 'Aguardando avaliação manual de fotos',
      color: warn,
      condition: 'Aguardando avaliação manual de fotos',
      icon: <BsCircleHalf size={10} />,
      owner: 'Backoffice',
    };

  if (status === 'awaiting_registration')
    return {
      label: 'Aguardando cadastro',
      color: warn,
      condition: 'Aguardando cadastro',
      icon: <BsCircleHalf size={10} />,
      owner: 'Backoffice',
    };

  if (status === 'awaiting_payment')
    return {
      label: 'Aguardando pagamento',
      color: middleGray,
      condition: 'Aguardando pagamento',
      icon: <BsCircleFill size={10} />,
      owner: 'Cliente',
    };

  if (status === 'cancelled') return { label: 'Cancelada', color: error, condition: 'Cancelada' };
  if (status === 'rejected') return { label: 'Rejeitada', color: error, condition: 'Rejeitada' };
  if (status === 'activated') return { label: 'Ativada', color: warn, condition: 'Ativada' };

  if (status === 'expired') return { label: 'Expirada', color: error, condition: 'Expirada' };

  if (status === 'awaiting_complementar_report')
    return {
      label: 'Aguardando aprovação das fotos complementares',
      color: warn,
      condition: 'Aguardando aprovação das fotos complementares',
      icon: <BsCircleHalf size={10} />,
      owner: 'Backoffice',
    };
};

export const formatClaimStatus = (status) => {
  if (!status) return;
  if (status === 'created') return { label: 'Em análise', color: warn, condition: 'Em análise' };
  if (status === 'noticed') return { label: 'Aguardando aprovação', color: warn, condition: 'Aguardando aprovação' };
  if (status === 'awaiting_payment') return { label: 'Aguardando pagamento', color: warn, condition: 'Aguardando pagamento' };
  if (status === 'paid_deductible') return { label: 'Franquia paga', color: success, condition: 'Franquia paga' };
  if (status === 'claim_approved') return { label: 'Sinistro aprovado', color: success, condition: 'Sinistro aprovado' };
  if (status === 'claim_disapproved') return { label: 'Sinistro reprovado', color: error, condition: 'Sinistro reprovado' };
  if (status === 'claim_canceled') return { label: 'Sinistro cancelado', color: error, condition: 'Sinistro cancelado' };
  if (status === 'indemnity_disapproved')
    return { label: 'Indenização reprovada', color: error, condition: 'Indenização reprovada' };
  if (status === 'repair_approved') return { label: 'Reparo aprovado', color: success, condition: 'Reparo aprovado' };
  if (status === 'indemnity_total_approved')
    return { label: 'Indenização total aprovada', color: success, condition: 'Indenização total aprovada' };
  if (status === 'indemnity_partial_approved')
    return { label: 'Indenização parcial aprovada', color: success, condition: 'Indenização parcial aprovada' };
  if (status === 'indemnity_total_paid')
    return { label: 'Indenização total paga', color: success, condition: 'Indenização total paga' };
  if (status === 'indemnity_partial_paid')
    return { label: 'Indenização parcial paga', color: success, condition: 'Indenização parcial paga' };
  if (status === 'in_repair') return { label: 'Em reparo', color: warn, condition: 'Em reparo' };
  if (status === 'repair_finished') return { label: 'Reparo finalizado', color: success, condition: 'Reparo finalizado' };
  if (status === 'avaliation') return { label: 'Aguardando avaliação', color: warn, condition: 'Aguardando avaliação' };
  if (status === 'finished') return { label: 'Sinistro finalizado', color: success, condition: 'Sinistro finalizado' };
};

export const formatHistoricStatus = (status) => {
  if (!status) return;
  if (status === 'new_proposal') return { icon: <IoDocumentTextOutline size={16} />, color: success };
  if (status === 'inspection') return { icon: <BsShieldX size={16} />, color: success };
  if (status === 'registration') return { icon: <BiCheck size={16} />, color: success };
  if (status === 'new_policy') return { icon: <BsShieldCheck size={16} />, color: success };
  if (status === 'new_claim') return { icon: <BsShieldExclamation size={16} />, color: warn };
  if (status === 'attendance') return { icon: <BsChatSquareText size={16} />, color: warn };
  if (status === 'new_charge') return { icon: <FiDollarSign size={16} />, color: success };
  if (status === 'charge_paid') return { icon: <FiDollarSign size={16} />, color: success };
  if (status === 'charge_failed') return { icon: <FiDollarSign size={16} />, color: error };
  if (status === 'new_cancelation') return { icon: <BsShieldX size={16} />, color: warn };
  if (status === 'cancelation_updated') return { icon: <BsShieldX size={16} />, color: warn };
  if (status === 'new_complaint') return { icon: <BsChatSquareText size={16} />, color: success };
  if (status === 'complaint_updated') return { icon: <BsChatSquareText size={16} />, color: warn };
  if (status === 'complaint_finished') return { icon: <BsChatSquareText size={16} />, color: success };
};

export const formatHistoricStatusClaim = (status) => {
  if (!status) return;
  if (status === 'Novo aviso de sinistro') return { icon: <BsShieldPlus size={16} />, color: success };
  if (status === 'Envio aviso de sinistro') return { icon: <BiCheck size={16} />, color: success };
  if (status === 'Reabertura de sinistro') return { icon: <BsShieldLock size={16} />, color: warn };
  if (status === 'Mudança de status') return { icon: <BiCheck size={16} />, color: success };
  if (status === 'Cancelar aviso de sinistro') return { icon: <BsShieldX size={16} />, color: error };
  if (status === 'Sinistro aprovado') return { icon: <BsShieldCheck size={16} />, color: success };
  if (status === 'Sinistro recusado') return { icon: <BsShieldX size={16} />, color: error };
  if (status === 'Fatura de franquia criada') return { icon: <FiDollarSign size={16} />, color: warn };
  if (status === 'Pagamento franquia') return { icon: <FiDollarSign size={16} />, color: success };
};

export const formatClaimStatusHistory = (status) => {
  if (!status) return;

  if (status === 'awaiting_payment') return 'Aguardando pagamento';
  if (status === 'paid_deductible') return 'Franquia paga';
  if (status === 'claim_approved') return 'Sinistro aprovado';
  if (status === 'claim_disapproved') return 'Sinistro reprovado';
  if (status === 'claim_canceled') return 'Sinistro cancelado';
  if (status === 'indemnity_disapproved') return 'Indenização reprovada';
  if (status === 'repair_approved') return 'Reparo aprovado';
  if (status === 'indemnity_total_approved') return 'Indenização total aprovada';
  if (status === 'indemnity_partial_approved') return 'Indenização parcial aprovada';
  if (status === 'indemnity_total_paid') return 'Indenização total paga';
  if (status === 'indemnity_partial_paid') return 'Indenização parcial paga';
  if (status === 'in_repair') return 'Em reparo';
  if (status === 'repair_finished') return 'Reparo finalizado';
  if (status === 'avaliation') return 'Aguardando avaliação';
  if (status === 'finished') return 'Sinistro finalizado';
};
