/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import DataTable from 'react-data-table-component';
import api from 'api';
import moment from 'moment';

import { primary } from 'styles/colorProvider';
import { Header, SideMenu, Input, Button } from 'components';
import {
  Container,
  ContentArea,
  DataArea,
  Div,
  Title,
  MenuArea,
  MenuItem,
  WidgetArea,
  Widget,
  TableArea,
  Toolbar,
} from './styles';
import { dataColumns, dataColumnsCharges } from './helper';

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [loadingCharge, setLoadingCharge] = useState(false);

  const [generatedCharges, setGeneratedCharges] = useState();
  const [selectedMenu, setSelectedMenu] = useState('generatedCharges');
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [selectedPolicies, setSelectedPolicies] = useState([]);

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'POST',
      url: `/generated-charges`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        date: moment(selectedDate).format(),
      },
      json: true,
    });

    setGeneratedCharges(resp?.data);
    setLoading(false);
  };

  const handelCharges = async () => {
    setLoadingCharge(true);
    try {
      await api({
        method: 'POST',
        url: `/company-create-charge`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id_policies: selectedPolicies.map((i) => i.id),
        },
        json: true,
      });

      loadData();
    } catch (error) {}

    setLoadingCharge(false);
  };

  useEffect(() => {
    loadData();
  }, [selectedDate]);

  const handleSelection = ({ selectedRows }) => {
    setSelectedPolicies(selectedRows);
  };
  return (
    <>
      <Header />
      <Container>
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>DASHBOARD</Title>
          </Div>

          <DataArea>
            <WidgetArea>
              <Widget>
                <p>Data</p>
                <Input
                  height="30px"
                  width="150px"
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  endButtonIcon={loading && <ReactLoading color={primary} height={15} width={15} type="spin" />}
                />
              </Widget>
              <Widget>
                <p>Cobranças a gerar</p>
                <h2>{generatedCharges?.chargesToGenerateAmount || 0}</h2>
              </Widget>

              <Widget>
                <p>Cobranças geradas</p>
                <h2>{generatedCharges?.generatedChargesAmount || 0}</h2>
              </Widget>
            </WidgetArea>

            <TableArea>
              <p>Cobranças não geradas</p>
              <DataTable
                persistTableHead
                responsive
                columns={dataColumns}
                data={generatedCharges?.chargesToGenerate}
                highlightOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={<ReactLoading color={primary} height={30} width={30} style={{ marginTop: 10 }} type="spin" />}
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhuma cobrança a cobrar</small>}
                selectableRows
                onSelectedRowsChange={handleSelection}
                selectableRowDisabled={(i) => i?.generated}
              />

              {selectedPolicies?.length > 0 && (
                <Toolbar>
                  <Button height="28px">
                    Gerar {selectedPolicies?.length} {selectedPolicies?.length > 1 ? 'cobranças' : 'cobrança'}{' '}
                  </Button>
                </Toolbar>
              )}

              <p>Cobranças geradas</p>
              <DataTable
                persistTableHead
                responsive
                columns={dataColumnsCharges}
                data={generatedCharges?.generatedCharges}
                highlightOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={<ReactLoading color={primary} height={30} width={30} style={{ marginTop: 10 }} type="spin" />}
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhuma cobrança gerada</small>}
                selectableRows
                selectableRowDisabled={(i) => i?.id > 0}
              />
            </TableArea>
          </DataArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default Dashboard;
