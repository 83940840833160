/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { MdChevronLeft } from 'react-icons/md';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import api from 'api';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import { loadingColor, white } from 'styles/colorProvider';
import { toast } from 'react-toastify';

import { Header, ComplaintHistoric, Dialog, Button } from 'components';
import { Container, LoadingArea, ContentArea, Title, TextModal, ModalButton, Div, Area } from './styles';

function ComplaintDetails({ location }) {
  const complaintId = parseInt(location.pathname.split('/backoffice/detalhes-reclamacao/')[1]);

  const history = useHistory();
  const [textInput, setTextInput] = useState();
  const [showElement, setShowElement] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [complaintData, setComplaintData] = useState({});

  const [radioType, setRadioType] = useState();
  const [radioStatus, setRadioStatus] = useState();

  const loadData = async () => {
    const policyNumber = {
      method: 'GET',
      url: `/company-get-complaint/${complaintId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(policyNumber);

    setComplaintData(response.data);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'POST',
        url: `/create-complaint-history`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id_complaint: complaintId,
          id_policy: complaintData?.id_policy,
          id_type_reclamation: complaintData?.id_type_complaint,
          description: textInput,
          id_status_complaint: radioStatus,
          type: radioType,
        },

        json: true,
      });
      setLoadingUpdate(false);
      toast.success('Alteração realizada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      handleClose(true);
      loadData();
    } catch (e) {
      setLoadingUpdate(false);

      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleOpenModal = () => {
    setOpen(true);
    setShowElement(false);
  };

  const handleClose = () => {
    setRadioType();
    setRadioStatus();
    setTextInput('');
    setOpen(false);
  };
  const handleShowOrHide = () => setShowElement(true);

  const handleNoShowOrHide = () => setShowElement(false);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <Div>
              <Title>
                <IconButton size="small" style={{ marginRight: 10 }} onClick={() => history.push('/backoffice/reclamacoes')}>
                  <MdChevronLeft size={24} />
                </IconButton>
                RECLAMAÇÃO
              </Title>

              {complaintData?.id_status_complaint === 1 && (
                <>
                  <Button height="35px" onClick={handleOpenModal}>
                    Atualizar reclamação
                  </Button>
                  <Dialog open={open} onClose={handleClose} title="Atualizar reclamação">
                    <FormControl style={{ marginLeft: 5 }}>
                      <RadioGroup style={{ flexDirection: 'row', padding: 5 }}>
                        <FormControlLabel
                          value="updated"
                          selected={radioType === 'updated'}
                          control={<Radio />}
                          onClick={(e) => {
                            setRadioType(e.target.value);
                            handleNoShowOrHide();
                          }}
                          label="Atualização"
                        />
                        <FormControlLabel
                          style={{ marginLeft: 50 }}
                          value="finished"
                          selected={radioType === 'finished'}
                          onClick={(e) => {
                            setRadioType(e.target.value);
                            handleShowOrHide();
                          }}
                          control={<Radio />}
                          label="Finalização"
                        />
                      </RadioGroup>
                    </FormControl>

                    {showElement ? (
                      <FormControl style={{ marginLeft: 5 }}>
                        <TextModal>Tipo de finalização</TextModal>
                        <RadioGroup style={{ flexDirection: 'row', padding: 5 }}>
                          <FormControlLabel
                            value={2}
                            onClick={(e) => setRadioStatus(e.target.value)}
                            control={<Radio />}
                            label="Procedente"
                          />

                          <FormControlLabel
                            style={{ marginLeft: 50 }}
                            value={3}
                            onClick={(e) => setRadioStatus(e.target.value)}
                            control={<Radio />}
                            label="Improcedente"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}

                    <TextModal color>Observações:</TextModal>
                    <Area>
                      <TextareaAutosize
                        minRows={1}
                        placeholder="Digite..."
                        onChange={(e) => setTextInput(e.target.value)}
                        value={textInput}
                        style={{
                          padding: 10,
                          fontFamily: 'Nunito',
                          fontSize: 14,
                          width: '100%',
                          height: '100px',
                          borderRadius: 5,
                          borderColor: '#d1d2d4',
                        }}
                      />
                    </Area>

                    <ModalButton>
                      <Button
                        style={{ alignSelf: 'center' }}
                        onClick={handleSubmit}
                        disabled={loadingUpdate || !textInput || !radioType || (radioType === 'finished' && !radioStatus)}
                      >
                        {loadingUpdate ? (
                          <ReactLoading color={white} height={24} width={24} type="spin" />
                        ) : showElement ? (
                          'Finalizar'
                        ) : (
                          'Atualizar'
                        )}
                      </Button>
                    </ModalButton>
                  </Dialog>
                </>
              )}
            </Div>

            <div style={{ width: '100%' }}>
              <ComplaintHistoric data={complaintData} />
            </div>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default ComplaintDetails;
