import React from 'react';
import PartnersData from 'utils/corporateSettings.json';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import 'moment/locale/pt-br';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon } from './styles';
import { useHistory } from 'react-router-dom';
import { formatProposalStatus } from 'utils/policyStatus';
import { formatCPF, formatPlate } from 'react-data-formatter';

function ProposalItem({ proposal }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push(`/backoffice/detalhes-da-proposta/${proposal?.id}`);
  };

  const partnerInfo = PartnersData[PartnersData.findIndex((i) => i.id_partner === proposal?.partner?.id)];

  return (
    <>
      <Container onClick={navigate}>
        <div className="description">
          <div className="iconProposal">
            <Tooltip title={`Parceiro ${partnerInfo?.name}`}>
              <img className="partnerIcon" src={partnerInfo?.logos?.small} alt={partnerInfo?.name} />
            </Tooltip>
          </div>
          <Div style={{ width: '50%' }}>
            <Title>{proposal?.user?.name}</Title>
            <Subtitle>{formatCPF(proposal?.user.cpf)}</Subtitle>
          </Div>
          <Div>
            <Title color={formatProposalStatus(proposal?.status)?.color}>{formatProposalStatus(proposal?.status)?.label}</Title>
            <Subtitle>
              Placa: {proposal?.vehicle?.plate !== null || undefined ? formatPlate(proposal?.vehicle?.plate) : 'Sem placa'}
            </Subtitle>
          </Div>
          <Div>
            <Title color={formatProposalStatus(proposal?.status)?.color}>Parceiro: {partnerInfo?.name}</Title>
            <Subtitle>Enviada em {moment(proposal?.created_at).format('DD/MM/YYYY HH:mm')}</Subtitle>
          </Div>
          <Icon>
            <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
          </Icon>
        </div>
      </Container>
    </>
  );
}

export default ProposalItem;
