import styled from 'styled-components';
import { white, primary, secondary, lightGray } from 'styles/colorProvider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 90%;
  height: 100% !important;
  max-height: 100% !important;
  box-sizing: border-box;
  padding: 30px;
  padding-left: 90px;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;

  & > :first-child {
    margin-right: 50px;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > :first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const Div = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .containerTitle {
    display: flex;
    width: 40%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 63vh !important;
  max-height: 63vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${primary};
    border-radius: 10px;
  }

  .partnerIcon {
    height: 20px;
    width: 20px;
    border-radius: 2px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;
export const ModalButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const RemoveButton = styled.button`
  border: none;

  background: red;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  padding: 8px 0;
  border-radius: 10px;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
  }

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
  }
`;

// Remove opacity on hover
export const ButtonTable = styled.button`
  border: none !important;
  background: transparent !important;
  cursor: pointer;

  :hover {
    opacity: 0.4 !important;
  }
`;
export const CountArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  font-size: 12px;

  p {
    margin: 0px;
  }
`;

export const ModalError = styled.p`
  visibility: ${(props) => (props.showError ? 'visible' : 'hidden')};
  font-size: 12px;
  color: #f61e2c;
`;

export const DivCenter = styled.div`
  display: flex;
  margin: 0 auto;
`;


export const ConfirmAndCancelButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    text-decoration: none;
    text-align: center;
    border: none;
    border-radius: 30px;
    width: 40%;
    margin-top: 30px;
    height: 40px;
    margin-right: 18px;
    padding: 1px 6px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      opacity: 0.8;
    }
  }
  button.confirm {
    background: ${secondary};
  }
  button.cancel {
    background: ${lightGray};
  }
`;
