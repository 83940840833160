import { FcLowPriority, FcMediumPriority, FcHighPriority } from 'react-icons/fc';

export const formatTicketStatus = (status) => {
  if (!status) return;

  if (status === 'pending')
    return (
      <div
        style={{
          display: 'flex',
          width: 90,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#feeeca',
          border: '1px solid #d9c292',
          borderRadius: 20,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <p style={{ margin: 0, color: '#835a1d', fontSize: 11 }}>Ag. atendimento</p>
      </div>
    );

  if (status === 'in_attendance')
    return (
      <div
        style={{
          display: 'flex',
          width: 90,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#e4f6fc',
          border: '1px solid #9ec5fe',
          borderRadius: 20,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <p style={{ margin: 0, color: '#4c8ab0', fontSize: 11 }}>Em atendimento</p>
      </div>
    );

  if (status === 'finished')
    return (
      <div
        style={{
          display: 'flex',
          width: 90,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#edf8ee',
          border: '1px solid #a3cfbb',
          borderRadius: 20,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <p style={{ margin: 0, color: '#4a8253', fontSize: 11 }}>Finalizado</p>
      </div>
    );
};

export const formatTicketPriority = (priority) => {
  if (!priority) return;
  if (priority === 'low') return { label: 'Baixa', icon: <FcLowPriority size={18} /> };
  if (priority === 'medium') return { label: 'Média', icon: <FcMediumPriority size={18} /> };
  if (priority === 'high') return { label: 'Alta', icon: <FcHighPriority size={18} /> };
};
