export const formatOperation = (operation) => {
  switch (operation) {
    case 'add':
      return 'Adicionado';
    case 'update':
      return 'Editado';
    case 'remove':
      return 'Removido';
    default:
      return '';
  }
};

export const formatProvider = (provider) => {
  switch (provider) {
    case 'assistme':
      return 'AssistMe';
    case 'tempo':
      return 'Tempo';
    case 'liberi':
      return 'Liberi';
    default:
      return '';
  }
};

export const providerStatus = {
  success: 'success',
  error: 'error',
};
