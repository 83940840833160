import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';

import { Container, MenuItem, MenuButton, SubItems, SubItem, MenuItemCollapsable } from './styles';

import { BsShieldCheck, BsChatSquareText, BsShieldX, BsShieldExclamation, BsCashCoin } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { HiMenu } from 'react-icons/hi';
import { SlSupport } from 'react-icons/sl';
import { Ri24HoursFill } from 'react-icons/ri';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { AiOutlineRight } from 'react-icons/ai';

function SideMenu() {
  const location = useLocation();
  const path = location.pathname;

  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedItem, setCollapsedItem] = useState(true);

  const permissions = JSON.parse(Cookies.get('splitrisk-backoffice-permissions'));

  const hasPermission = (menu) => {
    const menuIndex = permissions.findIndex((i) => i.feature === menu);
    if (menuIndex === -1) return;
    return permissions[menuIndex].hasPermission;
  };

  const toggleCollapsed = () => {
    setCollapsedItem(!collapsedItem);
  };

  const assistanceSubItems = [
    {
      name: 'Baixar Relatórios',
      url: '/backoffice/assistencia/baixar-relatorios',
    },
    {
      name: 'Listagem de Assistência',
      url: '/backoffice/assistencia/',
    },
  ];

  const subItems = [
    {
      name: 'Administrar Ceps',
      url: '/backoffice/produtos/administrar-ceps',
    },
    {
      name: 'Administrar Veículos',
      url: '/backoffice/produtos/administrar-veiculos',
    },
    {
      name: 'Bloqueio de coberturas',
      url: '/backoffice/produtos/bloqueio-coberturas',
    },
    {
      name: 'Bloqueios de cotação',
      url: '/backoffice/produtos/bloqueio-cotacoes',
    },
    {
      name: 'Coberturas',
      url: '/backoffice/produtos/coberturas',
    },
    {
      name: 'Condições do parceiro',
      url: '/backoffice/produtos/parceiros',
    },
    {
      name: 'Travas de comissionamento',
      url: '/backoffice/produtos/parceiros-comissao',
    },
  ];

  return (
    <Container collapsed={collapsed}>
      <MenuButton collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
        <HiMenu />
      </MenuButton>

      {hasPermission('menu-propostas') && (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/backoffice/propostas') && 'select'}
          onClick={() => history.push('/backoffice/propostas')}
        >
          <IoDocumentTextOutline />
          <p className="menuText">Propostas</p>
        </MenuItem>
      )}

      {hasPermission('menu-apolices') && (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/backoffice/apolices') && 'select'}
          onClick={() => history.push('/backoffice/apolices')}
        >
          <BsShieldCheck />
          <p className="menuText">Apólices</p>
        </MenuItem>
      )}
      {hasPermission('menu-apolices') && (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/backoffice/cobrancas') && 'select'}
          onClick={() => history.push('/backoffice/cobrancas')}
        >
          <BsCashCoin />
          <p className="menuText">Cobranças</p>
        </MenuItem>
      )}

      {hasPermission('menu-reclamacoes') && (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/backoffice/reclamacoes') && 'select'}
          onClick={() => history.push('/backoffice/reclamacoes')}
        >
          <BsChatSquareText />
          <p className="menuText">Reclamações</p>
        </MenuItem>
      )}

      {hasPermission('menu-usuarios') && (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/backoffice/usuarios') && 'select'}
          onClick={() => history.push('/backoffice/usuarios')}
        >
          <FiUsers />
          <p className="menuText">Usuários</p>
        </MenuItem>
      )}

      {hasPermission('menu-sinistros') && (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/backoffice/sinistros') && 'select'}
          onClick={() => history.push('/backoffice/sinistros')}
        >
          <BsShieldExclamation />
          <p className="menuText">Sinistros</p>
        </MenuItem>
      )}

      {hasPermission('menu-cancelamentos') && (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/backoffice/cancelamentos') && 'select'}
          onClick={() => history.push('/backoffice/cancelamentos')}
        >
          <BsShieldX />
          <p className="menuText">Cancelamentos</p>
        </MenuItem>
      )}

      <MenuItem
        collapsed={collapsed}
        className={path.includes('/backoffice/suporte') && 'select'}
        onClick={() => history.push('/backoffice/suporte')}
      >
        <SlSupport />
        <p className="menuText">Suporte</p>
      </MenuItem>

      <MenuItem
        collapsed={collapsed}
        className={path.includes('/backoffice/premio-servicos') && 'select'}
        onClick={() => history.push('/backoffice/premio-servicos')}
      >
        <IoDocumentTextOutline />
        <p className="menuText">Prêmio x Serviços</p>
      </MenuItem>

      <>
        <MenuItemCollapsable
          collapsedSidebar={collapsed}
          className={path.includes('/backoffice/assistencia') && 'select'}
          collapsedItem={collapsedItem}
          onClick={toggleCollapsed}
        >
          <Ri24HoursFill />
          <span className="menuText">Assistência</span>
          <AiOutlineRight className="arrow" />
        </MenuItemCollapsable>
        <SubItems collapsedSidebar={collapsed} collapsedItem={collapsedItem}>
          {collapsed ||
            assistanceSubItems.map((subItem) => (
              <SubItem collapsed={collapsedItem} onClick={() => history.push(subItem.url)}>
                <p className="menuText">{subItem.name}</p>
              </SubItem>
            ))}
        </SubItems>
      </>
      {hasPermission('menu-blacklist-portal') && (
        <MenuItem
          collapsed={collapsed}
          className={path.includes('/backoffice/blacklist') && 'select'}
          onClick={() => history.push('/backoffice/blacklist')}
        >
          <SlSupport />
          <p className="menuText">Blacklist</p>
        </MenuItem>
      )}

      {/* <>
        <MenuItemCollapsable
          collapsedSidebar={collapsed}
          className={path.includes('/backoffice/produtos') && 'select'}
          collapsedItem={collapsedItem}
          onClick={toggleCollapsed}
        >
          <Ri24HoursFill />
          <span className="menuText">Produtos</span>
          <AiOutlineRight className="arrow" />
        </MenuItemCollapsable>
        <SubItems collapsedSidebar={collapsed} collapsedItem={collapsedItem}>
          {collapsed ||
            subItems.map((subItem) => (
              <SubItem collapsed={collapsedItem} onClick={() => history.push(subItem.url)}>
                <p className="menuText">{subItem.name}</p>
              </SubItem>
            ))}
        </SubItems>
      </> */}
    </Container>
  );
}

export default SideMenu;
