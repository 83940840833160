/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import CsvDownload from 'react-json-to-csv';
import api from 'api';
import { toast } from 'react-toastify';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { Header, SideMenu, Select, Search, Button, Dialog, Input } from 'components';
import {
  Container,
  ContentArea,
  Title,
  Div,
  DataArea,
  LoadingArea,
  CountArea,
  ModalButton,
  ButtonTable,
  RemoveButton,
  ModalError,
} from './styles';
import { loadingColor, primary, white, secondary } from 'styles/colorProvider';
import { TiFilter } from 'react-icons/ti';
import { formatCPF } from 'react-data-formatter';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { SliderContent } from '../Partners/styles';

import { renderAddUserCommission, renderEditUserCommission, renderRemoveUserCommission } from './modals';
import { getUserPartners, handleLoadMore, loadData } from './requests';
import { ConfirmDialog } from 'components/index';

// import { dataColumns } from './helper';

function BackOfficeProductsPartnersComission({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingDataTable, setLoadingDataTable] = useState(false);
  const [usercommissionsList, setUsercommissionsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState();
  const [selectStatus, setSelectStatus] = useState('all');
  const [startData, setStartdata] = useState();
  const [endData, setEndData] = useState();
  const [touchFilter, setTouchFilter] = useState();

  // Modals
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);

  const [selectedPartnerVendor] = useState('all');

  const [userPartners, setUserPartners] = useState([]);
  const [userPartnerCommissionInfo, setUserPartnerCommissionInfo] = useState({
    id: undefined,
    client_id: undefined,
    enabled: false,
    percent: '',
  });
  const [userPartnerInfo, setUserPartnerInfo] = useState({
    loading: 0,
    maxLoading: 0,
    commercialization: 0,
  });

  const handleClear = () => {
    setSelectStatus('all');
    setStartdata('');
    setEndData('');
  };

  useEffect(() => {
    getUserPartners({
      setLoading,
      setUserPartners,
      setUserPartnerInfo,
    });
    loadData({
      setCurrentPage,
      setUsercommissionsList,
      setHasNextPage,
      setLoading,
      setTotalCount,
      setTouchFilter,
      loadingDataTable,
      setLoadingDataTable,
    });
  }, []);

  const renderFilterDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} width="50%" title="Filtrar proposta">
      <Select height="30px" label="Filtrar por status" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
        <option value={'all'}>Todos os Status</option>
        <option value={'awaiting_inspection'}>Aguardando vistoria</option>
        <option value={'awaiting_payment'}>Aguardando pagamento</option>
        <option value={'activated'}>Ativada</option>
        <option value={'rejected'}> Rejeitada</option>
        <option value={'cancelled'}>Cancelada</option>
        <option value={'awaiting_registration'}>Aguardando cadastro</option>
        <option value={'expired'}> Expirada</option>
        <option value={'awaiting_vistei'}>Aguardando aprovação de fotos</option>
        <option value={'awaiting_complementar'}>Aguardando fotos complementares</option>
        <option value={'awaiting_complementar_report'}>Aguardando aprovação das fotos complementares</option>
      </Select>

      <Input
        value={startData}
        onChange={(e) => setStartdata(e.target.value)}
        widht="30%"
        label="Data inicial"
        mask="99/99/9999"
        height="30px"
      />
      <Input
        value={endData}
        onChange={(e) => setEndData(e.target.value)}
        widht="30%"
        label="Data final"
        mask="99/99/9999"
        height="30px"
      />
      <ModalButton>
        <Button
          height="35px"
          outlined
          disabled={selectStatus === 'all' && startData === '' && endData === ''}
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          height="35px"
          onClick={() => {
            setTouchFilter(true);
            loadData({
              setCurrentPage,
              setUsercommissionsList,
              setHasNextPage,
              setLoading,
              setTotalCount,
              setTouchFilter,
              search,
              loadingDataTable,
              setLoadingDataTable,
            });
            setOpen(false);
          }}
        >
          Aplicar alterações
        </Button>
      </ModalButton>
    </Dialog>
  );

  const dataColumns = [
    {
      name: 'ID',
      selector: (row) => row?.id,
      width: '10%',
      sortable: true,
    },
    {
      name: 'Cliente',
      selector: (row) => row?.TB_USERS?.name,
      width: '15%',
      sortable: true,
    },
    {
      name: 'CPF',
      selector: (row) => formatCPF(row.TB_USERS?.cpf),
      width: '15%',
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.TB_USERS?.email,
      width: '20%',
      sortable: true,
    },
    {
      name: 'Comissão',
      selector: (row) => `${row.percent}%`,
      width: '10%',
      sortable: true,
    },
    {
      name: 'Regra ativa?',
      selector: (row) =>
        row.enabled ? (
          <AiFillCheckCircle
            size={16}
            style={{
              color: '#62ec83',
            }}
          />
        ) : (
          <AiFillCloseCircle
            size={16}
            style={{
              color: 'red',
            }}
          />
        ),
      width: '16%',
      sortable: true,
    },
    {
      name: 'Editar',
      selector: (row) => (
        <ButtonTable
          onClick={() => {
            setUserPartnerCommissionInfo({
              id: row.id,
              enabled: row.enabled,
              percent: row.percent,
            });
            setOpenModalEdit(true);
          }}
        >
          <BiPencil size={16} />
        </ButtonTable>
      ),
      width: '7%',
      sortable: false,
    },
    {
      name: 'Excluir',
      selector: (row) => (
        <ButtonTable
          onClick={() => {
            setUserPartnerCommissionInfo({
              ...userPartnerCommissionInfo,
              id: row.id,
            });
            setOpenModalRemove(true);
          }}
        >
          <BiTrash size={16} />
        </ButtonTable>
      ),
      width: '7%',
      sortable: false,
    },
  ];

  useEffect(() => {
    console.log(usercommissionsList?.length);
  }, [usercommissionsList]);

  return (
    <>
      <Header />
      <Container>
        {renderFilterDialog()}
        {renderRemoveUserCommission({
          openModalRemove,
          setOpenModalRemove,
          userPartnerCommissionInfo,
          setUserPartnerCommissionInfo,
          loading,
          setLoading,
          setCurrentPage,
          setUsercommissionsList,
          setHasNextPage,
          setTotalCount,
          setTouchFilter,
          setUserPartners,
          setUserPartnerInfo,
          loadingDataTable,
          setLoadingDataTable,
        })}
        {renderAddUserCommission({
          openModalAdd,
          setOpenModalAdd,
          userPartners,
          userPartnerCommissionInfo,
          setUserPartnerCommissionInfo,
          setLoading,
          loading,
          userPartnerInfo,
          setCurrentPage,
          setUsercommissionsList,
          setHasNextPage,
          setTotalCount,
          setTouchFilter,
          setUserPartners,
          setUserPartnerInfo,
          loadingDataTable,
          setLoadingDataTable,
        })}
        {renderEditUserCommission({
          openModalEdit,
          setOpenModalEdit,
          userPartnerCommissionInfo,
          setUserPartnerCommissionInfo,
          loading,
          setLoading,
          userPartnerInfo,
          loadData,
          setCurrentPage,
          setUsercommissionsList,
          setHasNextPage,
          setTotalCount,
          setTouchFilter,
          loadingDataTable,
          setLoadingDataTable,
        })}
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>TRAVAS DE COMISSIONAMENTO</Title>
            <div className="containerTitle">
              <Button
                title="Adicionar"
                height="30px"
                onClick={() => setOpenModalAdd(true)}
                disabled={loading || loadingDataTable}
              >
                Adicionar
              </Button>

              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData({
                    setCurrentPage,
                    setUsercommissionsList,
                    setHasNextPage,
                    setLoading,
                    setTotalCount,
                    setTouchFilter,
                    search,
                    loadingDataTable,
                    setLoadingDataTable,
                  });
                }}
                onConfirm={() =>
                  loadData({
                    setCurrentPage,
                    setUsercommissionsList,
                    setHasNextPage,
                    setLoading,
                    setTotalCount,
                    setTouchFilter,
                    search,
                    loadingDataTable,
                    setLoadingDataTable,
                  })
                }
              />
            </div>
          </Div>
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={() => {
                handleLoadMore({
                  touchFilter,
                  search,
                  startData,
                  endData,
                  selectStatus,
                  selectedPartnerVendor,
                  currentPage,
                  hasNextPage,
                  loadingMore,
                  setLoadingMore,
                  usercommissionsList,
                  setCurrentPage,
                  setUsercommissionsList,
                  setHasNextPage,
                });
              }}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={0}
            >
              <DataTable
                // onRowClicked={(row) => history.push(`/backoffice/detalhes-da-proposta/${row?.id}`)}
                persistTableHead
                responsive
                columns={dataColumns}
                data={usercommissionsList}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading || loadingDataTable}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhum travamento encontrado</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {usercommissionsList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default BackOfficeProductsPartnersComission;
