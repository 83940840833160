import React from 'react';
import { StyledInput, Label, Container } from './styles';

function Select({
  icon,
  placeholder,
  label,
  value,
  onChange,
  width,
  disabled,
  disableLabel,
  marginVertical,
  onFocus,
  height,
  success,
  error,
  children,
}) {
  return (
    <Container>
      {!disableLabel && <Label>{label}:</Label>}

      <StyledInput
        success={success}
        error={error}
        height={height}
        icon={icon && true}
        width={width}
        marginVertical={marginVertical}
        disabled={disabled}
      >
        {icon && <div>{icon}</div>}
        <select value={value} placeholder={placeholder} onFocus={onFocus} onChange={onChange}>
          {children}
        </select>
      </StyledInput>
    </Container>
  );
}

export default Select;
