import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { error, success, warn, middleGray } from 'styles/colorProvider';
import { AiOutlineBarcode } from 'react-icons/ai';

import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlineCalendar,
  HiOutlineXCircle,
} from 'react-icons/hi';
import { Container, Status, StatusText, Month, MonthText, ContainerText, Value, Message } from './styles';
import { formatBilletStatus } from 'utils/billetStatus';
import { formatCurrency } from 'react-data-formatter';

function BilletItem({ id, status, month, value, message, clickable, type, billet, data }) {
  const dueAt = `Data vencimento ${moment(message).format('DD/MM/YYYY')}`;

  const handleOpenBillet = () => {
    window.open(data?.url).focus();
  };

  return (
    <>
      <Container
        clickable={clickable}
      >
        <div className="description">
          <Month>
            <HiOutlineCalendar color={middleGray} size={16} />
            <MonthText>{moment(month).format('MMMM/YYYY')}</MonthText>
          </Month>
          <Status>
            {status === 'open' && <HiOutlineExclamationCircle color={warn} size={20} />}
            {status === 'paid' && <HiOutlineCheckCircle color={success} size={20} />}
            {status === 'due' && <HiOutlineXCircle color={error} size={20} />}
            <StatusText>{formatBilletStatus(status)?.label}</StatusText>
          </Status>
          <ContainerText>
            <Value>               
               {type === 'deductible' ? formatCurrency(value) : formatCurrency(value)}
            </Value>
          </ContainerText>
          <Message>
            {type === 'subscription' && 'Subscrição'}
            {type === 'resubscription' && 'Resubscrição'}
            {type === 'deductible' && 'Franquia'}
            {type === 'other' && 'Outros'}
          </Message>
          <Message>
            {status && <nobr>{dueAt}</nobr>}
          </Message>  
        </div>  
          {status === 'open' && (
          <div className="button">
            <button onClick={handleOpenBillet}>
              <AiOutlineBarcode size={18} />
            </button>
          </div>
        )}  
      </Container>
    </>
  );
}

export default BilletItem;
