import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards-br';
import api from 'api';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { loadingColor } from 'styles/colorProvider';
import { Header, ExtractItem, Button, ConfirmDialog, Input, Card, Dialog } from 'components';
import { Container, ContentArea, MenuArea, ItemMenu, TextItem, CardsArea, MobileMenu, CardClient, Title } from './styles';
import { MdChevronLeft } from 'react-icons/md';
import { FiDollarSign } from 'react-icons/fi';
import { BsCreditCard } from 'react-icons/bs';
import { BiMenuAltRight } from 'react-icons/bi';
import emptyAnimation from 'assets/empty.json';
import { AiOutlinePlus } from 'react-icons/ai';
import './cardStyle.css';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';

function Wallet({ history }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSection, setSelectedSection] = useState('cards');
  const [card, setCard] = useState([]);
  const [cardData, setCardData] = useState();
  const [loading, setLoading] = useState();
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState();
  const [loadingSelectCard, setLoadingSelectCard] = useState();
  const [extractData, setExtractData] = useState([]);
  const [showConfirmAddCard, setShowConfirmAddCard] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showSelectCard, setShowSelectCard] = useState(false);
  const [addCardData, setAddCardData] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    focused: '',
  });

  const listCard = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/me/list-cards-asaas`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });
    setCard(resp.data);
    setCardData(resp.data);
    setLoading(false);
  };

  const loadData = async () => {
    setLoading(true);
    const extract = await api({
      method: 'GET',
      url: `/me/extract`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });

    setExtractData(extract?.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    listCard();
  }, []);

  const handleRemoveCard = async (e) => {
    setLoadingRemove(true);
    try {
      await api({
        method: 'POST',
        url: `/me/remove-card`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: { id_payment_profile: showConfirm?.id },
        json: true,
      });
      setShowConfirm();
      setLoadingRemove(false);
      listCard();
      toast.success('Cartão removido com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (e) {
      setLoadingRemove(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };
  const handleSelectCard = async (e) => {
    setLoadingSelectCard(true);
    try {
      await api({
        method: 'POST',
        url: `/me/main-card`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: { id_payment_profile: showSelectCard?.id },
        json: true,
      });
      setShowSelectCard();
      setLoadingSelectCard(false);
      listCard();
      toast.success('Cartão alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (e) {
      setLoadingSelectCard(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleAddCard = async (e) => {
    setLoadingButton(true);
    try {
      const [expiryMonth, expiryYear] = addCardData?.expiry.split('/');
      await api({
        method: 'POST',
        url: `/me/add-card`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          creditCard: {
            holderName: addCardData?.name,
            number: addCardData?.number,
            expiryMonth: expiryMonth,
            expiryYear: `20${expiryYear}`,
            ccv: addCardData?.cvc,
          },
        },
        json: true,
      });
      setShowAddCard(false);
      setLoadingButton(false)
      setAddCardData({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: '',
      });
      listCard();
      loadData();
      toast.success('Cartão cadastrado com sucesso', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (e) {
      toast.error(e?.response?.data?.error || 'Não foi possível cadastrar esse cartão', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setShowAddCard(false);
    }
  };

  return (
    <>
      <ConfirmDialog
        open={showConfirmAddCard}
        onCancel={() => setShowConfirmAddCard(false)}
        title="Tem certeza que deseja substituir esse cartão"
        content={<Alert severity="warning" >Lembrando que ao adicionar esse cartão <strong>a última cobrança de recorrência em aberto será paga imediatamente</strong></Alert>}
        onConfirm={handleAddCard}
        loading={loadingButton}
      />

      <ConfirmDialog
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        content="Tem certeza de que deseja excluir este cartão?"
        title="Excluir cartão?"
        onConfirm={handleRemoveCard}
        loading={loadingRemove}
      />
      <ConfirmDialog
        open={showSelectCard}
        onCancel={() => setShowSelectCard(false)}
        content="Tem certeza de que deseja tornar este cartão o principal para cobranças?"
        title="Selecionar cartão"
        onConfirm={handleSelectCard}
        loading={loadingSelectCard}
      />
      <Header />
      <Container>
        <ContentArea>
          <MobileMenu>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <BiMenuAltRight size={26} />
            </IconButton>

            <Menu
              style={{ marginTop: '50px', marginLeft: '-40px' }}
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  history.push('/minhas-apolices');
                }}
              >
                <MdChevronLeft size="18" />
                <TextItem>Minha apólice</TextItem>
              </MenuItem>

              <MenuItem selected={selectedSection === 'cards'} onClick={() => setSelectedSection('cards')}>
                <BsCreditCard size="18" style={{ marginRight: 5 }} />
                Meu cartão
              </MenuItem>

              <MenuItem selected={selectedSection === 'extract'} onClick={() => setSelectedSection('extract')}>
                <FiDollarSign size="18" style={{ marginRight: 5 }} /> Extrato
              </MenuItem>
            </Menu>
          </MobileMenu>

          <MenuArea>
            <ItemMenu
              onClick={() => {
                history.push('/minhas-apolices');
              }}
            >
              <MdChevronLeft size="18" />
              <TextItem>Minha apólice</TextItem>
            </ItemMenu>

            <ItemMenu className={selectedSection === 'cards' && 'select'} onClick={() => setSelectedSection('cards')}>
              <BsCreditCard size="18" style={{ marginRight: 5 }} />
              Meu cartão
            </ItemMenu>

            <ItemMenu className={selectedSection === 'extract' && 'select'} onClick={() => setSelectedSection('extract')}>
              <FiDollarSign size="18" style={{ marginRight: 5 }} /> Extrato
            </ItemMenu>
          </MenuArea>

          {loading && (
            <CardsArea style={{ paddingTop: 30 }}>
              <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
            </CardsArea>
          )}

          {!loading && selectedSection === 'cards' && !!card.length && (
            <CardClient>
              <Title>
                <h3>Seu cartão</h3>
              </Title>
              {card
                .sort((x) => (x.default ? -1 : 1))
                .map((cardData) => (
                  <Card
                    cardData={cardData}
                    remove={() => setShowConfirm(cardData)}
                    addCard={() => setShowAddCard(true)}
                    selectCard={() => setShowSelectCard(cardData)}
                  />
                ))}
            </CardClient>
          )}

          {!loading && selectedSection === 'cards' && !card.length && (
            <CardsArea>
              <Lottie
                autoplay
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: emptyAnimation,
                }}
                height={180}
                width={280}
              />
              <h3>Você não tem nenhum cartão cadastrado</h3>
              <Button style={{ marginTop: 20 }} onClick={setShowAddCard}>
                Adicionar cartão
              </Button>
            </CardsArea>
          )}

          {!loading && selectedSection === 'cards' && showAddCard && (
            <>
              <Dialog open={setShowAddCard} onClose={() => {setShowAddCard(false); setShowConfirmAddCard(false)}}>
                <CardsArea>
                  <h3>Cadastrar novo cartão</h3>
                  <div className="cardLine">
                    <div className="cardForm">
                      <Input
                        label="Número do cartão"
                        disableLabel
                        number
                        mask="9999 9999 9999 9999"
                        placeholder="Insira o número do cartão"
                        value={addCardData?.number}
                        onChange={(e) => setAddCardData({ ...addCardData, number: e.target.value })}
                        onFocus={() => setAddCardData({ ...addCardData, focused: 'number' })}
                      />
                      <Input
                        label="Nome do titular"
                        disableLabel
                        placeholder="Insira o nome do titular"
                        value={addCardData?.name}
                        onChange={(e) => setAddCardData({ ...addCardData, name: e.target.value.toUpperCase() })}
                        onFocus={() => setAddCardData({ ...addCardData, focused: 'name' })}
                      />
                      <Input
                        number
                        disableLabel
                        label="Validade do cartão"
                        mask="99/99"
                        placeholder="MM/AA"
                        value={addCardData?.expiry}
                        onChange={(e) => setAddCardData({ ...addCardData, expiry: e.target.value })}
                        onFocus={() => setAddCardData({ ...addCardData, focused: 'expiry' })}
                      />
                      <Input
                        number
                        mask="999"
                        disableLabel
                        placeholder="CVC/CVV"
                        label="CVC/CVV"
                        value={addCardData?.cvc}
                        onChange={(e) => setAddCardData({ ...addCardData, cvc: e.target.value })}
                        onFocus={() => setAddCardData({ ...addCardData, focused: 'cvc' })}
                      />
                    </div>
                    <div>
                      <div className="cardBox">
                        <Cards
                          locale={{ valid: 'Validade' }}
                          placeholders={{ name: 'NOME DO TITULAR' }}
                          number={addCardData?.number}
                          name={addCardData?.name}
                          expiry={addCardData?.expiry}
                          cvc={addCardData?.cvc}
                          focused={addCardData?.focused}
                        />
                      </div>
                    </div>
                  </div>
                  <Alert style={{ marginTop: 20 }} severity="warning">
                  {card?.length > 0
                      ? 'Ao cadastrar esse cartão o anterior será sobreposto, e caso tenha alguma fatura em aberto o pagamento será processado'
                      : 'Ao cadastrar o Cartão a modalidade de Recorrência não é ativada, sendo necessário acessar suas faturas e alterar a forma de pagamento para Cartão de Crédito'}
                  </Alert>
                  <Button style={{ marginTop: 20 }} onClick={() => card?.length > 0 ? setShowConfirmAddCard(true) : handleAddCard()} loading={loadingButton} disabled={loadingButton}>
                    Adicionar cartão
                  </Button>
                </CardsArea>
              </Dialog>
            </>
          )}

          {!loading && selectedSection === 'extract' && (
            <>
              <h3>Extrato do usuário</h3>
              <div className="extractArea">
                {extractData?.map((extract) => (
                  <ExtractItem data={extract} />
                ))}
              </div>
            </>
          )}
        </ContentArea>
      </Container>
    </>
  );
}

export default Wallet;
