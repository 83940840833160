import { Header, SideMenu, Select, Search, Button, Dialog, Input } from 'components';
import { SliderContent } from '../Partners/styles';
import { Switch, FormControlLabel, withStyles, Checkbox } from '@material-ui/core';
import { loadingColor, primary } from 'styles/colorProvider';
import { DivCenter, ModalButton, ModalError, RemoveButton } from './styles';
import ReactLoading from 'react-loading';
import { ConfirmDialog } from 'components/index';
import { createBlockCoverages, handleRemoveBlockCoverages, updateBlockCoverages } from './requests';

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '12px', // Defina o tamanho da fonte desejado aqui
  },
})(FormControlLabel);

const renderEditBlockCoverages = ({
  openModalEdit,
  setOpenModalEdit,
  blockCoverageInfo,
  setBlockCoverageInfo,
  loading,
  setLoading,
  userPartnerInfo,
  loadData,
  setCurrentPage,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  loadingDataTable,
  setLoadingDataTable,
  coverageType,
  setBlockCoverageList,
}) => (
  <Dialog
    open={openModalEdit}
    onClose={() => {
      setOpenModalEdit(false);
      setBlockCoverageInfo({
        id: undefined,
        id_partner: undefined,
        id_pricing: undefined,
        enabled: false,
      });
    }}
    width="100%"
    title="Editar bloqueio"
  >
    {/* <ModalError showError={values.loading > values.commercialization || values.max_loading > values.commercialization}>
        A comercialização não pode ser menor que o carregamento {values.loading > values.commercialization ? 'atual' : 'máximo'}
      </ModalError> */}

    <CustomFormControlLabel
      control={
        <Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
          style={{ color: primary }}
          checked={blockCoverageInfo.enabled}
          onChange={(e) => {
            setBlockCoverageInfo({
              ...blockCoverageInfo,
              enabled: e.target.checked,
            });
          }}
        />
      }
      label="Regra ativa?"
    />

    <ModalButton>
      <Button
        height="35px"
        style={{
          marginTop: '100px',
        }}
        onClick={() => {
          updateBlockCoverages({
            setLoading,
            blockCoverageInfo,
            setBlockCoverageInfo,
            setOpenModalEdit,
            loadData,
            setCurrentPage,
            setHasNextPage,
            setTotalCount,
            setTouchFilter,
            loadingDataTable,
            setLoadingDataTable,
            coverageType,
            setBlockCoverageList,
          });
        }}
        disabled={loading}
      >
        {loading ? <ReactLoading color={loadingColor} height={28} width={28} type="spin" /> : 'Editar'}
      </Button>
    </ModalButton>
  </Dialog>
);

const renderAddBlockCoverages = ({
  openModalAdd,
  setOpenModalAdd,
  userPartners,
  blockCoverageInfo,
  setBlockCoverageInfo,
  loading,
  setLoading,
  userPartnerInfo,
  setCurrentPage,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
  coverageType,
  setBlockCoverageList,
  partnersList,
  coveragesList,
}) => (
  <Dialog
    open={openModalAdd}
    onClose={() => {
      setOpenModalAdd(false);
      setBlockCoverageInfo({
        id: undefined,
        id_partner: undefined,
        id_pricing: undefined,
        enabled: false,
      });
    }}
    width="100%"
    title="Adicionar bloqueio"
  >
    {/* <Select
      height="30px"
      label="Escolher o cliente"
      // value={userPartnerCommissionInfo.client_id}
      // onChange={(e) => setUserPartnerCommissionInfo({ ...userPartnerCommissionInfo, client_id: e.target.value })}
    >
      <option value="" disabled selected>
        Selecione o cliente
      </option>

      {userPartners.map((partner) => (
        <option key={partner.id} value={partner.id}>
          {partner.name} - {partner.cpf}
        </option>
      ))}
    </Select> */}

    {/* <Form> */}
    <Select
      label="Parceiro"
      height="35px"
      value={blockCoverageInfo?.id_partner}
      onChange={(e) => setBlockCoverageInfo({ ...blockCoverageInfo, id_partner: e.target.value })}
    >
      <option value="" disabled selected>
        Selecione o parceiro
      </option>
      {partnersList?.map((partner) => (
        <option value={partner?.id}>{partner?.trade_name}</option>
      ))}
    </Select>
    <Select
      label="Cobertura"
      height="35px"
      value={blockCoverageInfo?.id_pricing}
      onChange={(e) => setBlockCoverageInfo({ ...blockCoverageInfo, id_pricing: e.target.value })}
    >
      <option value="" disabled selected>
        Selecione a cobertura
      </option>
      {coveragesList?.map((coverage) => (
        <option value={coverage?.id}>{coverage?.name}</option>
      ))}
    </Select>
    {/* </Form> */}

    <CustomFormControlLabel
      control={
        <Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
          style={{ color: primary }}
          checked={blockCoverageInfo.enabled}
          onChange={(e) => {
            setBlockCoverageInfo({
              ...blockCoverageInfo,
              enabled: e.target.checked,
            });
          }}
        />
      }
      label="Regra ativa?"
    />

    <ModalButton>
      {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

      <Button
        height="35px"
        onClick={() => {
          createBlockCoverages({
            setLoading,
            blockCoverageInfo,
            setBlockCoverageInfo,
            setOpenModalAdd,
            setCurrentPage,
            setHasNextPage,
            setTotalCount,
            setTouchFilter,
            setUserPartners,
            setUserPartnerInfo,
            loadingDataTable,
            setLoadingDataTable,
            coverageType,
            setBlockCoverageList,
          });
        }}
        disabled={loading || !blockCoverageInfo.id_partner || !blockCoverageInfo.id_pricing}
      >
        Adicionar
      </Button>
    </ModalButton>
  </Dialog>
);

const renderRemoveBlockCoverages = ({
  openModalRemove,
  setOpenModalRemove,
  blockCoverageInfo,
  setBlockCoverageInfo,
  loading,
  setLoading,
  setCurrentPage,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
  coverageType,
  setBlockCoverageList,
}) => (
  <ConfirmDialog
    open={openModalRemove}
    onCancel={() => {
      setOpenModalRemove(false);
      setBlockCoverageInfo({
        ...blockCoverageInfo,
        id: undefined,
      });
    }}
    content="Tem certeza de que deseja excluir esse bloqueio?"
    title="Excluir bloqueio"
    onConfirm={() => {
      handleRemoveBlockCoverages({
        setLoading,
        blockCoverageInfo,
        setBlockCoverageInfo,
        setOpenModalRemove,
        setCurrentPage,
        setHasNextPage,
        setTotalCount,
        setTouchFilter,
        setUserPartners,
        setUserPartnerInfo,
        loadingDataTable,
        setLoadingDataTable,
        coverageType,
        setBlockCoverageList,
      });
    }}
    loading={loading}
  />
);

export { renderEditBlockCoverages, renderAddBlockCoverages, renderRemoveBlockCoverages };
