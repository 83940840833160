/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import api from 'api';
import { AiOutlineBarcode } from 'react-icons/ai';
import { Header, SideMenu, Select, Search, Button, Dialog, Input, DataTable } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton, PaymentOption } from './styles';
import { loadingColor, primary, white } from 'styles/colorProvider';
import { HiQrCode } from 'react-icons/hi2';
import { dataColumns } from './helper';
import { formatCurrency } from 'react-data-formatter';
import { toast } from 'react-toastify';
import { FiCopy } from 'react-icons/fi';
import { FaBarcode } from 'react-icons/fa';
import { TiFilter } from 'react-icons/ti';
import { RiFileExcel2Fill } from 'react-icons/ri';
import PartnersData from 'utils/corporateSettings.json';
import ExportToExcel from 'components/ExportToExcel/index';
import { Alert } from '@material-ui/lab';

const dateFilters = {
  created_at: 'Data de criação',
  due_at: 'Data de vencimento',
  paid_at: 'Data de pagamento',
};

function Charges({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [chargesList, setChargesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [open, setOpen] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [exportData, setExportData] = useState();
  const [search, setSearch] = useState();
  const [selectStatus, setSelectStatus] = useState('all');
  const [startData, setStartdata] = useState();
  const [endData, setEndData] = useState();
  const [touchFilter, setTouchFilter] = useState();

  //   const [partnerVendors, setPartnerVendors] = useState([]);
  //   const [selectedPartnerVendor, setSelectedPartnerVendor] = useState('all');

  const [moreVisible, setMoreVisible] = useState(null);
  const [showResponse, setShowResponse] = useState();

  const [loadingPix, setLoadingPix] = useState();
  const [pixResponse, setPixResponse] = useState();

  const [loadingBillet, setLoadingBillet] = useState();
  const [billetResponse, setBilletResponse] = useState();

  const [partner, setPartner] = useState();
  const [dateFilter, setDateFilter] = useState();

  const [dateFilterStartDate, setDateFilterStartDate] = useState();
  const [dateFilterEndDate, setDateFilterEndDate] = useState();

  const [exportErrorMessage, setExportErrorMessage] = useState('');

  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica';

  const loadData = async () => {
    setLoading(true);
    try {
      setCurrentPage(1);
      const resp = await api({
        method: 'GET',
        url: `/charges-list-reports`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        params: {
          search,
          startdate: startData === '' ? undefined : startData,
          enddate: endData === '' ? undefined : endData,
          page: 1,
          take: 20,
        },
        json: true,
      });

      //   const vendorsResp = await api({
      //     method: 'GET',
      //     url: `/list-partner-vendors`,
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: Cookies.get('splitrisk-authtoken'),
      //     },
      //     json: true,
      //   });

      //   setPartnerVendors(vendorsResp?.data);
      setChargesList(resp?.data?.charges);
      setTotalCount(resp?.data?.totalCount);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoading(false);
      setTouchFilter(false);
    } catch (error) {
      if (error.response.status === 401) history.push('/nao-autorizado');
    }
  };

  const handleLoadMore = async () => {
    if (!touchFilter) {
      const page = currentPage + 1;
      if (hasNextPage && !loadingMore) {
        setLoadingMore(true);
        const resp = await api({
          method: 'GET',
          url: `/charges-list-reports`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitrisk-authtoken'),
          },
          params: {
            search,
            startdate: startData === '' ? undefined : startData,
            enddate: endData === '' ? undefined : endData,
            page,
            take: 10,
          },
          json: true,
        });
        const data = chargesList;
        const ndata = data.concat(resp?.data?.charges);
        setCurrentPage(page);
        setChargesList(ndata);
        setHasNextPage(resp?.data?.hasNextPage);
        setLoadingMore(false);
      }
    }
  };

  const handleClear = () => {
    setSelectStatus('all');
    setStartdata('');
    setEndData('');
  };

  const handleExport = async () => {
    setLoadingExport(true);

    setExportErrorMessage('');

    if (!dateFilter) {
      setExportErrorMessage('Selecione um filtro de data');
      setLoadingExport(false);
      return;
    }

    if (!dateFilterStartDate || !dateFilterEndDate) {
      setExportErrorMessage('Informe as datas de inicio e de fim');
      setLoadingExport(false);
      return;
    }

    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!datePattern.test(dateFilterStartDate) || !datePattern.test(dateFilterEndDate)) {
      setExportErrorMessage('Formato de data inválida');
      setLoadingExport(false);
      return;
    }

    const momentStartDate = moment(dateFilterStartDate, 'DD/MM/YYYY');
    const momentEndDate = moment(dateFilterEndDate, 'DD/MM/YYYY');

    if (momentEndDate.isBefore(momentStartDate)) {
      setExportErrorMessage('Data final deve ser maior que a inicial');
      setLoadingExport(false);
      return;
    }

    try {
      const resp = await api({
        method: 'GET',
        url: `/report/export-charges`,
        params: {
          partner,
          fromPortal: true,
          dateFilterColumn: dateFilter,
          startDate: dateFilterStartDate,
          endDate: dateFilterEndDate,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
          feature: 'export-charges',
        },
        json: true,
      });
      setExportData(resp.data);
    } catch (error) {
      toast.error(error?.response?.data?.error || "Não foi possível exportar cobranças", {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setOpenExport(false);
    }
    setLoadingExport(false);
    setDateFilterStartDate();
    setDateFilterEndDate();
    setExportErrorMessage('');
  };

  const handleCreateBillet = async () => {
    setLoadingBillet(true);
    try {
      const response = await api({
        method: 'POST',
        url: isOceanica ? `charge-billet-asaas` : `/charge-billet`,
        data: {
          id_charge: moreVisible?.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      setLoadingBillet(false);
      toast.success('Boleto gerado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setBilletResponse(response.data);
      setShowResponse(true);
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingBillet(false);
    }
  };

  const handleCreatePix = async () => {
    setLoadingPix(true);
    try {
      const resp = await api({
        method: 'POST',
        url: isOceanica ? `charge-pix-asaas` : `/charge-pix`,
        data: {
          id_charge: moreVisible?.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      setPixResponse(resp.data.pix);
      setShowResponse(true);
      setLoadingPix(false);
      toast.success('Código PIX gerado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingPix(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleOpenFilter = () => {
    setOpen(true);
  };

  const handleCopyBillet = () => {
    navigator.clipboard.writeText(billetResponse?.billet?.barcode);
    toast.success('Código de barras copiado com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  };

  const handleCopyPix = () => {
    navigator.clipboard.writeText(pixResponse?.pix_code);
    toast.success('Código PIX copiado com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  };

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loadingExport && setOpenExport(false);
        setDateFilter();
        setExportErrorMessage('');
        setExportData();
      }}
      width="50%"
      height={'100px'}
      title="Exportar cobranças"
    >
      {!exportData && (
        <>
          <div
            style={{
              width: '100%',
            }}
          >
            <Select label="Parceiro" height="35px" value={partner} onChange={(e) => setPartner(e.target.value)}>
              <option value={''} selected={true}>
                Todos os parceiros
              </option>
              {PartnersData?.map((partner) => (
                <option value={partner?.id_partner}>{partner?.name}</option>
              ))}
            </Select>
            <Select
              label="Filtro por data (até 31 dias)"
              height="35px"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            >
              <option value="" selected>
                Nenhum
              </option>
              {Object.keys(dateFilters).map((key) => (
                <option value={key}>{dateFilters[key]}</option>
              ))}
            </Select>
            {dateFilter && (
              <>
                <Input
                  value={dateFilterStartDate}
                  onChange={(e) => setDateFilterStartDate(e.target.value)}
                  widht="30%"
                  label={`${dateFilters[dateFilter]} inicial`}
                  mask="99/99/9999"
                  height="30px"
                />
                <Input
                  value={dateFilterEndDate}
                  onChange={(e) => setDateFilterEndDate(e.target.value)}
                  widht="30%"
                  label={`${dateFilters[dateFilter]} final`}
                  mask="99/99/9999"
                  height="30px"
                />
              </>
            )}
          </div>
          {exportErrorMessage && (
            <Alert severity="error" style={{ width: '90%', height: '30px', marginTop: '15px' }}>
              {exportErrorMessage}
            </Alert>
          )}
          <ModalButton>
            <Button height="35px" style={{ margin: '2rem 0 0 0' }} onClick={handleExport} disabled={loadingExport}>
              {loadingExport ? <ReactLoading color={white} height={25} width={25} type="spin" /> : 'Exportar'}
            </Button>
          </ModalButton>
        </>
      )}

      {exportData?.hasData ? (
        <>
          <p
            style={{
              textAlign: 'center',
              margin: '0 auto',
              marginBottom: '10px',
            }}
          >
            Seu arquivo está pronto!
          </p>
          <ExportToExcel exportData={exportData?.data} fileName={`split_risk_cobranças_${new Date().toLocaleDateString()}`} />
        </>
      ) : (
        <p>{exportData?.message}</p>
      )}
    </Dialog>
  );

  const renderFilterDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} width="50%" title="Filtrar cobrança">
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between', width: '100%' }}>
        <Input
          value={startData}
          onChange={(e) => setStartdata(e.target.value)}
          widht="30%"
          label="Data inicial"
          mask="99/99/9999"
          height="30px"
        />
        <Input
          value={endData}
          onChange={(e) => setEndData(e.target.value)}
          widht="30%"
          label="Data final"
          mask="99/99/9999"
          height="30px"
        />
        <ModalButton>
          <Button
            height="35px"
            outlined
            disabled={selectStatus === 'all' && startData === '' && endData === ''}
            onClick={handleClear}
          >
            Limpar filtros
          </Button>
          <Button
            height="35px"
            onClick={() => {
              setTouchFilter(true);
              loadData();
              setOpen(false);
            }}
          >
            Aplicar alterações
          </Button>
        </ModalButton>
      </div>
    </Dialog>
  );

  const renderMoreDialog = () => (
    <Dialog
      open={moreVisible}
      onClose={() => {
        setMoreVisible();
      }}
      width="50%"
      title="Formas de pagamento"
    >
      <div>
        <p style={{ margin: 0, marginBottom: 5, fontSize: 14 }}>Valor: {formatCurrency(moreVisible?.value || 0)}</p>
        <p style={{ margin: 0, marginBottom: 25, fontSize: 14 }}>
          Vencimento: {moment(moreVisible?.created_at).format('DD/MM/YYYY')}
        </p>
        <PaymentOption
          disabled={moreVisible?.billets?.length < 1}
          onClick={() => moreVisible?.billets?.length > 0 && window.open(moreVisible?.billets[0]?.url)}
          height="30px"
          width="280px"
          style={{ justifyContent: 'flex-start' }}
        >
          <AiOutlineBarcode style={{ marginLeft: 10, marginRight: 10 }} size={18} /> Visualizar boleto
        </PaymentOption>
        <PaymentOption
          disabled={loadingBillet || moreVisible?.billets?.length > 0}
          onClick={handleCreateBillet}
          height="30px"
          width="280px"
          style={{ justifyContent: 'flex-start' }}
        >
          {loadingBillet ? (
            <ReactLoading color={loadingColor} height={20} width={20} type="spin" />
          ) : (
            <>
              <AiOutlineBarcode style={{ marginLeft: 10, marginRight: 10 }} size={18} /> Gerar boleto
            </>
          )}
        </PaymentOption>
        <PaymentOption
          loading={loadingPix}
          onClick={handleCreatePix}
          height="30px"
          width="280px"
          style={{ justifyContent: 'flex-start' }}
        >
          {loadingPix ? (
            <ReactLoading color={loadingColor} height={20} width={20} type="spin" />
          ) : (
            <>
              <HiQrCode style={{ marginLeft: 10, marginRight: 10 }} size={18} /> Gerar código PIX
            </>
          )}
        </PaymentOption>
      </div>
    </Dialog>
  );

  const renderResponseDialog = () => (
    <Dialog
      open={showResponse}
      onClose={() => {
        setShowResponse();
        setPixResponse();
        setBilletResponse();
      }}
      width="50%"
      title="Detalhes da cobrança"
    >
      {pixResponse && (
        <div className="pixArea">
          <h3>Pagar com PIX</h3>
          <small>
            Para realizar o pagamento, copie o código PIX e cole no APP do seu banco. O pagamento pode levar até 30 minutos para
            ser compensado.
          </small>
          <div className="buttonArea">
            <Button height="35px" width="220px" style={{ margin: 0, marginTop: 20 }} onClick={handleCopyPix}>
              <FiCopy style={{ marginRight: 10 }} />
              PIX Copia e cola
            </Button>
          </div>
        </div>
      )}

      {billetResponse && (
        <div className="billetArea">
          <h3>Aqui está o boleto!</h3>
          <div className="buttonArea" style={{ flexDirection: 'column', alignItems: 'center' }}>
            <Button height="35px" width="220px" onClick={() => window.open(billetResponse?.billet?.billet_url, '_blank').focus()}>
              <FaBarcode style={{ marginRight: 10 }} />
              Visualizar boleto
            </Button>
            <Button height="35px" width="220px" onClick={handleCopyBillet}>
              <FiCopy style={{ marginRight: 10 }} />
              Copiar código de barras
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );

  const actionDataColumns = dataColumns;

  return (
    <>
      <Header />
      <Container>
        {renderFilterDialog()}
        {renderExportDialog()}
        {renderMoreDialog()}
        {renderResponseDialog()}
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>COBRANÇAS</Title>
            <div className="containerTitle">
              <Button title="Exportar" height="30px" onClick={() => setOpenExport(true)}>
                <RiFileExcel2Fill size={18} />
              </Button>
              <Button title="Filtrar" height="30px" onClick={handleOpenFilter}>
                <TiFilter size={18} />
              </Button>

              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData();
                }}
                onConfirm={() => loadData()}
              />
            </div>
          </Div>
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <DataTable
                // onRowClicked={(row) => history.push(`/backoffice/detalhes-da-cobranca/${row?.id}`)}
                persistTableHead
                responsive
                columns={actionDataColumns}
                data={chargesList}
                highlightOnHover
                // pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhuma cobrança encontrada</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {chargesList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default Charges;
