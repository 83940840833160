/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';

import InfiniteScroll from 'react-infinite-scroller';
import api from 'api';

import { Header, ComplaintItem, Button, BackofficeMenu } from 'components';
import { Container, ContentArea, DataArea, LoadingArea, CountArea, AreaButton } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';

function Complaint({ location, history }) {
  const policyId = parseInt(location.pathname.split('/backoffice/historico-reclamacao/')[1]);

  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [policyData, setPolicyData] = useState({});
  const [complaintsList, setComplaintsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/policy-complaints/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      params: {
        page: currentPage,
        take: 10,
      },
      json: true,
    });

    setComplaintsList(resp?.data?.complaints);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);

      const resp = await api({
        method: 'GET',
        url: `/policy-complaints/${policyId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        params: {
          page,
          take: 10,
        },
        json: true,
      });

      const data = complaintsList;
      const ndata = data.concat(resp?.data?.complaints);

      setCurrentPage(page);
      setComplaintsList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  const policyIdData = async () => {
    const policyNumber = {
      method: 'GET',
      url: `/company-policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(policyNumber);

    setPolicyData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    policyIdData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <ContentArea>
          <BackofficeMenu policyId={policyId} />

          {policyData?.status === 'active' && (
            <AreaButton>
              <Button
                style={{ margin: 0, width: '180px' }}
                height="30px"
                onClick={() => history.push(`/backoffice/abrir-reclamacao/${policyId}`)}
              >
                Nova reclamação
              </Button>
            </AreaButton>
          )}

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading && complaintsList?.map((complaint) => <ComplaintItem complaint={complaint} />)}
              </div>
            </InfiniteScroll>

            {!loading && !complaintsList?.length && <p>Nenhuma reclamação encontrada</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {complaintsList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default Complaint;
