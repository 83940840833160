/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';

import moment from 'moment';

import api from 'api';
import { ImDownload3 } from 'react-icons/im';
import { Header, SideMenu, Button, Dialog } from 'components';
import { Container, ContentArea, Title, Div } from './styles';
import { toast } from 'react-toastify';
import { secondary } from 'styles/colorProvider';
import ExportToExcel from 'components/ExportToExcel/index';

function DownloadReports() {
  const [loading, setLoading] = useState(false);
  const [exportData, setExportData] = useState();
  const [openExport, setOpenExport] = useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'GET',
        url: `/report/care-export`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
          feature: 'care-export',
        },
        json: true,
      });

      setExportData(resp?.data);
      setOpenExport(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error || "Não foi possivel baixar base atualizada", {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const filename = `Planilha_Importacao_Padrao - SPLIT RISK SEGURADORA (${moment().format('DD-MM-YYYY')}).csv`;

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loading && setOpenExport(false);

        setExportData();
      }}
      width="50%"
      title="Exportar base"
    >
      {exportData?.hasData ? (
        <>
          <p style={{ margin: 0 }}>Seu arquivo está pronto!</p>
          <small style={{ margin: 0, marginBottom: 20 }}>{exportData?.data?.length} registros encontrados.</small>
          <ExportToExcel exportData={exportData?.data} fileName={filename} />
        </>
      ) : (
        <p>{exportData?.message}</p>
      )}
    </Dialog>
  );

  return (
    <>
      {renderExportDialog()}
      <Header />
      <Container>
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>Baixar Relatórios</Title>
          </Div>
          <Button width="280px" onClick={loadData} disabled={loading}>
            {loading ? (
              <ReactLoading color="#fff" height={25} width={25} type="spin" />
            ) : (
              <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ImDownload3 style={{ marginRight: 5 }} /> Baixar base atualizada
              </span>
            )}
          </Button>
        </ContentArea>
      </Container>
    </>
  );
}

export default DownloadReports;
