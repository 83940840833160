import React, { useState } from 'react';
import Cookies from 'js-cookie';
import api from 'api';
import ReactLoading from 'react-loading';
import { Container, LeftBox, RightBox, Form, BackToLogin, ResetPassword } from './styles';
import { Button, Input } from 'components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { white, middleGray } from 'styles/colorProvider';

function PasswordRecovery() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [click, setClick] = useState(false);

  const handlePasswordRecovery = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post('/request-recovery-code', { email });
      setLoading(false);
      setClick(true);
      toast.success('Código enviado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post('/reset-password-recovery', { recoveryCode: code, password: newPassword });
      const { token } = response?.data;
      setLoading(false);
      Cookies.set('splitrisk-authtoken', token);
      history.push('/minhas-apolices');
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  return (
    <Container>
      <LeftBox>
        <div />
      </LeftBox>
      <RightBox>
        <Form>
          <div className="areaLogo">
            <div className="logo" />
          </div>

          <ResetPassword>
            {click ? (
              <div className="description">
                <h2>Recuperar senha</h2>
                <p style={{ textAlign: 'center', fontSize: 14, color: middleGray, marginTop: 0, marginBottom: '3%' }}>
                  Insira o código que enviamos por e-mail e crie uma nova senha.
                </p>

                <Input
                  label="Código"
                  placeholder="Digite o código de recuperação"
                  value={code}
                  mask={'999999'}
                  onChange={(e) => setCode(e.target.value)}
                />
                <Input
                  label="Nova senha"
                  placeholder="Digite uma nova senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  password
                  type="password"
                />

                <div className="buttonArea">
                  <Button onClick={handleChangePassword} disabled={loading || !code || !newPassword || newPassword.length < 6}>
                    {loading ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Alterar'}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="description">
                <h2>Recuperar senha</h2>
                <p style={{ textAlign: 'center', fontSize: 14, color: middleGray, marginTop: 0, marginBottom: '20px' }}>
                  Insira o e-mail utilizado na sua conta.
                </p>

                <Input label="E-mail" placeholder="Digite seu e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />

                <div className="buttonArea">
                  <Button onClick={handlePasswordRecovery} disabled={loading || !email}>
                    {loading ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Recuperar'}
                  </Button>
                </div>
              </div>
            )}
          </ResetPassword>
        </Form>
        <BackToLogin onClick={() => history.push('/')}>Voltar para login</BackToLogin>
      </RightBox>
    </Container>
  );
}

export default PasswordRecovery;
