import { MdChevronLeft } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { Container, ItemMenu, TextItem } from './styles';

function AgentMenu() {
    const history = useHistory();

    return (
        <Container>
            <ItemMenu
                onClick={() => {
                    history.push('/backoffice/corretores');
                }}
            >
                <MdChevronLeft size="18" />
                <TextItem>Corretores</TextItem>
            </ItemMenu>
        </Container>
    );
}

export default AgentMenu;
