/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ReactLoading from 'react-loading';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import api from 'api';
import IconButton from '@material-ui/core/IconButton';
import Cookies from 'js-cookie';
import { formatCPF, formatPlate } from 'react-data-formatter';
import { MdChevronLeft } from 'react-icons/md';
import { success } from 'styles/colorProvider';
import { useHistory } from 'react-router-dom';
import { white } from 'styles/colorProvider';
import { Header, Button, DropDown } from 'components';
import { toast } from 'react-toastify';
import {
  ButtonArea,
  Container,
  ContentArea,
  Title,
  PolicyInformationArea,
  ItemPolicy,
  Subtitle,
  Text,
  LoadingArea,
  TextArea,
} from './styles';

function NewComplaint({ location }) {
  const policyId = parseInt(location.pathname.split('/backoffice/abrir-reclamacao/')[1]);

  const [policyData, setPolicyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [motive, setMotive] = useState();
  const history = useHistory();

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/company-policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });

    setPolicyData(resp?.data);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    setLoadingCreate(true);
    try {
      const createdComplaint = await api({
        method: 'POST',
        url: `/create-complaint-history`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id_policy: policyId,
          id_type_reclamation: parseInt(motive),
          description: textInput,
          type: 'created',
        },

        json: true,
      });

      setLoadingCreate(false);
      history.push(`/backoffice/detalhes-da-apolice/${policyId}`);
      toast.success('Reclamação criada!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (e) {
      setLoadingCreate(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={success} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <Title>
              <IconButton
                size="small"
                style={{ marginRight: 10 }}
                onClick={() => history.push(`/backoffice/historico-reclamacao/${policyId}`)}
              >
                <MdChevronLeft size={24} />
              </IconButton>
              RECLAMAÇÃO
            </Title>
            <Subtitle style={{ marginTop: '1.5%' }}>Motivo da reclamação</Subtitle>
            <PolicyInformationArea>
              <DropDown value={motive} onChange={(e) => setMotive(e.target.value)} />

              <ItemPolicy>
                <Subtitle>Informações do segurado</Subtitle>
                <Text>
                  Nome do segurado: <span>{policyData?.user?.name}</span>
                </Text>
                <Text>
                  CPF: <span>{formatCPF(policyData?.user?.cpf)}</span>
                </Text>
                <Text>
                  Data de nascimento: <span>{moment(policyData?.user?.birthdate).format('DD/MM/YYYY')}</span>
                </Text>
              </ItemPolicy>

              <TextArea>
                <Subtitle>Descreva a reclamação</Subtitle>
                <TextareaAutosize
                  minRows={1}
                  placeholder="Descreva o motivo..."
                  onChange={(e) => setTextInput(e.target.value)}
                  value={textInput}
                  style={{
                    padding: 10,
                    fontFamily: 'Nunito',
                    fontSize: 14,
                    marginTop: '3%',
                    width: '100%',
                    height: '150px',
                    borderRadius: 5,
                    borderColor: '#d1d2d4',
                  }}
                />
              </TextArea>

              <ItemPolicy>
                <Subtitle>Informações do veículo</Subtitle>
                <Text>
                  Marca e modelo: <span>{`${policyData?.vehicle?.brand}/ ${policyData?.vehicle?.model}`}</span>
                </Text>
                <Text>
                  Placa: <span>{formatPlate(policyData?.vehicle?.plate)}</span>
                </Text>
                <Text>
                  Ano e modelo: <span>{`${policyData?.vehicle?.manufacture_year} / ${policyData?.vehicle?.year_model}`}</span>
                </Text>
              </ItemPolicy>
            </PolicyInformationArea>

            <ButtonArea>
              <Button onClick={handleSubmit} disabled={loadingCreate || !motive || !textInput}>
                {loadingCreate ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Salvar reclamação'}
              </Button>
            </ButtonArea>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default NewComplaint;
