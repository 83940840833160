import React from 'react';

export default function Contract() {
  return (
    <div>
      <p>
      Fizemos o máximo para explicar de forma clara e simples quais dados pessoais precisaremos de você e o que 
      vamos fazer com cada um deles. Por isso, separamos abaixo os pontos mais importantes, que também podem ser 
      lidos de forma bem completa e detalhada nas nossas Políticas de Privacidade .
      Além disso, estamos sempre disponíveis para tirar qualquer dúvida que você tenha por meio do e-mail 
      contato@splitrisk.com.br, Instagram ou pelo Linkedin .
      </p>
      <p>
      1) Como faremos a segurança de seus dados? Para garantir a sua segurança, seus dados pessoais são transferidos 
      de forma criptografada e armazenados em servidores AWS Amazon . Além disso, utilizamos de uma variedade de 
      tecnologias e procedimentos de segurança para ajudar a proteger as informações dos Usuários. Com isso, essas 
      empresas passam a ter acesso aos seus dados, somente para armazená-los, assim que você os fornece à SplitRisk. 
      Se você tiver algum problema com isso, pedimos que não continue utilizando o site. 
      </p>
      <p>
      2) Quais dados precisa nos informar em nosso site? Para o cadastro e utilização dos serviços da SplitRisk 
      serão requeridas as seguintes informações: nome, e-mail, número do WhatsApp, CEP e a placa do veículo.
      </p>
      <p>
      3) Com quem compartilhamos seus dados pessoais?
      Nós iremos compartilhar os seus dados pessoais com as empresas referenciadas nestas políticas, com o objetivo 
      de aprimoramento e execução dos serviços da SplitRisk. Salvo nos casos citados, em caso de consentimento legal 
      do titular dos dados pessoais e por força de ordem judicial ou determinação legal, nós não iremos compartilhar 
      seus dados com terceiros.
      </p>
      <p>
      4) Seus registros de acesso serão coletados? Quando você entra em nosso site, colhemos seus registros de acesso, ou seja, 
      conjunto de informações referentes à data e hora de uso de uma determinada aplicação de internet a partir de 
      um determinado endereço IP, estas informações serão mantidas pela SplitRisk, sob sigilo, em ambiente controlado 
      e de segurança, pelo prazo mínimo de 06 (seis) meses, nos termos da Lei n° 12.965/2014, e artigo 7º, II, da 
      Lei 13709/18.
      </p>
      <p>
      5) Existe coleta indireta de dados? Além dos registros de acesso, podemos coletar informações de forma 
      indireta por meio de cookies, conforme o descrito nas Políticas de Privacidade. 
      Alguns destes cookies são indispensáveis para o funcionamento do site.
      </p>
      <p>
      6) Registros de comunicações serão armazenados? Nós vamos armazenar também todas as conversas que você tiver 
      conosco em nossos canais de comunicação, pois isso irá melhorar o seu atendimento e torná-lo mais eficiente. 
      Sem esse histórico, provavelmente todas as vezes que você utilizasse o canal teria que repetir o que já nos 
      passou anteriormente.
      </p>
      <p>
      7) Como seus dados serão tratados? Todos os seus dados são tratados com finalidades específicas e de acordo 
      com a Lei de Proteção de Dados Pessoais. Todas essas informações estão descritas em uma tabela, para 
      facilitar a sua visualização, nas nossas Políticas de Privacidade.
      </p>
      <p>
      8) Quais são seus direitos? Mesmo que você já tenha nos fornecido seus dados pessoais, você possui total direito de, a qualquer momento: confirmar a 
      existência de tratamento dos seus dados; acessar os seus dados; corrigir os seus dados; tornar anônimo os 
      dados; bloquear ou eliminar os dados desnecessários, excessivos ou tratados em desconformidade com a Lei; 
      pedir a portabilidade dos dados a outro fornecedor; eliminar dados, exceto aqueles exigidos por lei; obter 
      informação sobre quem a SplitRisk realizou uso compartilhado de dados; obter informação sobre a possibilidade de não fornecer consentimento e sobre 
      as consequências da negativa; e voltar atrás e revogar o seu consentimento.
      </p>
      <p>
      9) Essas condições poderão mudar? Nossa Política de Privacidade poderá mudar, mas você sempre poderá acessar a versão mais atualizada em nosso site. Além disso, se formos realizar alguma ação 
      que a lei exija sua autorização, você receberá um aviso antes para poder aceitar ou recusar.
      </p>
      <p>
      10) Qual é o conteúdo das Políticas de Privacidade? Este site, cujo endereço é https://splitrisk.com.br/, é de propriedade, mantida e operada por Split Risk Seguradora S.A. (“SplitRisk”), sociedade empresária 
      constituída na forma de Sociedade Limitada, inscrita no CNPJ sob o n° 43.505.273/0001-09, com endereço 
      na AV Gabriela Junqueira de Freitas, nº 392, Bairro dos Eucaliptos, CEP: 38.414-126, na cidade de Uberlândia, 
      no estado de Minas Gerais. O presente documento visa prestar informações sobre a coleta, utilização, tratamento e 
      armazenamento dos dados fornecidos pelos Usuários e está em conformidade com a Lei 12.965/2014 (Marco Civil da Internet) e com a Lei nº 13.709/18 (Lei Geral de Proteção de Dados).
        
        <p>10.1 A presente versão deste documento foi disponibilizada em: 04/10/2021.</p>

      </p>

      <p>10.2. Explicação dos termos técnicos ou língua estrangeira</p>

      <p>10.2.1. Abaixo estão dispostos os significados das nomenclaturas técnicas e termos na língua inglesa. Controlador: Pessoa natural ou jurídica, de direito 
        público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais; Cookies: Pequenos arquivos de texto que ficam gravados no 
        computador do internauta e podem ser recuperados pelo site que o enviou durante a navegação. São utilizados 
        principalmente para identificar e armazenar informações sobre os visitantes. Criptografia: Conjunto de princípios e técnicas para cifrar a escrita, 
        torná-la ininteligível para os que não tenham acesso às convenções combinadas. Dado pessoal: Informação relacionada a pessoa natural identificada ou identificável;
        Dado pessoal sensível: Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, 
        filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado 
        a uma pessoa natural; Encarregado: Pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a 
        Autoridade Nacional de Proteção de Dados (ANPD). IP (ou Internet Protocol): Identificação única para cada computador conectado a uma rede.
        Operador: Pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador;
        Tratamento de dados: Toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
      </p>
      <p>
      11) Encarregado e disposições gerais: A SplitRisk indica como encarregado o Sr. Rodrigo Bianchini, com endereço eletrônico rodrigo.bianchini@splitrisk.com.br, 
      nos termos do art. 41 da Lei Geral de Proteção de Dados, para aceitar reclamações e comunicações dos titulares 
      e da Autoridade Nacional de Proteção de Dados, prestar esclarecimentos e adotar providências. A SplitRisk dispõe de um texto específico para regular a licença de uso, os direitos, deveres, garantias e disposições gerais: os Termos de Uso. Todos esses documentos integram inseparavelmente estas Políticas de Privacidade.
      </p>
      <p>
      12) A SplitRisk disponibiliza os seguintes canais para receber todas as comunicações que os Usuários desejarem fazer: 
      por meio do e-mail contato@splitrisk.com.br, Instagram ou pelo Linkedin .
      </p>
    </div>
  );
}
