/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Header, ProposalMenu, Button } from 'components';
import {
  Container,
  ContentArea,
  Title,
  PolicyInformationArea,
  ItemPolicy,
  Subtitle,
  Text,
  LoadingArea,
  ContractBox,
} from './styles';
import { BsShieldFillExclamation } from 'react-icons/bs';
import { Alert } from '@material-ui/lab';
import { formatCPF, formatPlate, formatCurrency } from 'react-data-formatter';
import { formatPolicyStatus } from 'utils/policyStatus';
import moment from 'moment';
import MaterialButton from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import api from 'api';
import ReactLoading from 'react-loading';
import Contract from './contract';
import { loadingColor, warn } from 'styles/colorProvider';
import momentBD from 'utils/momentBusinessDays';
import { cpf } from 'cpf-cnpj-validator';

function ProposalDetails({ history, location }) {
  const proposalId = parseInt(location.pathname.split('/detalhes-da-proposta/')[1]);
  const iof = 0.0738;

  const [proposalData, setProposalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const proposalIdData = async () => {
    const proposalNumber = {
      method: 'GET',
      url: `/proposal/${proposalId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };

    setLoading(true);
    const response = await api(proposalNumber);

    setProposalData(response.data);
    setLoading(false);
  };

  useEffect(() => proposalIdData(), []);

  const userBetween18and25Years = proposalData?.TB_QUOTATION_PROPOSAL?.[0]?.some_driver_18_25_years_old;
  const isCpf = cpf.isValid(proposalData?.user?.cpf);

  return (
    <>
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && step === 1 && (
          <ContentArea>
            <ContractBox>
              <h1>Bem-vindo(a) à Split Risk!</h1>
              <div className="contractArea">
                <Contract />
              </div>
              <Button height="35px" width="250px" onClick={() => setStep(2)}>
                Aceitar contrato e continuar
              </Button>
            </ContractBox>
          </ContentArea>
        )}
        {!loading && step === 2 && (
          <ContentArea>
            <ProposalMenu proposalId={proposalId} />
            <Title>DETALHES DA PROPOSTA</Title>
            <Alert
              style={{ marginBottom: 20 }}
              severity="warning"
              action={
                <MaterialButton
                  color="inherit"
                  size="small"
                  onClick={() => history.push(`/pagamento/${proposalData?.charge?.id}`)}
                >
                  Realizar pagamento
                </MaterialButton>
              }
            >
              Falta pouco para você realizar o pagamento do seu seguro!
            </Alert>

            <PolicyInformationArea>
              <ItemPolicy>
                <Subtitle style={{ color: warn, textTransform: 'uppercase', marginBottom: 10 }}>
                  <BsShieldFillExclamation size={16} style={{ marginRight: 5 }} /> Realize o pagamento do seu seguro!
                </Subtitle>

                <Text>{formatPolicyStatus(proposalData?.status)?.label}</Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Primeiro pagamento até</Subtitle>
                <Text>{momentBD(proposalData?.accepted_at).businessAdd(3, 'day').format('DD/MM/YYYY')}</Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do segurado</Subtitle>
                <Text>
                  Nome do segurado: <span>{proposalData?.user?.name}</span>
                </Text>
                <Text>
                  CPF: <span>{formatCPF(proposalData?.user?.cpf)}</span>
                </Text>
                {isCpf && (
                  <Text>
                    Data de nascimento: <span>{moment.utc(proposalData?.user?.birthdate).format('DD/MM/YYYY')}</span>
                  </Text>
                )}
                <Text>
                  Endereço:
                  <br />
                  {' - '} Estado:<span>{proposalData?.address?.state}</span>
                  <br />
                  {' - '} Cidade:<span>{proposalData?.address?.city}</span>
                </Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Informações do veículo</Subtitle>
                <Text>
                  Marca e modelo:{' '}
                  <span>{`${proposalData?.vehicle?.TB_CONSTANT_VEHICLE_BRANDS?.name} ${proposalData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.name}`}</span>
                </Text>
                <Text>
                  Placa:{' '}
                  <span>
                    {proposalData?.vehicle?.plate !== null || undefined ? formatPlate(proposalData?.vehicle?.plate) : 'Sem placa'}
                  </span>
                </Text>
                <Text>
                  Ano/modelo: <span>{`${proposalData?.vehicle?.manufacture_year}/${proposalData?.vehicle?.year_model}`}</span>
                </Text>
                <Text>
                  Uso do veículo: <span>{`${proposalData?.vehicle?.vehicle_use?.name}`}</span>
                </Text>
                <Text>
                  Cor do veículo: <span>{proposalData?.vehicle?.TB_CONSTANT_VEHICLE_COLOR?.name}</span>
                </Text>
                <Text>
                  Veículo de leilão: <span>{proposalData?.vehicle?.auction ? 'SIM' : 'NÃO'}</span>
                </Text>
                <Text>
                  Chassis Remarcado/Veiculo Recuperado de Sinistro: <span>{proposalData?.vehicle?.remarked ? 'SIM' : 'NÃO'}</span>
                </Text>
                <Text>
                  GNV: <span>{proposalData?.vehicle?.gnv ? 'SIM' : 'NÃO'}</span>
                </Text>
                <Text>
                  Condutor entre 18 e 25 anos: <span>{userBetween18and25Years ? 'SIM' : 'NÃO'}</span>
                </Text>
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Coberturas</Subtitle>
                {!!proposalData?.coverages?.length && proposalData?.coverages.map((product) => <Text>• {product.name} </Text>)}
              </ItemPolicy>

              <ItemPolicy>
                <Subtitle>Valor cobrado mensamente</Subtitle>
                <Text>
                  Valor do seguro: {proposalData?.value && formatCurrency(proposalData?.value - proposalData?.value * iof)}
                </Text>
                <Text>IOF: {proposalData?.value && formatCurrency(proposalData?.value * iof)}</Text>
                <Text>
                  Mensalidade:{' '}
                  {proposalData?.value_final
                    ? formatCurrency(proposalData?.value_final)
                    : proposalData?.value
                      ? formatCurrency(proposalData?.value * (1 - (proposalData?.proposal?.discount_new || 0)))
                      : 0}
                </Text>
              </ItemPolicy>
            </PolicyInformationArea>
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default ProposalDetails;
