/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';

import moment from 'moment';

import api from 'api';
import { ImDownload3 } from 'react-icons/im';
import { Header, SideMenu, Select, Button, Dialog, Input } from 'components';
import { Container, ContentArea, Title, Div, ModalButton } from './styles';
import { toast } from 'react-toastify';
import { white } from 'styles/colorProvider';
import ExportToExcel from 'components/ExportToExcel/index';
import { Alert } from '@material-ui/lab';

const dateFilters = {
  created_at: 'Data de criação',
  due_at: 'Data de vencimento',
  paid_at: 'Data de pagamento',
};

function ExportPremiumServices() {
  const [exportData, setExportData] = useState();
  const [openExport, setOpenExport] = useState(false);
  const [dateFilter, setDateFilter] = useState('created_at');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingExport, setLoadingExport] = useState(false);

  const [exportErrorMessage, setExportErrorMessage] = useState('');

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loadingExport && setOpenExport(false);
        setExportErrorMessage('');
        setExportData();
      }}
      width="50%"
      height={'100px'}
      title="Exportar cobranças"
    >
      {!exportData && (
        <>
          <div
            style={{
              width: '100%',
            }}
          >
            <Select
              label="Filtro por data (até 93 dias)"
              height="35px"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            >
              {Object.keys(dateFilters).map((key) => (
                <option value={key}>{dateFilters[key]}</option>
              ))}
            </Select>
            {dateFilter && (
              <>
                <Input
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  widht="30%"
                  label={`${dateFilters[dateFilter]} inicial`}
                  mask="99/99/9999"
                  height="30px"
                />
                <Input
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  widht="30%"
                  label={`${dateFilters[dateFilter]} final`}
                  mask="99/99/9999"
                  height="30px"
                />
              </>
            )}
          </div>
          {exportErrorMessage && (
            <Alert severity="error" style={{ width: '90%', height: '30px', marginTop: '15px' }}>
              {exportErrorMessage}
            </Alert>
          )}
          <ModalButton>
            <Button height="35px" style={{ margin: '2rem 0 0 0' }} onClick={handleExport} disabled={loadingExport}>
              {loadingExport ? <ReactLoading color={white} height={25} width={25} type="spin" /> : 'Exportar'}
            </Button>
          </ModalButton>
        </>
      )}

      {exportData?.hasData ? (
        <>
          <p
            style={{
              textAlign: 'center',
              margin: '0 auto',
              marginBottom: '10px',
            }}
          >
            Seu arquivo está pronto!
          </p>
          <ExportToExcel
            exportData={exportData?.data}
            fileName={`split_risk_premio_servicos_${new Date().toLocaleDateString()}`}
          />
        </>
      ) : (
        <p>{exportData?.message}</p>
      )}
    </Dialog>
  );

  const handleExport = async () => {
    setLoadingExport(true);

    setExportErrorMessage('');

    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

    const isStartDateValid = !startDate || datePattern.test(startDate);
    const isEndDateValid = !endDate || datePattern.test(endDate);

    if (!startDate || !endDate) {
      setExportErrorMessage('Informe as datas de inicio e de fim');
      setLoadingExport(false);
      return;
    }

    if (!isStartDateValid || !isEndDateValid) {
      setExportErrorMessage('Formato de data inválida');
      setLoadingExport(false);
      return;
    }

    const momentStartDate = moment(startDate, 'DD/MM/YYYY');
    const momentEndDate = moment(endDate, 'DD/MM/YYYY');

    if (momentEndDate.isBefore(momentStartDate)) {
      setExportErrorMessage('Data final deve ser maior que a inicial');
      setLoadingExport(false);
      return;
    }

    try {
      const resp = await api({
        method: 'GET',
        url: `/report/export-premium-services`,
        params: {
          dateFilterColumn: dateFilter,
          startDate: startDate,
          endDate: endDate,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
          feature: 'export-premium-services',
        },
        json: true,
      });
      setExportData(resp.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Não foi possível exportar cobranças', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setOpenExport(false);
    }
    setLoadingExport(false);
    setStartDate();
    setEndDate();
    setExportErrorMessage('');
  };

  return (
    <>
      {renderExportDialog()}
      <Header />
      <Container>
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>Prêmio x Serviços</Title>
          </Div>
          <Button width="280px" onClick={() => setOpenExport(true)} disabled={loadingExport}>
            {loadingExport ? (
              <ReactLoading color="#fff" height={25} width={25} type="spin" />
            ) : (
              <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ImDownload3 style={{ marginRight: 5 }} /> Baixar relatório
              </span>
            )}
          </Button>
        </ContentArea>
      </Container>
    </>
  );
}

export default ExportPremiumServices;
