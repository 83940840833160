import React from 'react';
import { Container, Input } from './styles.js';
import { IoClose, IoSearch } from 'react-icons/io5';

function Search({ value, onChange, onClear, onConfirm, placeholder }) {
  return (
    <Container>
      <Input
        value={value}
        onChange={onChange}
        type="text"
        placeholder={placeholder || 'Buscar...'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onConfirm();
          if (e.key === 'Escape') onClear();
        }}
      />
      <button onClick={onConfirm}>
        <IoSearch />
      </button>
      <button onClick={onClear}>
        <IoClose />
      </button>
    </Container>
  );
}

export default Search;
