import { Header, SideMenu, Select, Search, Button, Dialog, Input } from 'components';
import { SliderContent } from '../Partners/styles';
import { Switch, FormControlLabel, withStyles, Checkbox } from '@material-ui/core';
import { loadingColor, primary } from 'styles/colorProvider';
import { DivCenter, ModalButton, ModalError, RemoveButton } from './styles';
import ReactLoading from 'react-loading';
import { ConfirmDialog } from 'components/index';
import { createCoverages, handleRemoveCoverages, updateCoverages } from './requests';
import { DEFAULT_COVERAGES_INFO_VALUES } from '../../../../utils/backofficeValues';

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '12px', // Defina o tamanho da fonte desejado aqui
  },
})(FormControlLabel);

const renderEditCoverages = ({
  openModalEdit,
  setOpenModalEdit,
  loading,
  setLoading,
  loadData,
  setCurrentPage,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  loadingDataTable,
  setLoadingDataTable,
  setCoverageList,
  coverageType,
  coverageInfo,
  setCoverageInfo,
  coveragesConfig,
}) => {
  console.log({ coverageInfo });

  if (!coverageType) return <></>;
  const config = coveragesConfig.find((item) => item.type === coverageType);
  if (!config?.editParams) return <></>;

  const components = [];

  config?.editParams?.forEach((item) => {
    // Switch case returning component

    switch (item) {
      case 'enabled': {
        components.push(
          <CustomFormControlLabel
            control={
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
                style={{ color: primary }}
                checked={coverageInfo.enabled}
                onChange={(e) => {
                  setCoverageInfo({
                    ...coverageInfo,
                    enabled: e.target.checked,
                  });
                }}
              />
            }
            label="Regra ativa?"
          />
        );

        break;
      }
      case 'percent': {
        components.push(
          <>
            <p
              style={{
                fontSize: '12px',
              }}
            >
              Porcentagem
            </p>
            <div
              style={{
                display: 'flex',
                margin: '0 auto',
                width: '95%',
              }}
            >
              <SliderContent
                track={false}
                aria-labelledby="track-false-slider"
                valueLabelDisplay="auto"
                valueLabelFormat={(e) => {
                  return `${Math.round(e)}%`;
                }}
                getAriaValueText={(value) => `${value}`}
                defaultValue={coverageInfo.percent || 0}
                value={coverageInfo.percent}
                onChange={(event) => {
                  setCoverageInfo({
                    ...coverageInfo,
                    percent: event.target.value,
                  });
                }}
                min={0}
                max={100}
                step={1}
                marks={[
                  {
                    value: 0,
                    label: `0%`,
                  },
                  {
                    value: 100,
                    label: `100%`,
                  },
                ]}
              />
            </div>
          </>
        );

        break;
      }
      case 'percent_value': {
        components.push(
          <>
            <p
              style={{
                fontSize: '12px',
              }}
            >
              Porcentagem
            </p>
            <div
              style={{
                display: 'flex',
                margin: '0 auto',
                width: '95%',
              }}
            >
              <SliderContent
                track={false}
                aria-labelledby="track-false-slider"
                valueLabelDisplay="auto"
                valueLabelFormat={(e) => {
                  return `${Math.round(e)}%`;
                }}
                getAriaValueText={(value) => `${value}`}
                defaultValue={coverageInfo.percent || 0}
                value={coverageInfo.percent}
                onChange={(event) => {
                  setCoverageInfo({
                    ...coverageInfo,
                    percent_value: event.target.value,
                  });
                }}
                min={0}
                max={100}
                step={1}
                marks={[
                  {
                    value: 0,
                    label: `0%`,
                  },
                  {
                    value: 100,
                    label: `100%`,
                  },
                ]}
              />
            </div>
          </>
        );

        break;
      }
      case 'type': {
        components.push(
          <Select
            label="Tipo de cobertura"
            height="35px"
            value={coverageInfo?.type}
            onChange={(e) => setCoverageInfo({ ...coverageInfo, type: e.target.value })}
          >
            <option value="" disabled selected>
              Selecione o tipo
            </option>
            <option value="add">Adicionar</option>
            <option value="remove">Remover</option>
          </Select>
        );

        break;
      }
      case 'value': {
        components.push(
          <Input
            style={{ margin: 0 }}
            height="30px"
            label="Valor"
            numeric
            price={true}
            value={coverageInfo?.value}
            onChange={(e) => setCoverageInfo({ ...coverageInfo, value: e.target.value })}
          />
        );

        break;
      }
      default:
        break;
    }
  });

  return (
    <Dialog
      open={openModalEdit}
      onClose={() => {
        setOpenModalEdit(false);
        setCoverageInfo(DEFAULT_COVERAGES_INFO_VALUES);
      }}
      width="100%"
      title="Editar trava"
    >
      {components}

      <ModalButton>
        {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

        <Button
          height="35px"
          onClick={() => {
            updateCoverages({
              setLoading,
              setOpenModalEdit,
              loadData,
              setCurrentPage,
              setCoverageList,
              setHasNextPage,
              setTotalCount,
              setTouchFilter,
              loadingDataTable,
              setLoadingDataTable,
              coverageType,
              coverageInfo,
              setCoverageInfo,
            });
          }}
          disabled={loading}
        >
          Editar
        </Button>
      </ModalButton>
    </Dialog>
  );

  // return (
  // <Dialog
  //   open={openModalEdit}
  //   onClose={() => {
  //     setOpenModalEdit(false);
  //     setCoverageInfo(DEFAULT_COVERAGES_INFO_VALUES);
  //   }}
  //   width="100%"
  //   title="Editar trava"
  // >
  // <p
  //   style={{
  //     fontSize: '12px',
  //   }}
  // >
  //   Comissão
  // </p>
  // <div
  //   style={{
  //     display: 'flex',
  //     margin: '0 auto',
  //     width: '95%',
  //   }}
  // >
  //   <SliderContent
  //     track={false}
  //     aria-labelledby="track-false-slider"
  //     valueLabelDisplay="auto"
  //     valueLabelFormat={(e) => {
  //       return `${Math.round(e)}%`;
  //     }}
  //     getAriaValueText={(value) => `${value}`}
  //     // defaultValue={grievanceValue || 0}
  //     // defaultValue={userPartnerCommissionInfo.percent || 0}
  //     // value={userPartnerCommissionInfo.percent}
  //     // onChange={(event) => {
  //     //   setUserPartnerCommissionInfo({
  //     //     ...userPartnerCommissionInfo,
  //     //     percent: event.target.value,
  //     //   });
  //     // }}
  //     onChangeCommitted={() => {
  //       // setDiscountValue(0);
  //       // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
  //     }}
  //     min={0}
  //     // max={userPartnerInfo.commercialization - userPartnerInfo.loading}
  //     step={1}
  //     // marks={[
  //     //   {
  //     //     value: 0,
  //     //     label: `0%`,
  //     //   },
  //     //   {
  //     //     value: userPartnerInfo.commercialization - userPartnerInfo.loading,
  //     //     label: `${userPartnerInfo.commercialization - userPartnerInfo.loading}%`,
  //     //   },
  //     // ]}
  //   />
  // </div>

  // <CustomFormControlLabel
  //   control={
  //     <Checkbox
  //       inputProps={{ 'aria-label': 'controlled' }}
  //       size="small"
  //       style={{ color: primary }}
  //       // checked={userPartnerCommissionInfo.enabled}
  //       // onChange={(e) => {
  //       //   setUserPartnerCommissionInfo({
  //       //     ...userPartnerCommissionInfo,
  //       //     enabled: e.target.checked,
  //       //   });
  //       // }}
  //     />
  //   }
  //   label="Regra ativa?"
  // />

  // <ModalButton>
  //   {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

  //   <Button
  //     height="35px"
  //     onClick={() => {
  //       updateCoverages({
  //         setLoading,
  //         setOpenModalEdit,
  //         loadData,
  //         setCurrentPage,
  //         setCoverageList,
  //         setHasNextPage,
  //         setTotalCount,
  //         setTouchFilter,
  //         loadingDataTable,
  //         setLoadingDataTable,
  //         coverageType,
  //         coverageInfo,
  //         setCoverageInfo,
  //       });
  //     }}
  //     disabled={loading}
  //   >
  //     Editar
  //   </Button>
  // </ModalButton>
  //   </Dialog>
  // );
};

const renderAddCoverages = ({
  openModalAdd,
  setOpenModalAdd,
  userPartners,
  userPartnerCommissionInfo,
  setUserPartnerCommissionInfo,
  loading,
  setLoading,
  userPartnerInfo,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
  setCoverageList,
  coverageType,
  coverageInfo,
  setCoverageInfo,
  coveragesConfig,
}) => (
  <Dialog
    open={openModalAdd}
    onClose={() => {
      setOpenModalAdd(false);
      setCoverageInfo(DEFAULT_COVERAGES_INFO_VALUES);
    }}
    width="100%"
    title="Adicionar trava"
  >
    <Select
      height="30px"
      label="Escolher o cliente"
      // value={userPartnerCommissionInfo.client_id}
      // onChange={(e) => setUserPartnerCommissionInfo({ ...userPartnerCommissionInfo, client_id: e.target.value })}
    >
      <option value="" disabled selected>
        Selecione o cliente
      </option>

      {userPartners.map((partner) => (
        <option key={partner.id} value={partner.id}>
          {partner.name} - {partner.cpf}
        </option>
      ))}
    </Select>

    <p
      style={{
        fontSize: '12px',
      }}
    >
      Comissão
    </p>
    <div
      style={{
        display: 'flex',
        margin: '0 auto',
        width: '95%',
      }}
    >
      <SliderContent
        track={false}
        aria-labelledby="track-false-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(e) => {
          return `${Math.round(e)}%`;
        }}
        getAriaValueText={(value) => `${value}`}
        // defaultValue={grievanceValue || 0}
        // defaultValue={userPartnerCommissionInfo.percent || 0}
        // value={userPartnerCommissionInfo.percent}
        // onChange={(event) => {
        //   setUserPartnerCommissionInfo({
        //     ...userPartnerCommissionInfo,
        //     percent: event.target.value,
        //   });
        // }}
        onChangeCommitted={() => {
          // setDiscountValue(0);
          // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
        }}
        min={0}
        // max={userPartnerInfo.commercialization - userPartnerInfo.loading}
        step={1}
        // marks={[
        //   {
        //     value: 0,
        //     label: `0%`,
        //   },
        //   {
        //     value: userPartnerInfo.commercialization - userPartnerInfo.loading,
        //     label: `${userPartnerInfo.commercialization - userPartnerInfo.loading}%`,
        //   },
        // ]}
      />
    </div>

    <CustomFormControlLabel
      control={
        <Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
          style={{ color: primary }}
          // checked={userPartnerCommissionInfo.enabled}
          // onChange={(e) => {
          //   setUserPartnerCommissionInfo({
          //     ...userPartnerCommissionInfo,
          //     enabled: e.target.checked,
          //   });
          // }}
        />
      }
      label="Regra ativa?"
    />

    <ModalButton>
      {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

      <Button
        height="35px"
        onClick={() => {
          createCoverages({
            setLoading,
            setOpenModalAdd,
            setCurrentPage,
            setUsercommissionsList,
            setHasNextPage,
            setTotalCount,
            setTouchFilter,
            setUserPartners,
            setUserPartnerInfo,
            loadingDataTable,
            setLoadingDataTable,
            setCoverageList,
            coverageType,
            coverageInfo,
            setCoverageInfo,
          });
        }}
        disabled={loading}
      >
        Adicionar
      </Button>
    </ModalButton>
  </Dialog>
);

const renderRemoveCoverages = ({
  openModalRemove,
  setOpenModalRemove,
  userPartnerCommissionInfo,
  setUserPartnerCommissionInfo,
  loading,
  setLoading,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,

  loadingDataTable,
  setLoadingDataTable,
  coverageList,
  setCoverageList,
  coverageType,
  coverageInfo,
  setCoverageInfo,
}) => (
  <ConfirmDialog
    open={openModalRemove}
    onCancel={() => {
      setOpenModalRemove(false);
      setCoverageInfo({
        ...coverageList,
        id: undefined,
      });
    }}
    content="Tem certeza de que deseja excluir essa trava?"
    title="Excluir trava"
    onConfirm={() => {
      handleRemoveCoverages({
        setLoading,
        setUserPartnerCommissionInfo,
        userPartnerCommissionInfo,
        setOpenModalRemove,
        setCurrentPage,
        setUsercommissionsList,
        setHasNextPage,
        setTotalCount,
        setTouchFilter,
        loadingDataTable,
        setLoadingDataTable,
        coverageInfo,
        setCoverageInfo,
        setCoverageList,
        coverageType,
      });
    }}
    loading={loading}
  />
);

export { renderEditCoverages, renderAddCoverages, renderRemoveCoverages };
