import React, { useState } from 'react';
import Cookies from 'js-cookie';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import api from 'api';
import moment from 'moment';
import 'moment/locale/pt-br';
import { error, success, warn, middleGray, lightGray } from 'styles/colorProvider';
import { Dialog, Button, PaymentMethod } from 'components';
import { toast } from 'react-toastify';

import {
  HiOutlineArrowNarrowRight,
  HiOutlineClock,
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlineCalendar,
  HiOutlineXCircle,
  HiOutlineBan,
} from 'react-icons/hi';
import {
  Container,
  Status,
  StatusText,
  Month,
  MonthText,
  ContainerText,
  Value,
  Message,
  ConfirmAndCancelButtonsContainer,
  CancelButton,
} from './styles';
import { formatPaymentStatus } from 'utils/paymentStatus';
import { useHistory } from 'react-router-dom';
import { formatCurrency } from 'react-data-formatter';

const translateModality = {
  billet: 'Boleto',
  pix: 'Pix',
  credit_card: 'Cartão de crédito',
};

function ChargeItem({
  id,
  status,
  month,
  value,
  message,
  clickable,
  type,
  backoffice,
  backofficePolicy,
  loadData,
  invoiceUrl,
  billingType,
  canBeReprocessed,
  canChangeModalityToCreditCard,
  proposalId,
  policyId,
  isHinova = false,
}) {
  const history = useHistory();

  const payDate = `Vence em ${moment.utc(message).format('DD/MM/YYYY')}`;
  const paidDate = `Pago em ${moment.utc(message).format('DD/MM/YYYY')}`;
  const dueAt = `Vencido em ${moment.utc(message).format('DD/MM/YYYY')}`;

  const [selectedModality, setSelectedModality] = useState('');

  const [changePaymentModality, setChangePaymentModality] = useState(false);
  const [confirmChangePaymentModality, setConfirmChangePaymentModality] = useState(false);

  const [confirmManualPayment, setConfirmManualPayment] = useState(false);
  const [textInput, setTextInput] = useState();

  const [confirmReprocessPayment, setConfirmReprocessPayment] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const handleSelectModality = (newModality) => {
    setSelectedModality(newModality);
    setChangePaymentModality(false);
    setConfirmChangePaymentModality(true);
  };

  const handleChangeModality = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'post',
        url: '/change-charge-modality',
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          newModality: selectedModality,
          id_charge: id,
          id_policy: type === 'resubscription' ? policyId : null,
          id_proposal: type === 'subscription' ? proposalId : null,
        },
      });
      toast.success('Modalide de pagamento alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmChangePaymentModality(false);
      await sleep(3000);
      loadData();
    } catch (e) {
      toast.error(e?.response?.data?.error || 'Não foi possivel alterar a modalidade de pagamento para essa cobrança', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmChangePaymentModality(false);
    }
  };

  const handleManualPayment = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'post',
        url: `/manual-payment`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id_charge: id,
          id_proposal: type === 'subscription' ? proposalId : null,
          id_policy: type === 'resubscription' ? policyId : null,
          description: textInput,
        },
      });
      toast.success('Baixa manual realizada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmManualPayment(false);
      setTextInput('');
      await sleep(3000);
      loadData();
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Não foi possível processar baixa manual para essa cobrança', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmManualPayment(false);
      setTextInput('');
    }
  };

  const handleReprocessPayment = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'post',
        url: `/reprocess-charge-with-credit-card/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      toast.success('Cobrança reprocessada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmReprocessPayment(false);
      await sleep(3000);
      loadData();
    } catch (e) {
      toast.error(e?.response?.data?.error || 'Não foi possível reprocessar esse pagamento', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButton(false);
      setConfirmReprocessPayment(false);
    }
  };

  const renderConfirmManualPayment = () => (
    <Dialog title="Baixa manual" open={confirmManualPayment} onClose={() => setConfirmManualPayment(false)}>
      <label>Descrição:</label>
      <TextareaAutosize
        minRows={1}
        onChange={(e) => setTextInput(e.target.value)}
        value={textInput}
        placeholder="Insira aqui a descrição"
        style={{
          padding: 10,
          fontFamily: 'Nunito',
          fontSize: 14,
          marginTop: '3%',
          width: '100%',
          height: '100px',
          borderRadius: 5,
          borderColor: '#d1d2d4',
        }}
      />

      <ConfirmAndCancelButtonsContainer>
        <button className="cancel" onClick={() => setConfirmManualPayment(false)}>
          Cancelar
        </button>
        <Button className="confirm" disabled={loadingButton || !textInput} loading={loadingButton} onClick={handleManualPayment}>
          Confirmar baixa
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  const renderConfirmReprocessPayment = () => (
    <Dialog title="Reprocessar cobrança" open={confirmReprocessPayment} onClose={() => setConfirmReprocessPayment(false)}>
      <label>Isso fará com que a cobrança seja reprocessada caso o cliente tenha um cartão de credito cadastrado</label>
      <ConfirmAndCancelButtonsContainer>
        <Button className="cancel" onClick={() => setConfirmReprocessPayment(false)}>
          Cancelar
        </Button>
        <Button disabled={loadingButton} loading={loadingButton} onClick={handleReprocessPayment}>
          Confirmar reprocessamento
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  const renderChangePaymentModality = () => (
    <Dialog title="Alterar modalidade de pagamento" open={changePaymentModality} onClose={() => setChangePaymentModality(false)}>
      <label>
        Ao alterar a modalidade de pagamento para essa cobrança, todas as próximas cobranças serão geradas na modalidade
        escolhida.
      </label>
      {!canChangeModalityToCreditCard && billingType !== 'credit_card' && (
        <label>
          Atenção: para poder escolher a opção "Cartão de credito" o cliente dessa apólice precisa ter um cartão de crédito
          cadastrado.
        </label>
      )}
      {canChangeModalityToCreditCard && billingType !== 'credit_card' && (
        <PaymentMethod
          disabled={true}
          style={{ marginTop: 10, width: '50%' }}
          type="cartão de crédito"
          onClick={() => handleSelectModality('credit_card')}
        />
      )}
      {billingType !== 'billet' && (
        <PaymentMethod style={{ marginTop: 10, width: '50%' }} type="boleto" onClick={() => handleSelectModality('billet')} />
      )}
      {billingType !== 'pix' && (
        <PaymentMethod style={{ marginTop: 10, width: '50%' }} type="pix" onClick={() => handleSelectModality('pix')} />
      )}
      <CancelButton onClick={() => setChangePaymentModality(false)}>Cancelar</CancelButton>
    </Dialog>
  );

  const renderConfirmChangePaymentModality = () => (
    <Dialog
      title="Confirmar alteração de modalidade de pagamento"
      open={confirmChangePaymentModality}
      onClose={() => {
        setConfirmChangePaymentModality(false);
        setChangePaymentModality(true);
      }}
    >
      <label>
        Tem certeza que deseja alterar a modalidade de pagamento para <strong>{translateModality[selectedModality]}</strong>?
      </label>
      {selectedModality === 'credit_card' && (
        <label>
          Ao confirmar essa alteração para cartão de crédito, {!!proposalId && 'Caso exista um cartão cadastrado '}
          <strong>será feita a cobrança dessa fatura no cartão do cliente</strong>
        </label>
      )}
      <ConfirmAndCancelButtonsContainer>
        <button
          className="cancel"
          onClick={() => {
            setConfirmChangePaymentModality(false);
            setChangePaymentModality(true);
          }}
        >
          Cancelar
        </button>
        <Button className="confirm" onClick={() => handleChangeModality()} loading={loadingButton} disabled={loadingButton}>
          Confirmar
        </Button>
      </ConfirmAndCancelButtonsContainer>
    </Dialog>
  );

  return (
    <>
      {renderConfirmManualPayment()}
      {renderConfirmReprocessPayment()}
      {renderChangePaymentModality()}
      {renderConfirmChangePaymentModality()}
      <Container
        onClick={() => clickable && status.includes('awaiting_') && history.push(`/pagamento/${id}`)}
        clickable={clickable}
      >
        <div className="description">
          <div className="month-and-payment">
            <Month>
              <HiOutlineCalendar color={middleGray} size={16} />
              <MonthText>{month}</MonthText>
            </Month>
            <Status>
              {status === 'awaiting_payment' && <HiOutlineClock color={warn} size={20} />}
              {status === 'paid' && <HiOutlineCheckCircle color={success} size={20} />}
              {status === 'overdue' && <HiOutlineExclamationCircle color={error} size={20} />}
              {status === 'due' && <HiOutlineXCircle color={error} size={20} />}
              {status === 'canceled' && <HiOutlineBan color={error} size={20} />}
              <StatusText>{formatPaymentStatus(status)?.label}</StatusText>
            </Status>
          </div>
          <ContainerText>
            <Value>{type === 'deductible' ? formatCurrency(value) : formatCurrency(value)}</Value>
          </ContainerText>
          <Message>
            {type === 'subscription' && 'Subscrição'}
            {type === 'resubscription' && 'Resubscrição'}
            {type === 'deductible' && 'Franquia'}
            {type === 'other' && 'Outros'}
          </Message>
          <Message>{billingType && translateModality[billingType]}</Message>
          <Message>
            {status === 'paid' && <nobr>{paidDate}</nobr>}
            {status === 'overdue' && <nobr>{dueAt}</nobr>}
            {<nobr>{status !== 'paid' && status !== 'overdue' && payDate}</nobr>}
          </Message>
        </div>
        {status === 'awaiting_payment' && clickable && (
          <HiOutlineArrowNarrowRight color={lightGray} size={25} className="iconArrow" />
        )}

        {billingType !== 'credit_card' && !['paid', 'canceled'].includes(status) && (backoffice || backofficePolicy) && (
          <button
            onClick={() => setConfirmManualPayment(true)}
            height="30px"
            style={{ width: '10rem', marginLeft: '1rem' }}
            className="billet-create"
          >
            Baixa manual
          </button>
        )}

        <div className="charge-buttons">
          {billingType === 'credit_card' &&
            !['paid', 'canceled'].includes(status) &&
            canBeReprocessed &&
            (backoffice || backofficePolicy) && (
              <button
                onClick={() => setConfirmReprocessPayment(true)}
                height="30px"
                style={{ width: '10rem' }}
                className="reprocess-charge"
              >
                Reprocessar
              </button>
            )}

          {!['paid', 'canceled'].includes(status) && !isHinova && (
            <button onClick={() => setChangePaymentModality(true)} className="change-modality">
              Alterar modalidade
            </button>
          )}

          {invoiceUrl && status !== 'paid' && (
            <a href={invoiceUrl} target="_blank" rel="noopener noreferrer" className="view-charge">
              Ver cobrança
            </a>
          )}
        </div>
      </Container>
    </>
  );
}

export default ChargeItem;
