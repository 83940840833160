import styled from 'styled-components';
import { primary, secondary, white, middleGray, lightGray, lighterGray, darkGray } from 'styles/colorProvider';
import { styled as SliderStyled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';

export const Container = styled.div`
  background-color: ${white};
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 90%;
  height: 100% !important;
  max-height: 100% !important;
  box-sizing: border-box;
  padding: 30px;
  padding-left: 90px;
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${primary};
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    margin: 20px 0px;
  }
`;

export const PolicyInformationArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -1.6%;

  .lastItem {
    height: 9%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const ItemPartner = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  width: 100%;
  padding: 2.2% 3%;
  margin-top: 20px;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 10%;
    margin-top: 15%;
  }
`;

export const Subtitle = styled.h2`
  color: ${primary};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${darkGray};
  margin: 0;
  margin-top: 2%;

  span {
    color: ${middleGray};
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ContractBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 80px;

  h1 {
    margin: 10px 0px;
    margin-top: 30px;
    color: ${primary};
    font-size: 28px;
  }

  .contractArea {
    height: 65vh !important;
    max-height: 65vh !important;
    width: 100%;
    background-color: ${lighterGray};
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${primary};
      border-radius: 10px;
    }
  }
`;

export const ModalButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const ModalError = styled.p`
  visibility: ${(props) => (props.showError ? 'visible' : 'hidden')};
  font-size: 12px;
  color: #f61e2c;
`;

export const SliderContent = SliderStyled(Slider)({
  color: '#c7c7c7',
  height: 8,
  width: '100%',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: secondary,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '100%',
    backgroundColor: secondary,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -80%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -80%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});
