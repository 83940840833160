/* eslint-disable jsx-a11y/alt-text */
import defaultIcon from 'assets/cardsFlags/default.png';
import visaIcon from 'assets/cardsFlags/visa.png';
import masterIcon from 'assets/cardsFlags/master.png';
import dinnersIcon from 'assets/cardsFlags/dinners.png';
import amexIcon from 'assets/cardsFlags/amex.png';

const imageByBrand = {
  "mastercard": <img className="flagIcon" src={masterIcon} />,
  "visa": <img className="flagIcon" src={visaIcon} />,
}

export const getCardFlag = (cardNumber, brand) => {
  if (brand && imageByBrand[brand]) {
    return { name: brand, image: imageByBrand[brand] }
  }
  const firstNumbers = cardNumber.slice(0, 2);

  let flag = { name: 'Cartão de crédito', image: <img className="flagIcon" src={defaultIcon} /> };

  if (firstNumbers[0] === '4') flag = { name: 'Visa', image: <img className="flagIcon" src={visaIcon} /> };
  if (firstNumbers[0] === '5') flag = { name: 'Mastercard', image: <img className="flagIcon" src={masterIcon} /> };
  if (firstNumbers === '36' || firstNumbers === '38')
    flag = { name: 'Dinners Club', image: <img className="flagIcon" src={dinnersIcon} /> };
  if (firstNumbers === '34' || firstNumbers === '37')
    flag = { name: 'American Express', image: <img className="flagIcon" src={amexIcon} /> };

  return flag;
};