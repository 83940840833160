import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactLoading from 'react-loading';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import { white } from 'styles/colorProvider';

function ConfirmDialog({
  open,
  onClose,
  title,
  content,
  onConfirm,
  onCancel,
  loading,
  confirmLabel,
  cancelLabel,
  invertButtons,
  requestCancel,
}) {
  const history = useHistory();

  return (
    <Dialog open={open} onClose={onClose} onCancel={onClose}>
      <div style={{ padding: 10 }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {requestCancel ? (
            <></>
          ) : (
            <Button onClick={() => onCancel()} height="35px" width="120px" outlined={!invertButtons} disabled={loading}>
              {cancelLabel || 'Cancelar'}
            </Button>
          )}
          <Button disabled={loading} onClick={() => onConfirm()} height="35px" width="120px" outlined={invertButtons}>
            {loading ? <ReactLoading color={white} height={30} width={30} type="spin" /> : confirmLabel || 'Confirmar'}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ConfirmDialog;
