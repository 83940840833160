export const primary = '#2a034f';
export const secondary = '#62ec83';
export const loadingColor = '#2ecc71';
export const success = '#2ecc71';
export const error = '#e74c3c';
export const warn = '#F1C40F';
export const white = '#FFFFFF';
export const black = '#000000';
export const darkGray = '#121212';
export const middleGray = '#868686';
export const middleGraySecondary = '#5c5a5a';
export const lightGray = '#d1d2d4';
export const lighterGray = '#fafafa';
export const primaryGradient = `linear-gradient(90deg, #2a034f 0%, #4c1580 100%)`;
export const lineGradient = 'linear-gradient(73deg, #421b67 0%, #713aa6 50%, #421b67 100%)';

// {
// // SPLIT RISK
// export const primary = '#2a034f';
// export const secondary = '#62ec83';
// export const loadingColor = '#2ecc71';
// export const success = '#2ecc71';
// export const error = '#e74c3c';
// export const warn = '#F1C40F';
// export const white = '#FFFFFF';
// export const black = '#000000';
// export const darkGray = '#121212';
// export const middleGray = '#868686';
// export const lightGray = '#d1d2d4';
// export const lighterGray = '#fafafa';
// export const primaryGradient = `linear-gradient(90deg, #2a034f 0%, #4c1580 100%)`;
// export const lineGradient = 'linear-gradient(73deg, #421b67 0%, #713aa6 50%, #421b67 100%)';
// }
