import React from 'react';
import 'moment/locale/pt-br';
import { BsShieldSlash } from 'react-icons/bs';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container, Div, Title, Icon } from './styles';

function ExclusionRuleItem({ item, onClick }) {
  return (
    <>
      <Container>
        <div className="description">
          <div className="iconCar">
            <BsShieldSlash size={15} />
          </div>

          <Div>
            <Title>{item?.description}</Title>
          </Div>

          <Div>
            <Title>Rejeitar</Title>
          </Div>

          <Icon>
            <DeleteIcon
              onClick={onClick}
              style={{ cursor: 'pointer' }}
              onMouseOver={({ target }) => (target.style.color = '#e74c3c')}
              onMouseOut={({ target }) => (target.style.color = '#121212')}
            />
          </Icon>
        </div>
      </Container>
    </>
  );
}

export default ExclusionRuleItem;