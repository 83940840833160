import { RiCarLine } from 'react-icons/ri';
import { formatPolicyStatus } from 'utils/policyStatus';

import { Container, ContainerText, BrandAndModel, Plate } from './styles';

function ObjectVehicle({ data, onClick, active }) {
  return (
    <Container onClick={onClick} active={active}>
      <div className="description">
        <RiCarLine color={formatPolicyStatus(data?.status)?.color} size={28} />
        <ContainerText>
          <BrandAndModel>{data?.model}</BrandAndModel>
          <Plate>{`${data?.manufacture_year}/${data?.year_model}`}</Plate>
        </ContainerText>
      </div>
    </Container>
  );
}

export default ObjectVehicle;
