import React, { useState } from 'react';
import { BiDetail, BiMenuAltRight } from 'react-icons/bi';
import { FiDollarSign } from 'react-icons/fi';
import { MdChevronLeft } from 'react-icons/md';
import { RiHistoryFill } from 'react-icons/ri';
import { useLocation, useHistory } from 'react-router-dom';
import { BsChatSquareText, BsPrinter, BsShieldExclamation } from 'react-icons/bs';
import { Container, ItemMenu, TextItem, Div, Subtitle, ModalButton, Description, MobileMenu } from './styles';
import { Dialog, Button } from 'components';
import { VscCloseAll } from 'react-icons/vsc';
import { BiBarcodeReader } from 'react-icons/bi';
import { toast } from 'react-toastify';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import api from 'api';
import Cookies from 'js-cookie';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import settings from 'settings.json';

function BackofficeMenu({ policyId, onCancel, policyData }) {
  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [textInput, setTextInput] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const handleCancelPolicy = async (e) => {
    setLoadingCancel(true);
    try {
      await api({
        method: 'POST',
        url: `/backoffice-cancel-policy`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          id: policyId,
          description: textInput,
        },
        json: true,
      });
      setLoadingCancel(false);
      handleClose();
      window.location.reload();
    } catch (e) {
      setLoadingCancel(false);

      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MobileMenu>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <BiMenuAltRight size={26} />
        </IconButton>

        <Menu
          style={{ marginTop: '50px', marginLeft: '-40px' }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              history.push('/backoffice/apolices');
            }}
          >
            <MdChevronLeft size="18" />
            <TextItem>Apólices</TextItem>
          </MenuItem>

          <MenuItem
            selected={path.includes('/backoffice/detalhes-da-apolice')}
            onClick={() => {
              history.push(`/backoffice/detalhes-da-apolice/${policyId}`);
            }}
          >
            <BiDetail size="18" />
            <TextItem>Detalhes</TextItem>
          </MenuItem>

          <MenuItem
            selected={path.includes('/backoffice/historico-da-apolice')}
            onClick={() => {
              history.push(`/backoffice/historico-da-apolice/${policyId}`);
            }}
          >
            <RiHistoryFill size="18" style={{ marginRight: '-3px' }} />
            <TextItem>Histórico da apólice</TextItem>
          </MenuItem>

          <MenuItem
            selected={path.includes('/backoffice/faturas')}
            onClick={() => {
              history.push(`/backoffice/faturas/${policyId}`);
            }}
          >
            <FiDollarSign size="18" style={{ marginRight: '-3px' }} />
            <TextItem>Faturas</TextItem>
          </MenuItem>

          <MenuItem
            selected={path.includes('/backoffice/historico-reclamacao')}
            onClick={() => {
              history.push(`/backoffice/historico-reclamacao/${policyId}`);
            }}
          >
            <BsChatSquareText size="18" style={{ marginRight: '-3px' }} />
            <TextItem>Reclamações</TextItem>
          </MenuItem>

          {policyData?.status === 'active' && (
            <MenuItem onClick={onCancel}>
              <VscCloseAll size="18" style={{ marginRight: '-3px' }} />
              <TextItem onClick={handleClickOpen}>Cancelar seguro</TextItem>

              <>
                <Dialog
                  open={open}
                  title="Confirmar cancelamento"
                  width="450px"
                  onConfirm={handleCancelPolicy}
                  onCancel={handleClose}
                  loading={loading}
                >
                  <Div>
                    <Subtitle>Tem certeza de que deseja cancelar esta apólice? O cancelamento não poderá ser desfeito!</Subtitle>
                    <Description>
                      <TextareaAutosize
                        minRows={1}
                        onChange={(e) => setTextInput(e.target.value)}
                        value={textInput}
                        placeholder="Observações..."
                        style={{
                          padding: 10,
                          fontFamily: 'Nunito',
                          fontSize: 14,
                          marginTop: '3%',
                          width: '100%',
                          height: '100px',
                          borderRadius: 5,
                          borderColor: '#d1d2d4',
                        }}
                      />
                    </Description>
                  </Div>
                  <ModalButton>
                    <Button
                      height="35px"
                      style={{ background: 'transparent', border: '1.5px solid #62ec83' }}
                      onClick={handleClose}
                      loading={loadingCancel}
                    >
                      Voltar
                    </Button>
                    <Button height="35px" style={{ background: '#62ec83', border: 'none' }} onClick={handleCancelPolicy}>
                      Concluir cancelamento
                    </Button>
                  </ModalButton>
                </Dialog>
              </>
            </MenuItem>
          )}

          <MenuItem onClick={() => window.open(settings?.claim_url)}>
            <BsShieldExclamation size="18" style={{ marginRight: '-3px' }} />
            <TextItem onClick={handleClickOpen}>Comunicar sinistro</TextItem>
          </MenuItem>
        </Menu>
      </MobileMenu>

      <Container>
        <ItemMenu
          onClick={() => {
            history.push('/backoffice/apolices');
          }}
        >
          <MdChevronLeft size="18" />
          <TextItem>Apólices</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/backoffice/detalhes-da-apolice') && 'select'}
          onClick={() => {
            history.push(`/backoffice/detalhes-da-apolice/${policyId}`);
          }}
        >
          <BiDetail size="18" />
          <TextItem>Detalhes</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/backoffice/historico-da-apolice') && 'select'}
          onClick={() => {
            history.push(`/backoffice/historico-da-apolice/${policyId}`);
          }}
        >
          <RiHistoryFill size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Histórico da apólice</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/backoffice/faturas') && 'select'}
          onClick={() => {
            history.push(`/backoffice/listar-documentos-apolice/${policyId}`);
          }}
        >
          <BsPrinter size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Imprimir</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/backoffice/faturas') && 'select'}
          onClick={() => {
            history.push(`/backoffice/faturas/${policyId}`);
          }}
        >
          <FiDollarSign size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Faturas</TextItem>
        </ItemMenu>

        <ItemMenu
          className={path.includes('/backoffice/historico-reclamacao') && 'select'}
          onClick={() => {
            history.push(`/backoffice/historico-reclamacao/${policyId}`);
          }}
        >
          <BsChatSquareText size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Reclamações</TextItem>
        </ItemMenu>

        {(policyData?.status === 'active' || policyData?.status === 'awaiting_cancellation') && (
          <ItemMenu onClick={onCancel}>
            <VscCloseAll size="18" style={{ marginRight: '-3px' }} />
            <TextItem onClick={handleClickOpen}>Cancelar seguro</TextItem>

            <>
              <Dialog
                open={open}
                title="Confirmar cancelamento"
                width="450px"
                onConfirm={handleCancelPolicy}
                onCancel={handleClose}
                loading={loading}
              >
                <Div>
                  <Subtitle>Tem certeza de que deseja cancelar esta apólice? O cancelamento não poderá ser desfeito!</Subtitle>
                  <Description>
                    <TextareaAutosize
                      minRows={1}
                      onChange={(e) => setTextInput(e.target.value)}
                      value={textInput}
                      placeholder="Observações..."
                      style={{
                        padding: 10,
                        fontFamily: 'Nunito',
                        fontSize: 14,
                        marginTop: '3%',
                        width: '100%',
                        height: '100px',
                        borderRadius: 5,
                        borderColor: '#d1d2d4',
                      }}
                    />
                  </Description>
                </Div>
                <ModalButton>
                  <Button
                    height="35px"
                    style={{ background: 'transparent', border: '1.5px solid #62ec83' }}
                    onClick={handleClose}
                  >
                    Voltar
                  </Button>
                  <Button
                    height="35px"
                    style={{ background: '#62ec83', border: 'none' }}
                    onClick={handleCancelPolicy}
                    loading={loadingCancel}
                  >
                    Concluir cancelamento
                  </Button>
                </ModalButton>
              </Dialog>
            </>
          </ItemMenu>
        )}
        <ItemMenu onClick={() => window.open(settings?.claim_url)}>
          <BsShieldExclamation size="18" style={{ marginRight: '-3px' }} />
          <TextItem>Comunicar sinistro</TextItem>
        </ItemMenu>
      </Container>
    </>
  );
}

export default BackofficeMenu;
