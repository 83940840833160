/* eslint-disable no-restricted-globals */
import api from 'api/index';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const handleCreateBlacklistQuotation = async ({ loadData, setLoadingData, setOpenModalAdd, type, value, setInputValue }) => {
  setLoadingData(true);
  try {
    await api({
      method: 'POST',
      url: `/blacklist`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        type: type,
        value: value,
      },
      json: true,
    });

    toast.success('Item adicionado à blacklist com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });

    setInputValue('');
    setOpenModalAdd(false);
    setLoadingData(false);
    await loadData();
  } catch (e) {
    setLoadingData(false);
    toast.error(e?.response?.data?.error, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  }
};

const handleCreateBlacklistQuotationBatch = async ({ setOpenModalAddBatch, loadData, setLoading, file }) => {
  setLoading(true);

  const formData = new FormData();
  formData.append('fileBlacklist', file);

  try {
    await api({
      method: 'POST',
      url: `/blacklist/batch`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: formData,
      json: true,
    });

    toast.success('Itens adicionados à blacklist com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });

    setOpenModalAddBatch(false);
    await loadData();
    setLoading(false);
  } catch (e) {
    setLoading(false);
    toast.error('Não foi possível enviar o arquivo', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  }
};

const handleRemoveBlacklistQuotation = async ({ id, loadData, setOpenModalRemove, setLoadingData }) => {
  setLoadingData(true);
  try {
    await api({
      method: 'DELETE',
      url: `/blacklist/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });

    toast.success('Item removido com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });

    setOpenModalRemove(false);
    setLoadingData(false);
    await loadData();
  } catch (e) {
    setLoadingData(false);
    toast.error('Não foi possível excluir o item', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
  }
};

const updateBlacklistQuotation = async ({ setOpenModalEdit, setInputValue, loadData, value, id, setLoadingData }) => {

  try {
    setLoadingData(true);
    await api({
      method: 'PATCH',
      url: `/blacklist/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        value,
      },
      json: true,
    });

    toast.success('Item atualizado!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
setInputValue('')
    setOpenModalEdit(false);
    setLoadingData(false);
    await loadData();
  } catch (e) {
    toast.error('Não foi possível atualizar o item', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
    setInputValue('')
  } finally {
    setLoadingData(false);
  }
};

export {
  handleCreateBlacklistQuotation,
  updateBlacklistQuotation,
  handleRemoveBlacklistQuotation,
  handleCreateBlacklistQuotationBatch,
};
