/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import api from 'api';
import CsvDownload from 'react-json-to-csv';

import { Header, SideMenu, ComplaintItem, Search, Select, Dialog, Button } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea } from './styles';
import { loadingColor, primary, secondary, white } from 'styles/colorProvider';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';

function ComplaintList() {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [complaintsList, setComplaintsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [exportData, setExportData] = useState();
  const [exportStatus, setExportStatus] = useState();
  const [search, setSearch] = useState();
  const [openExport, setOpenExport] = useState(false);

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/list-complaints`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      params: {
        search,
        page: currentPage,
        take: 10,
      },
      json: true,
    });

    setComplaintsList(resp?.data?.complaints);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);

      const resp = await api({
        method: 'GET',
        url: `/list-complaints`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        params: {
          search,
          page,
          take: 10,
        },
        json: true,
      });

      const data = complaintsList;
      const ndata = data.concat(resp?.data?.complaints);

      setCurrentPage(page);
      setComplaintsList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  const handleExport = async () => {
    setLoadingExport(true);

    try {
      const resp = await api({
        method: 'GET',
        url: `/report/export-complaints`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
          feature: 'export-complaints',
        },
        data: {
          status: exportStatus === null ? null : parseInt(exportStatus),
        },
        json: true,
      });
      setExportData(resp.data);
      setLoadingExport(false);
    } catch (error) {
      toast.error("Não foi possível exportar reclamacões", {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setOpenExport(false);
      setLoadingExport(false);
    }
  };

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loadingExport && setOpenExport(false);
        setExportStatus();
        setExportData();
      }}
      width="50%"
      title="Exportar reclamações"
    >
      {!exportData && (
        <>
          <Select height="30px" label="Status" value={exportStatus} onChange={(e) => setExportStatus(e.target.value)}>
            <option value={null}>Todos os Status</option>
            <option value={1}>Reclamação aberta</option>
            <option value={2}>Encerrada procedente</option>
            <option value={3}>Encerrada improcedente</option>
          </Select>
          <Button height="35px" style={{ margin: 0, marginTop: 30 }} onClick={handleExport} disabled={loadingExport}>
            {loadingExport ? <ReactLoading color={white} height={25} width={25} type="spin" /> : 'Exportar'}
          </Button>
        </>
      )}

      {exportData?.hasData ? (
        <>
          <p>Seu arquivo está pronto!</p>
          <CsvDownload
            data={exportData?.data}
            style={{
              height: 30,
              width: 200,
              margin: 0,
              cursor: 'pointer',
              borderRadius: 20,
              border: 'none',
              backgroundColor: secondary,
            }}
            filename={`split_risk_reclamacoes_${new Date().toLocaleDateString()}.csv`}
          >
            Baixar
          </CsvDownload>
        </>
      ) : (
        <p>{exportData?.message}</p>
      )}
    </Dialog>
  );

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        {renderExportDialog()}

        <SideMenu />

        <ContentArea>
          <Div>
            <Title>RECLAMAÇÕES</Title>
            <div className="containerTitle">
              <Button title="Exportar" height="30px" style={{ marginRight: 15 }} onClick={() => setOpenExport(true)}>
                <RiFileExcel2Fill size={18} />
              </Button>
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData();
                }}
                onConfirm={() => loadData()}
              />
            </div>
          </Div>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading && complaintsList?.map((complaint) => <ComplaintItem complaint={complaint} />)}
              </div>
            </InfiniteScroll>

            {!loading && !complaintsList?.length && <p>Nenhuma reclamação encontrada</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {complaintsList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default ComplaintList;
