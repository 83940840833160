import moment from 'moment';
import { formatCurrency, formatCPF } from 'react-data-formatter';

export const dataColumns = [
  {
    name: 'ID Apólice',
    selector: (row) => row?.id,
    width: '10%',
  },
  {
    name: 'Cliente',
    selector: (row) => row?.user?.name,
    width: '20%',
  },
  {
    name: 'CPF',
    selector: (row) => formatCPF(row?.user?.cpf),
    width: '15%',
  },
  {
    name: 'Apólice',
    selector: (row) => row?.policy_number,
    width: '20%',
  },
  {
    name: 'Valor',
    selector: (row) => formatCurrency(row?.value || 0),
    width: '10%',
  },
  {
    name: 'Próximo vencimento',
    selector: (row) => moment(row?.next_due).format('DD/MM/YYYY'),
    width: '15%',
  },
  {
    name: 'Status',
    selector: (row) => (row?.generated ? '✅' : '❌'),
    width: '15%',
  },
];

export const dataColumnsCharges = [
  {
    name: 'ID Cobrança',
    selector: (row) => row?.id,
    width: '10%',
  },
  {
    name: 'Cliente',
    selector: (row) => row?.user?.name,
    width: '20%',
  },
  {
    name: 'CPF',
    selector: (row) => formatCPF(row?.user?.cpf),
    width: '15%',
  },

  {
    name: 'Apólice',
    selector: (row) => row?.policy?.policy_number,
    width: '20%',
  },
  {
    name: 'Valor',
    selector: (row) => formatCurrency(row?.value || 0),
    width: '10%',
  },
  {
    name: 'Data vencimento',
    selector: (row) => moment(row?.due_at).format('DD/MM/YYYY'),
    width: '15%',
  },
  {
    name: 'Status',
    selector: (row) => (row?.generated ? '✅' : '✅'),
    width: '15%',
  },
];
