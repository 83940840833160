import { Header, SideMenu, Select, Search, Button, Dialog, Input } from 'components';
import { SliderContent } from '../Partners/styles';
import { Switch, FormControlLabel, withStyles, Checkbox } from '@material-ui/core';
import { loadingColor, primary } from 'styles/colorProvider';
import { DivCenter, ModalButton, ModalError, RemoveButton } from './styles';
import ReactLoading from 'react-loading';
import { ConfirmDialog } from 'components/index';
import { createBlacklistQuotation, handleRemoveBlacklistQuotation, updateBlacklistQuotation } from './requests';

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: '12px', // Defina o tamanho da fonte desejado aqui
  },
})(FormControlLabel);

const renderEditBlacklistQuotation = ({
  openModalEdit,
  setOpenModalEdit,
  userPartnerCommissionInfo,
  setUserPartnerCommissionInfo,
  loading,
  setLoading,
  userPartnerInfo,
  loadData,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  loadingDataTable,
  setLoadingDataTable,
}) => (
  <Dialog
    open={openModalEdit}
    // onClose={() => {
    //   setOpenModalEdit(false);
    //   setUserPartnerCommissionInfo({
    //     enabled: false,
    //   });
    // }}
    width="100%"
    title="Editar trava"
  >
    {/* <ModalError showError={values.loading > values.commercialization || values.max_loading > values.commercialization}>
        A comercialização não pode ser menor que o carregamento {values.loading > values.commercialization ? 'atual' : 'máximo'}
      </ModalError> */}

    <p
      style={{
        fontSize: '12px',
      }}
    >
      Comissão
    </p>
    <div
      style={{
        display: 'flex',
        margin: '0 auto',
        width: '95%',
      }}
    >
      <SliderContent
        track={false}
        aria-labelledby="track-false-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(e) => {
          return `${Math.round(e)}%`;
        }}
        getAriaValueText={(value) => `${value}`}
        // defaultValue={grievanceValue || 0}
        // defaultValue={userPartnerCommissionInfo.percent || 0}
        // value={userPartnerCommissionInfo.percent}
        // onChange={(event) => {
        //   setUserPartnerCommissionInfo({
        //     ...userPartnerCommissionInfo,
        //     percent: event.target.value,
        //   });
        // }}
        onChangeCommitted={() => {
          // setDiscountValue(0);
          // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
        }}
        min={0}
        // max={userPartnerInfo.commercialization - userPartnerInfo.loading}
        step={1}
        // marks={[
        //   {
        //     value: 0,
        //     label: `0%`,
        //   },
        //   {
        //     value: userPartnerInfo.commercialization - userPartnerInfo.loading,
        //     label: `${userPartnerInfo.commercialization - userPartnerInfo.loading}%`,
        //   },
        // ]}
      />
    </div>

    <CustomFormControlLabel
      control={
        <Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
          style={{ color: primary }}
          // checked={userPartnerCommissionInfo.enabled}
          // onChange={(e) => {
          //   setUserPartnerCommissionInfo({
          //     ...userPartnerCommissionInfo,
          //     enabled: e.target.checked,
          //   });
          // }}
        />
      }
      label="Regra ativa?"
    />

    <ModalButton>
      {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

      <Button
        height="35px"
        onClick={() => {
          updateBlacklistQuotation({
            setLoading,
            setUserPartnerCommissionInfo,
            userPartnerCommissionInfo,
            setOpenModalEdit,
            loadData,
            setCurrentPage,
            setUsercommissionsList,
            setHasNextPage,
            setTotalCount,
            setTouchFilter,
            loadingDataTable,
            setLoadingDataTable,
          });
        }}
        disabled={loading}
      >
        Editar
      </Button>
    </ModalButton>
  </Dialog>
);

const renderAddBlacklistQuotation = ({
  openModalAdd,
  setOpenModalAdd,
  userPartners,
  userPartnerCommissionInfo,
  setUserPartnerCommissionInfo,
  loading,
  setLoading,
  userPartnerInfo,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
}) => (
  <Dialog
    open={openModalAdd}
    onClose={() => {
      setOpenModalAdd(false);
      setUserPartnerCommissionInfo({
        enabled: false,
      });
    }}
    width="100%"
    title="Adicionar trava"
  >
    <Select
      height="30px"
      label="Escolher o cliente"
      // value={userPartnerCommissionInfo.client_id}
      // onChange={(e) => setUserPartnerCommissionInfo({ ...userPartnerCommissionInfo, client_id: e.target.value })}
    >
      <option value="" disabled selected>
        Selecione o cliente
      </option>

      {userPartners.map((partner) => (
        <option key={partner.id} value={partner.id}>
          {partner.name} - {partner.cpf}
        </option>
      ))}
    </Select>

    <p
      style={{
        fontSize: '12px',
      }}
    >
      Comissão
    </p>
    <div
      style={{
        display: 'flex',
        margin: '0 auto',
        width: '95%',
      }}
    >
      <SliderContent
        track={false}
        aria-labelledby="track-false-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={(e) => {
          return `${Math.round(e)}%`;
        }}
        getAriaValueText={(value) => `${value}`}
        // defaultValue={grievanceValue || 0}
        // defaultValue={userPartnerCommissionInfo.percent || 0}
        // value={userPartnerCommissionInfo.percent}
        // onChange={(event) => {
        //   setUserPartnerCommissionInfo({
        //     ...userPartnerCommissionInfo,
        //     percent: event.target.value,
        //   });
        // }}
        onChangeCommitted={() => {
          // setDiscountValue(0);
          // setQuotationData({ ...quotationData, grievance_percent: grievanceValue, discount_percent: discountValue });
        }}
        min={0}
        // max={userPartnerInfo.commercialization - userPartnerInfo.loading}
        step={1}
        // marks={[
        //   {
        //     value: 0,
        //     label: `0%`,
        //   },
        //   {
        //     value: userPartnerInfo.commercialization - userPartnerInfo.loading,
        //     label: `${userPartnerInfo.commercialization - userPartnerInfo.loading}%`,
        //   },
        // ]}
      />
    </div>

    <CustomFormControlLabel
      control={
        <Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
          style={{ color: primary }}
          // checked={userPartnerCommissionInfo.enabled}
          // onChange={(e) => {
          //   setUserPartnerCommissionInfo({
          //     ...userPartnerCommissionInfo,
          //     enabled: e.target.checked,
          //   });
          // }}
        />
      }
      label="Regra ativa?"
    />

    <ModalButton>
      {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

      <Button
        height="35px"
        onClick={() => {
          createBlacklistQuotation({
            setLoading,
            setUserPartnerCommissionInfo,
            userPartnerCommissionInfo,
            setOpenModalAdd,
            setCurrentPage,
            setUsercommissionsList,
            setHasNextPage,
            setTotalCount,
            setTouchFilter,
            setUserPartners,
            setUserPartnerInfo,
            loadingDataTable,
            setLoadingDataTable,
          });
        }}
        disabled={loading}
      >
        Adicionar
      </Button>
    </ModalButton>
  </Dialog>
);

const renderRemoveBlacklistQuotation = ({
  openModalRemove,
  setOpenModalRemove,
  userPartnerCommissionInfo,
  setUserPartnerCommissionInfo,
  loading,
  setLoading,
  setCurrentPage,
  setUsercommissionsList,
  setHasNextPage,
  setTotalCount,
  setTouchFilter,
  setUserPartners,
  setUserPartnerInfo,
  loadingDataTable,
  setLoadingDataTable,
}) => (
  <ConfirmDialog
    open={openModalRemove}
    onCancel={() => {
      setOpenModalRemove(false);
      setUserPartnerCommissionInfo({
        ...userPartnerCommissionInfo,
        client_id: undefined,
      });
    }}
    content="Tem certeza de que deseja excluir essa trava?"
    title="Excluir trava"
    onConfirm={() => {
      handleRemoveBlacklistQuotation({
        setLoading,
        setUserPartnerCommissionInfo,
        userPartnerCommissionInfo,
        setOpenModalRemove,
        setCurrentPage,
        setUsercommissionsList,
        setHasNextPage,
        setTotalCount,
        setTouchFilter,
        setUserPartners,
        setUserPartnerInfo,
        loadingDataTable,
        setLoadingDataTable,
      });
    }}
    loading={loading}
  />
);

export { renderAddBlacklistQuotation, renderRemoveBlacklistQuotation, renderEditBlacklistQuotation };
