/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from 'api';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';
import BackofficeMenu from 'components/BackofficeMenu';
import { Header, BilletItem } from 'components';
import { Container, ContentArea, Title, ListPaymentStatus, EmptyArea, LoadingArea, TitleArea } from './styles';
import { loadingColor } from 'styles/colorProvider';
import emptyAnimation from 'assets/empty.json';
import 'moment/locale/pt-br';

function BackofficeBilletPolicy({ location }) {
  const policyId = parseInt(location.pathname.split('/backoffice/boletos-apolice/')[1]);
  const [loading, setLoading] = useState(false);
  const [chargeData, setChargeData] = useState([]);

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'GET',
      url: `/list-billets-policy/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });
    setChargeData(resp.data);

    setLoading(false);
  };

  useEffect(() => loadData(), []);

  return (
    <>
      <Header />
      <Container>
        <ContentArea>
          <BackofficeMenu policyId={policyId} />
          <TitleArea>
            <Title>BOLETOS DA APÓLICE</Title>
          </TitleArea>
          <ListPaymentStatus>
            {loading && (
              <LoadingArea>
                <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
              </LoadingArea>
            )}

            {!loading &&
              chargeData?.map((charge) => (
                <BilletItem
                  noRedirect
                  id={charge?.id}
                  status={charge?.status}
                  month={charge?.created_at}
                  type={charge?.type}
                  value={charge?.value}
                  message={charge?.due_at}
                  clickable={false}
                  billet={true}
                  data={charge}
                />
              ))}

            {!loading && !chargeData.length && (
              <EmptyArea>
                <Lottie
                  autoplay
                  style={{ marginTop: 50, opacity: 0.6 }}
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: emptyAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  height={140}
                  width={240}
                />
                <p>Nenhum boleto encontrado.</p>
              </EmptyArea>
            )}
          </ListPaymentStatus>
        </ContentArea>
      </Container>
    </>
  );
}

export default BackofficeBilletPolicy;
