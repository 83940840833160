/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import CsvDownload from 'react-json-to-csv';
import { RiFileExcel2Fill } from 'react-icons/ri';
import api from 'api';
import axios from 'axios';
import { Header, SideMenu, ProviderItem, Dialog, Search, Button, Input, ConfirmDialog, Select } from 'components';
import DeleteButton from 'components/DeleteButton/index';
import EditButton from 'components/EditButton/index';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton, ErrorMsg } from './styles';
import { error, loadingColor, primary, white, secondary } from 'styles/colorProvider';
import { toast } from 'react-toastify';

// ProviderList components
import { EditProviderModal } from './components/EditProviderModal';

function ProviderList() {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportStatus, setExportStatus] = useState();
  const [exportData, setExportData] = useState();
  const [providerList, setProviderList] = useState([]);
  const [provider, setProvider] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [document, setDocument] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [openExport, setOpenExport] = useState(false);

  const loadData = async () => {
    setLoading(true);
    setCurrentPage(1);
    setProvider(false);
    setItem(false);
    setOpenEdit(false);
    const resp = await api({
      method: 'GET',
      url: `/list-providers`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    });
    setProviderList(resp?.data?.response);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);

      const resp = await api({
        method: 'GET',
        url: `/list-providers`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        json: true,
      });

      const data = providerList;
      const ndata = data.concat(resp?.data?.response);

      setCurrentPage(page);
      setProviderList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  /*----------CREATE PROVIDER----------*/
  const handleCreate = async (e) => {
    setLoadingCreate(true);
    try {
      await api({
        method: 'POST',
        url: `/create-provider`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        data: {
          name,
          phone,
          email,
          document,
          address: {
            zip_code: cep.replaceAll(' ', '').replaceAll('-', '').replaceAll('.', ''),
            city: address.localidade,
            street: address?.logradouro,
            number: number,
            complement: complement,
            district: address.bairro,
            state: address.uf,
          },
        },
        json: true,
      });
      setLoadingCreate(false);
      handleClose();
      toast.success('Parceiro criado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (e) {
      setLoadingCreate(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  /*----------DELETE PROVIDER----------*/
  const handleDeleteProvider = async (e) => {
    setItem(true);
    try {
      await api({
        method: 'DELETE',
        url: `/delete-provider/${item?.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
      });
      handleClose();
      setItem(false);
      toast.success('Parceiro deletado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (e) {
      setItem(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleExport = async () => {
    setLoadingExport(true);

    try {
      const resp = await api({
        method: 'GET',
        url: `/export-providers`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitrisk-authtoken'),
        },
        params: {
          status: !exportStatus || exportStatus === '' || exportStatus === 'all' ? 'undefined' : exportStatus,
        },
        json: true,
      });
      setExportData(resp.data);
      setLoadingExport(false);
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingExport(false);
    }
  };

  const renderExportDialog = () => (
    <Dialog
      open={openExport}
      onClose={() => {
        !loadingExport && setOpenExport(false);
        setExportStatus();
        setExportData();
      }}
      width="50%"
      title="Exportar prestadores"
    >
      {!exportData && (
        <>
          <Button height="35px" style={{ margin: 0, marginTop: 50 }} onClick={handleExport} disabled={loadingExport}>
            {loadingExport ? <ReactLoading color={white} height={25} width={25} type="spin" /> : 'Exportar'}
          </Button>
        </>
      )}

      {exportData && (
        <>
          <p>Seu arquivo está pronto!</p>
          <CsvDownload
            data={exportData}
            style={{
              height: 30,
              width: 200,
              margin: 0,
              cursor: 'pointer',
              borderRadius: 20,
              border: 'none',
              backgroundColor: secondary,
            }}
            filename={`split_risk_prestadores_${new Date().toLocaleDateString()}.csv`}
          >
            Baixar
          </CsvDownload>
        </>
      )}
    </Dialog>
  );

  const handleChangeCep = async (cep) => {
    setCep(cep);
    if (cep.length === 10) {
      const resp = await axios.get(`https://viacep.com.br/ws/${cep?.replaceAll('-', '').replaceAll('.', '')}/json/`);
      setAddress(resp?.data);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setPhone('');
    setCep('');
    setEmail('');
    setAddress('');
    setDocument('');
    setNumber('');
    setComplement('');
  };

  const handleCloseDeleteItem = () => {
    setItem(false);
  };

  const handleCloseProvider = () => {
    // setProvider(false);
    setOpenEdit(false);
  };

  const renderAddProvider = () => (
    <Dialog open={open} onClose={handleClose} width="50%" title="Adicionar parceiro">
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        widht="30%"
        label="Nome do parceiro"
        height="30px"
        placeholder="Empresa ou pessoa"
      />
      <Input
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        widht="30%"
        label="Telefone"
        height="30px"
        placeholder="(__) ____-____"
        mask="(99) 9999-9999"
      />
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        widht="30%"
        label="E-mail"
        height="30px"
        placeholder="email@email.com"
      />
      <Input
        value={document}
        onChange={(e) => setDocument(e.target.value)}
        widht="30%"
        label="CPF ou CNPJ"
        height="30px"
        placeholder="Documento"
      />
      <Input
        value={cep}
        onChange={(e) => handleChangeCep(e.target.value)}
        widht="30%"
        label="CEP"
        mask="99.999-999"
        height="30px"
        placeholder="___.___-___"
      />
      {address && address?.erro && <ErrorMsg>CEP Inválido</ErrorMsg>}
      {address && !address?.erro && (
        <>
          <Input
            value={address?.localidade}
            onChange={(e) => setAddress(e.target.value)}
            widht="30%"
            label="Cidade"
            height="30px"
            disabled
          />
          <Input
            value={address?.bairro}
            onChange={(e) => setAddress(e.target.value)}
            widht="30%"
            label="Bairro"
            name="district"
            height="30px"
            disabled
          />
          <Input
            value={address?.logradouro}
            onChange={(e) => setAddress(e.target.value)}
            widht="30%"
            label="Endereço"
            height="30px"
            name="address"
            disabled
          />
          <Input
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            widht="30%"
            label="Número"
            height="30px"
            type="number"
          />
          <Input
            value={complement}
            onChange={(e) => setComplement(e.target.value)}
            widht="30%"
            label="Complemento"
            height="30px"
          />
        </>
      )}
      <ModalButton>
        <Button
          height="35px"
          style={{ backgroundColor: 'transparent', border: `1px solid ${error}`, color: error }}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          height="35px"
          onClick={handleCreate}
          disabled={
            loadingCreate ||
            !name ||
            !phone ||
            phone.length < 14 ||
            !email ||
            !document ||
            document.length < 11 ||
            document.length > 14 ||
            !complement ||
            !number
          }
        >
          {loadingCreate ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Adicionar'}
        </Button>
      </ModalButton>
    </Dialog>
  );

  const renderDeleteProvider = () => {
    return (
      <ConfirmDialog
        open={item}
        onClose={() => setItem(false)}
        onCancel={handleCloseDeleteItem}
        onConfirm={handleDeleteProvider}
        title="Deseja deletar este parceiro?"
      />
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Header />
      <Container>
        {renderAddProvider()}
        {/* {renderEditProvider()} */}
        <EditProviderModal
          openEdit={openEdit}
          handleCloseProvider={handleCloseProvider}
          provider={provider}
          address={address}
          loadData={loadData}
        />
        {renderDeleteProvider()}
        {renderExportDialog()}
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>PARCEIROS</Title>
            <div className="containerTitle">
              <Button title="Exportar" height="30px" style={{ marginRight: -5 }} onClick={() => setOpenExport(true)}>
                <RiFileExcel2Fill size={18} />
              </Button>
              <Button height="30px" onClick={() => setOpen(true)}>
                Adicionar
              </Button>

              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData();
                }}
                onConfirm={() => loadData()}
              />
            </div>
          </Div>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <div style={{ width: '100%' }}>
                {loading && (
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                )}
                {!loading &&
                  providerList?.map((provider) => (
                    <ProviderItem provider={provider} key={provider.id}>
                      <EditButton
                        provider={provider}
                        onClose={handleCloseProvider}
                        open={openEdit}
                        onClick={() => {
                          setProvider(provider);
                          setOpenEdit(true);
                          setCep(provider?.TB_ADDRESS?.zip_code);
                          setName(provider?.name);
                          setPhone(provider?.phone);
                          setEmail(provider?.email);
                          setDocument(provider?.document);
                          setComplement(provider?.TB_ADDRESS?.complement);
                          setNumber(provider?.TB_ADDRESS?.number);
                        }}
                      />
                      <DeleteButton item={provider} onClick={() => setItem(provider)} />
                    </ProviderItem>
                  ))}
              </div>
            </InfiniteScroll>

            {!loading && !providerList?.length && <p>Nenhum parceiro encontrado!</p>}
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {providerList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default ProviderList;
