import { Select, Button, Dialog, Input } from 'components';
import { ModalButton, FilesContainer } from './styles';
import { ConfirmDialog, UploadFileInput } from 'components/index';
import {
  handleCreateBlacklistQuotation,
  handleRemoveBlacklistQuotation,
  updateBlacklistQuotation,
  handleCreateBlacklistQuotationBatch,
} from './requests';
import React, { useState, useEffect } from 'react';
import { fileTypes } from 'utils/fileTypes';
import exampleBlacklist from 'assets/blacklist_example.xlsx';
import { formatValue, validateInput } from 'utils/masks';
import ReactLoading from 'react-loading';

const RenderAddBlacklistQuotation = ({ loadData, openModalAdd, loadingData, setLoadingData, setOpenModalAdd, loading }) => {
  const [selectedType, setSelectedType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isInputValid, setIsInputValid] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    setIsInputValid(validateInput(value, selectedType));
  };

  return (
    <Dialog open={openModalAdd} width="100%" title="Adicionar novo item">
      <Select
        height="30px"
        label="Tipo"
        value={selectedType}
        onChange={(e) => {
          setSelectedType(e.target.value);
          setInputValue('');
          setIsInputValid(false);
        }}
      >
        <option value="" disabled selected>
          Selecione o tipo
        </option>
        <option value="document">Documento</option>
        <option value="phone">Telefone</option>
        <option value="email">Email</option>
        <option value="plate">Placa</option>
      </Select>

      <Input
        value={formatValue(inputValue, selectedType)}
        onChange={handleInputChange}
        label="Valor"
        height="30px"
        placeholder="Insira o valor"
      />
      {selectedType === 'email' && !isInputValid && inputValue && (
        <p style={{ color: 'red' }}>Por favor, insira um email válido.</p>
      )}
      {selectedType === 'plate' && !isInputValid && inputValue && (
        <p style={{ color: 'red' }}>Por favor, insira uma placa válida.</p>
      )}

      <ModalButton>
        <Button
          height="35px"
          outlined
          onClick={() => {
            setInputValue('');
            setOpenModalAdd(false);
          }}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          height="35px"
          onClick={() => {
            handleCreateBlacklistQuotation({
              loadData,
              setInputValue,
              setOpenModalAdd,
              loading,
              type: selectedType,
              value: inputValue,
              loadingData,
              setLoadingData,
            });
          }}
          disabled={loading || !inputValue || !isInputValid || !selectedType}
          loading={loading}
        >
          {loadingData ? <ReactLoading color="#ffff" height={20} width={20} type="spin" /> : 'Adicionar'}
        </Button>
      </ModalButton>
    </Dialog>
  );
};

const RenderEditBlacklistQuotation = ({
  id,
  type,
  value,
  openModalEdit,
  setOpenModalEdit,
  loading,
  setLoading,
  loadData,
  loadingData,
  setLoadingData,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isInputValid, setIsInputValid] = useState(false);

  const formatType = {
    document: 'Documento',
    plate: 'Placa',
    chassi: 'Chassi',
    email: 'Email',
    phone: 'Telefone',
  };

  useEffect(() => {
    setIsInputValid(validateInput(value, type));
  }, [value, type]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    setIsInputValid(validateInput(value, type));
  };

  return (
    <Dialog open={openModalEdit} width="550px" title="Editar item">
      <div>
        <label style={{ fontWeight: 'bold' }}>Tipo: </label>
        <span style={{ height: '35px', display: 'block' }}>{formatType[type]}</span>
      </div>

      <div>
        <label style={{ fontWeight: 'bold' }}>Valor atual: </label>
        <span style={{ height: '35px', display: 'block' }}>{formatValue(value, type)}</span>
      </div>

      <div>
        <label style={{ fontWeight: 'bold' }}>Novo valor: </label>
        <Input value={formatValue(inputValue, type)} onChange={handleInputChange} placeholder="Insira o novo valor" />
        {type === 'email' && !isInputValid && inputValue && <p style={{ color: 'red' }}>Por favor, insira um email válido.</p>}
        {type === 'plate' && !isInputValid && inputValue && <p style={{ color: 'red' }}>Por favor, insira uma placa válida.</p>}
      </div>

      <ModalButton>
        <Button
          height="35px"
          outlined
          onClick={() => {
            setInputValue('');
            setOpenModalEdit(false);
          }}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          height="35px"
          onClick={() => {
            updateBlacklistQuotation({
              id,
              value: inputValue,
              setLoading,
              setOpenModalEdit,
              loadData,
              loadingData,
              setLoadingData,
              setInputValue
            });
          }}
          disabled={loading || !inputValue || !isInputValid}
          loading={loading}
        >
          {loadingData ? <ReactLoading color="#ffff" height={20} width={20} type="spin" /> : 'Editar'}
        </Button>
      </ModalButton>
    </Dialog>
  );
};

const RenderAddBlacklistBatchQuotation = ({
  loadData,
  openModalAddBatch,
  setOpenModalAddBatch,
  loading,
  setLoading,
  setLoadingData,
}) => {
  const [file, setFile] = useState(null);
  const [removeFile, setRemoveFile] = useState(false);

  const handleFileSelect = (selectedFile) => {
    setFile(selectedFile);
  };

  const handleFileRemove = () => {
    setFile(null);
    setRemoveFile(true);
  };

  return (
    <Dialog open={openModalAddBatch} width="100%" title="Adicionar itens">
      <h5 style={{ marginTop: '10px', color: '#666' }}>Os arquivos aceitos são dos seguintes formatos: XLS, XLSX e CSV.</h5>
      <h5 style={{ marginTop: '10px', color: '#666' }}>
        Por favor, insira os valores com a devida pontuação. Por exemplo, 000.000.000-00.
      </h5>
      <h5 style={{ marginTop: '-8px', color: '#666' }}>Números inseridos sem pontuação serão ignorados.</h5>

      <h5 style={{ marginTop: '-10px', color: '#666' }}>
        Disponibilizamos um modelo padrão para download,{' '}
        <a href={exampleBlacklist} download style={{ color: '#0000EE', textDecoration: 'underline', cursor: 'pointer' }}>
          clique aqui
        </a>
      </h5>

      <FilesContainer style={{ marginLeft: '15%' }}>
        <UploadFileInput
          buttonColor="white"
          required
          label="Enviar arquivo"
          text="Selecione o arquivo"
          onFileSelect={handleFileSelect}
          onFileRemove={handleFileRemove}
          acceptedExtensions={[fileTypes.xls, fileTypes.xlsx, fileTypes.csv]}
        />
      </FilesContainer>

      <ModalButton>
        <Button
          height="35px"
          outlined
          onClick={() => {
            setOpenModalAddBatch(false);
          }}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          height="35px"
          onClick={() => {
            handleCreateBlacklistQuotationBatch({
              setOpenModalAddBatch,
              loadData,
              setLoading,
              file,
            });
          }}
          disabled={loading}
          loading={loading}
        >
          Confirmar
        </Button>
      </ModalButton>
    </Dialog>
  );
};

export default RenderAddBlacklistBatchQuotation;

const renderRemoveBlacklistQuotation = ({
  id,
  openModalRemove,
  setOpenModalRemove,
  loading,
  setLoading,
  loadData,
  loadingData,
  setLoadingData,
}) => (
  <ConfirmDialog
    open={openModalRemove}
    confirmLabel={loadingData ? <ReactLoading color="#ffff" height={20} width={20} type="spin" /> : 'Confirmar'}
    onCancel={() => {
      setOpenModalRemove(false);
    }}
    content="Tem certeza de que deseja excluir?"
    title="Excluir item"
    onConfirm={() => {
      handleRemoveBlacklistQuotation({
        id,
        loadData,
        setLoading,
        setOpenModalRemove,
        loadingData,
        setLoadingData,
      });
    }}
    loading={loading}
  />
);

export {
  RenderAddBlacklistQuotation,
  renderRemoveBlacklistQuotation,
  RenderEditBlacklistQuotation,
  RenderAddBlacklistBatchQuotation,
};
