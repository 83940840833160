import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { BsClipboardData } from 'react-icons/bs';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { Container, Div, Title, Subtitle, Icon, IconMobile } from './styles';
import { useHistory } from 'react-router-dom';
import { formatCPF } from 'react-data-formatter';
import { formatAgentStatus } from 'utils/agentStatus';


function AgentItem({ agent }) {
    const history = useHistory();

    const navigate = async (e) => {
        e.preventDefault();
        history.push(`/backoffice/detalhes-do-corretor/${agent?.id}`);
    };

    return (
        <>
            <Container onClick={navigate}>
                <div className="description">
                    <div className="iconCar">
                        <BsClipboardData size={23} />
                    </div>

                    <Div>
                        <Title>{agent?.name}</Title>
                        <Subtitle>CNPJ:{formatCPF(agent?.cnpj)}</Subtitle>
                    </Div>

                    <Div>
                        <Title color={formatAgentStatus(agent?.status)?.color}> {formatAgentStatus(agent?.status)?.label}</Title>
                        <Subtitle>Cadastro criado em: {moment(agent?.created_at).format('DD/MM/YYYY')}</Subtitle>
                    </Div>

                    <Icon>
                        <HiOutlineArrowNarrowRight color="#C4C4C4" size={25} className="iconArrow" />
                    </Icon>

                </div>
            </Container>
        </>
    );
}

export default AgentItem;
