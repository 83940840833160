/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import api from 'api';
import { toast } from 'react-toastify';
import { TiFilter } from 'react-icons/ti';
import { Header, SideMenu, Select, Search, Button, Dialog, Input } from 'components';
import { Container, ContentArea, Title, Div, DataArea, LoadingArea, CountArea, ModalButton, WidgetArea, Widget } from './styles';
import { loadingColor, primary } from 'styles/colorProvider';
import { dataColumns } from './helper';

function SupportList({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [data, setData] = useState([]);
  const [createData, setCreateData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [open, setOpen] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [search, setSearch] = useState();
  const [selectStatus, setSelectStatus] = useState('all');

  const [touchFilter, setTouchFilter] = useState();

  const loadData = async () => {
    setLoading(true);
    setCurrentPage(1);
    const resp = await api({
      method: 'GET',
      url: `/list-tickets`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      params:{
        search,
        status: selectStatus === 'all' ? undefined : selectStatus,
        page: 1,
        take: 30,
      },
      json: true,
    });
    setData(resp?.data?.response);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
    setTouchFilter(false);
  };

  const handleLoadMore = async () => {
    if (!touchFilter) {
      const page = currentPage + 1;
      if (hasNextPage && !loadingMore) {
        setLoadingMore(true);
        const resp = await api({
          method: 'GET',
          url: `/list-tickets`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: Cookies.get('splitrisk-authtoken'),
          },
          params:{
            search,
            status: selectStatus === 'all' ? undefined : selectStatus,
            page,
            take: 10,
          },
          json: true,
        });
        const data = data;
        const ndata = data.concat(resp?.data?.proposals);
        setCurrentPage(page);
        setData(ndata);
        setHasNextPage(resp?.data?.hasNextPage);
        setLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleOpenFilter = () => {
    setOpen(true);
  };

  const renderFilterDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} width="50%" title="Filtrar chamados">
      <Select height="30px" label="Filtrar por status" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
        <option value={'all'}>Todos os Status</option>
        <option value={'pending'}>Aberto</option>
        <option value={'in_attendance'}>Em atendimento</option>
        <option value={'finished'}>Finalizado</option>
      </Select>

      <ModalButton style={{ marginTop: 30 }}>
        <Button
          height="35px"
          onClick={() => {
            setTouchFilter(true);
            loadData();
            setOpen(false);
          }}
        >
          Aplicar alterações
        </Button>
      </ModalButton>
    </Dialog>
  );

  const handleCreate = async () => {
    setLoadingCreate(true);
    await api({
      method: 'POST',
      url: `/create-ticket`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      data: {
        client_name: createData?.client_name,
        description: createData?.description,
      },
      json: true,
    });
    toast.success('Solicitação registrada com sucesso!', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
    setLoadingCreate(false);
    setCreateData(false);
    setOpenRegister();
    loadData();
  };

  const renderAddDialog = () => (
    <Dialog open={openRegister} onClose={() => setOpenRegister(false)} width="50%" title="Registrar chamado">
      <Input
        value={createData?.client_name}
        onChange={(e) => setCreateData({ ...createData, client_name: e.target.value?.toUpperCase() })}
        label="Nome do solicitante"
        height="30px"
        width="280px"
      />

      <TextareaAutosize
        minRows={1}
        value={createData?.description}
        onChange={(e) => setCreateData({ ...createData, description: e.target.value })}
        placeholder="Insira a descrição da solicitação..."
        style={{
          padding: 10,
          fontFamily: 'Nunito',
          fontSize: 14,
          width: '280px',
          height: '100px',
          borderRadius: 10,
          borderColor: '#d1d2d4',
          marginTop: 20,
          outline: 'none',
        }}
      />

      <ModalButton>
        <Button
          style={{ marginTop: 30 }}
          height="35px"
          onClick={handleCreate}
          loading={loadingCreate}
          disabled={!createData?.client_name || !createData?.description}
        >
          {loadingCreate ? <ReactLoading color={'#fff'} height={24} width={24} type="spin" /> : 'Registrar solicitação'}
        </Button>
      </ModalButton>
    </Dialog>
  );

  return (
    <>
      <Header />
      <Container>
        {renderFilterDialog()}
        {renderAddDialog()}
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>SUPORTE</Title>
            <div className="containerTitle">
              <Button title="Filtrar" height="30px" onClick={() => setOpenRegister(true)}>
                {/* <MdAddBox size={18} /> */}
                Registrar
              </Button>

              <Button title="Registrar chamado" height="30px" onClick={handleOpenFilter}>
                <TiFilter size={18} />
              </Button>

              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  loadData();
                }}
                onConfirm={() => loadData()}
              />
            </div>
          </Div>

          {!loading && (
            <WidgetArea>
              <Widget>
                <div className="info">
                  <h1>{data?.filter((i) => i.status === 'pending')?.length}</h1>
                  <p>Ag. atendimento</p>
                </div>
              </Widget>
              <Widget>
                <div className="info">
                  <h1>{data?.filter((i) => i.status === 'in_attendance')?.length}</h1>
                  <p>Em atendimento</p>
                </div>
              </Widget>
              <Widget>
                <div className="info">
                  <h1>{data?.filter((i) => i.status === 'finished')?.length}</h1>
                  <p>Finalizado</p>
                </div>
              </Widget>
            </WidgetArea>
          )}
          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={10}
            >
              <DataTable
                onRowClicked={(row) => history.push(`/backoffice/suporte/${row?.id}`)}
                persistTableHead
                responsive
                columns={dataColumns}
                data={data}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhum chamado encontrado</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {data?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default SupportList;
