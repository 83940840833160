/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import ReactLoading from 'react-loading';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import axios from 'axios';
import api from 'api';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { isBirthDateValid } from 'utils/birthDate';
import { saveFilesBucket } from 'utils/aws';
import { ClaimFileItem } from 'components';
import { Container, ContainerArea, Left, Right, ContainerUpload, Form } from './styles';
import { primary } from 'styles/colorProvider';

function AgentRegistration({ location }) {
  const fileInputRefSusep = useRef();
  const fileInputRefCpf = useRef();
  const fileInputRefRg = useRef();
  const fileInputRefCnpj = useRef();
  const fileInputRefBank = useRef();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [questionOne, setQuestionOne] = useState('');
  const [personal, setPersonal] = useState('');
  const [address, setAddress] = useState(null);
  const [bank, setBank] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [upFile, setupFile] = useState(null);
  const [upFileCnpj, setupFileCnpj] = useState(null);
  const [upFileRg, setupFileRg] = useState(null);
  const [upFileCpf, setupFileCpf] = useState(null);
  const [upFileBank, setupFileBank] = useState(null);

  const [document, setDocument] = useState();
  const [showWelcome, setShowWelcome] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingSearchPartner, setLoadingSearchPartner] = useState(false);

  const formatBirthdate = (date) => {
    const [day, month, year] = date?.split('/');
    return `${year}-${month}-${day}`;
  };

  const handleChangeCep = async (zip_code) => {
    setZipCode(zip_code);
    if (zip_code.length === 10) {
      const resp = await axios.get(`https://viacep.com.br/ws/${zip_code?.replaceAll('-', '').replaceAll('.', '')}/json/`);
      setAddress(resp?.data);
    } else {
      const defaultAddress = {
        bairro: '',
        zip_code,
        complemento: '',
        ddd: '',
        gia: '',
        ibge: '',
        localidade: '',
        logradouro: '',
        siafi: '',
        uf: '',
      };
      setAddress(defaultAddress);
    }
  };

  const handleAddFile = (e) => {
    const files = e.target.files[0];
    const description = 'Titulo habilitação SUSEP';
    const ext = files.name.replaceAll(' ', '').toLowerCase().split('.').pop();

    saveFilesBucket({
      Bucket: process.env.AWS_CLAIMS_FILES_BUCKET,
      Body: files,
      Key: `/${files.type}/${new Date().getTime()}.${ext}`,
      onEnd: async (err, data) => {
        const list = { type: files?.type, description: description, url: data?.file, name: files?.name };
        setupFile(list);
      },
    });
  };

  const handleRemoveFile = (e) => {
    setupFile(null);
  };

  const handleAddFileCnpj = (e) => {
    const files = e.target.files[0];
    const description = 'CNPJ';
    const ext = files.name.replaceAll(' ', '').toLowerCase().split('.').pop();

    saveFilesBucket({
      Bucket: process.env.AWS_CLAIMS_FILES_BUCKET,
      Body: files,
      Key: `/${files.type}/${new Date().getTime()}.${ext}`,
      onEnd: async (err, data) => {
        const list = { type: files?.type, description: description, url: data?.file, name: files?.name };
        setupFileCnpj(list);
      },
    });
  };

  const handleRemoveFileCnpj = (e) => {
    setupFileCnpj(null);
  };

  const handleAddFileRg = (e) => {
    const files = e.target.files[0];
    const description = 'RG';
    const ext = files.name.replaceAll(' ', '').toLowerCase().split('.').pop();

    saveFilesBucket({
      Bucket: process.env.AWS_CLAIMS_FILES_BUCKET,
      Body: files,
      Key: `/${files.type}/${new Date().getTime()}.${ext}`,
      onEnd: async (err, data) => {
        const list = { type: files?.type, description: description, url: data?.file, name: files?.name };
        setupFileRg(list);
      },
    });
  };

  const handleRemoveFileRg = (e) => {
    setupFileRg(null);
  };

  const handleAddFileCpf = (e) => {
    const files = e.target.files[0];
    const description = 'CPF';
    const ext = files.name.replaceAll(' ', '').toLowerCase().split('.').pop();

    saveFilesBucket({
      Bucket: process.env.AWS_CLAIMS_FILES_BUCKET,
      Body: files,
      Key: `/${files.type}/${new Date().getTime()}.${ext}`,
      onEnd: async (err, data) => {
        const list = { type: files?.type, description: description, url: data?.file, name: files?.name };
        setupFileCpf(list);
      },
    });
  };

  const handleRemoveFileCpf = (e) => {
    setupFileCpf(null);
  };

  const handleAddFileBank = (e) => {
    const files = e.target.files[0];
    const description = 'Bank';
    const ext = files.name.replaceAll(' ', '').toLowerCase().split('.').pop();

    saveFilesBucket({
      Bucket: process.env.AWS_CLAIMS_FILES_BUCKET,
      Body: files,
      Key: `/${files.type}/${new Date().getTime()}.${ext}`,
      onEnd: async (err, data) => {
        const list = { type: files?.type, description: description, url: data?.file, name: files?.name };
        setupFileBank(list);
      },
    });
  };

  const handleRemoveFileBank = (e) => {
    setupFileBank(null);
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    const data = {
      name: personal?.name,
      trade_name: personal?.tradeName,
      cnpj: personal?.cnpj,
      susep_number: personal?.susep,
      phone: personal?.phone,
      email: personal?.email,
      birthdate: formatBirthdate(personal?.birthdate),
      address: {
        zip_code: address?.cep,
        street: address?.logradouro,
        number: address?.number,
        complement: address?.complemento,
        district: address?.bairro,
        city: address?.localidade,
        state: address?.uf,
      },
      bank: {
        bank_name: bank?.bank_name,
        agency_code: bank?.agency_code,
        current_account: bank?.current_account,
        digit: bank?.digit,
      },
      questions: [
        {
          label: 'Já trabalha ou trabalhou com seguros? Por favor, relate sua experiência:',
          value: questionOne,
        },
      ],
      documents: [
        {
          type: 'Habilitação SUSEP',
          url: upFile?.url,
        },
        {
          type: 'RG',
          url: upFileRg?.url,
        },
        {
          type: 'CPF',
          url: upFileCpf?.url,
        },
        {
          type: 'Cartão CNPJ',
          url: upFileCnpj?.url,
        },
        {
          type: 'Dados bancários',
          url: upFileBank?.url,
        },
      ],
    };

    try {
      await api({
        method: 'POST',
        url: `/create-agent`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
        json: true,
      });
      setLoadingSubmit(false);
      window.location.reload();
      toast.success('Cadastro enviado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (error) {
      setLoadingSubmit(false);
      toast.error('Erro ao enviar cadastro!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleSearchPartner = async () => {
    setLoadingSearchPartner(true);

    try {
      const resp = await api({
        method: 'POST',
        url: `/get-partner-lead`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { document },
        json: true,
      });
      setPersonal({
        ...personal,
        name: resp?.data?.name,
        cnpj: resp?.data?.document,
        phone: resp?.data?.owner_phone,
        email: resp?.data?.owner_mail,
      });
      setLoadingSearchPartner(false);
      setShowWelcome(false);
      setShowForm(true);
    } catch (error) {
      setLoadingSearchPartner(false);
      setShowWelcome(false);
      setShowError(true);
    }
  };

  return (
    <>
      <Container>
        <Left />

        <Right>
          <div className="areaLogo">
            <div className="logo" />
          </div>

          <h2 style={{ marginTop: 30, textAlign: 'center', color: primary }}>Cadastro de corretores</h2>
          {!showError && (
            <p style={{ width: '60%', textAlign: 'center', marginTop: 0 }}>
              Olá, corretor(a)! É um prazer ter você aqui. Para completar o seu cadastro, por favor, preencha as informações
              abaixo.
            </p>
          )}

          {showError && (
            <>
              <h3 style={{ color: primary }}>Oops!</h3>
              <p style={{ width: '60%', textAlign: 'center', marginTop: 0 }}>
                No momento não podemos continuar com o seu cadastro. Por favor, entre em contato conosco pelo e-mail{' '}
                <a href="mailto:contato@splitrisk.com.br">contato@splitrisk.com.br</a>.
              </p>
            </>
          )}

          {showWelcome && (
            <div
              style={{ width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <p style={{ marginTop: 20, textAlign: 'center', fontSize: 14 }}>Para começar, por favor, insira seu CPF ou CNPJ:</p>
              <Input numeric height="30px" value={document} onChange={(e) => setDocument(e.target.value)} />
              <Button
                loading={loadingSearchPartner}
                onClick={handleSearchPartner}
                disabled={loadingSearchPartner || !document || document?.length < 11}
                height="30px"
              >
                {loadingSearchPartner ? <ReactLoading color={primary} height={25} width={25} type="spin" /> : 'Continuar'}
              </Button>
            </div>
          )}

          {showForm && (
            <div className="content">
              <h4 style={{ widht: '100%', marginTop: '30px', textAlign: 'center', marginLeft: '25px', color: primary }}>
                Dados pessoais
              </h4>

              <Form>
                <div className="grid-items">
                  <Input
                    label="Nome/Razão Social"
                    height="30px"
                    placeholder="Insira a razão social"
                    value={personal?.name}
                    onChange={(e) => setPersonal({ ...personal, name: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="Nome Fantasia (Opcional)"
                    height="30px"
                    placeholder="Insira o nome fantasia"
                    value={personal?.tradeName}
                    onChange={(e) => setPersonal({ ...personal, tradeName: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="CPF/CNPJ"
                    height="30px"
                    mask={'99.999.999/9999-99'}
                    placeholder="Insira o CPF ou CNPJ"
                    value={personal?.cnpj}
                    onChange={(e) => setPersonal({ ...personal, cnpj: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="SUSEP Oficial"
                    height="30px"
                    placeholder="Inscrição SUSEP"
                    value={personal?.susep}
                    onChange={(e) => setPersonal({ ...personal, susep: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="E-mail"
                    height="30px"
                    placeholder="Insira seu e-mail"
                    value={personal?.email}
                    onChange={(e) => setPersonal({ ...personal, email: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="Telefone"
                    height="30px"
                    placeholder="Telefone pra contato"
                    mask="(99) 99999-9999"
                    value={personal?.phone}
                    onChange={(e) => setPersonal({ ...personal, phone: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="Data de nascimento"
                    height="30px"
                    placeholder="Digite sua data de nascimento"
                    mask="99/99/9999"
                    value={personal?.birthdate}
                    onChange={(e) => setPersonal({ ...personal, birthdate: e.target.value })}
                    success={isBirthDateValid(personal?.birthdate)}
                    error={personal?.birthdate && !isBirthDateValid(personal?.birthdate)}
                  />
                </div>
              </Form>

              <div className="div-separator" />

              <h4 style={{ widht: '100%', marginTop: '30px', textAlign: 'center', marginLeft: '25px', color: primary }}>
                Endereço fiscal/tributário
              </h4>

              <Form>
                <div className="grid-items">
                  <Input
                    label="CEP"
                    height="30px"
                    placeholder="Insira o CEP"
                    mask={'99.999-999'}
                    helperText={zip_code ? 'opa' : null}
                    value={zip_code}
                    onChange={(e) => handleChangeCep(e.target.value)}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    height="30px"
                    label="Logradouro"
                    value={address?.logradouro}
                    onChange={(e) => setAddress({ ...address, logradouro: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    height="30px"
                    label="Bairro"
                    value={address?.bairro}
                    onChange={(e) => setAddress({ ...address, bairro: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    height="30px"
                    label="Cidade"
                    value={address?.localidade}
                    onChange={(e) => setAddress({ ...address, localidade: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    height="30px"
                    label="UF"
                    value={address?.uf}
                    onChange={(e) => setAddress({ ...address, uf: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    height="30px"
                    label="Número"
                    placeholder="Insira o número"
                    value={address?.number}
                    onChange={(e) => setAddress({ ...address, number: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    height="30px"
                    label="Complemento"
                    placeholder="Insira o complemento"
                    value={address?.complemento}
                    onChange={(e) => setAddress({ ...address, complemento: e.target.value })}
                  />
                </div>
              </Form>

              <div className="div-separator" />

              <h4 style={{ widht: '100%', marginTop: '30px', textAlign: 'center', marginLeft: '25px', color: primary }}>
                Dados Bancários
              </h4>
              <Form>
                <div className="grid-items">
                  <Input
                    label="Banco"
                    height="30px"
                    placeholder="Insira o nome do Banco"
                    value={bank?.bank_name}
                    onChange={(e) => setBank({ ...bank, bank_name: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="Código da agência"
                    height="30px"
                    placeholder="Insira o código da agência"
                    value={bank?.agency_code}
                    onChange={(e) => setBank({ ...bank, agency_code: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="Conta corrente"
                    height="30px"
                    placeholder="Insira a conta corrente"
                    value={bank?.current_account}
                    onChange={(e) => setBank({ ...bank, current_account: e.target.value })}
                  />
                </div>

                <div className="grid-items">
                  <Input
                    label="Digito da conta"
                    height="30px"
                    placeholder="Insira o digito da conta"
                    value={bank?.digit}
                    onChange={(e) => setBank({ ...bank, digit: e.target.value })}
                  />
                </div>
              </Form>

              <div className="div-separator" />

              <h4 style={{ widht: '100%', marginTop: '30px', textAlign: 'center', marginLeft: '25px', color: primary }}>
                Documentos Complementares
              </h4>

              <div style={{ width: '93%', flexDirection: 'row', flexWrap: 'wrap', display: 'flex' }}>
                <ContainerUpload>
                  <label style={{ color: 'green' }} className="new-third-button">
                    {`Titulo habilitação SUSEP`}{' '}
                  </label>

                  {!upFile && (
                    <AiOutlinePlusCircle
                      size={24}
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRefSusep.current.click();
                      }}
                    />
                  )}

                  {upFile && (
                    <ClaimFileItem
                      data={upFile?.name}
                      onClick={(e) => {
                        handleRemoveFile(e);
                      }}
                    />
                  )}

                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    style={{ display: 'none' }}
                    ref={fileInputRefSusep}
                    onChange={(e) => {
                      handleAddFile(e);
                    }}
                  />
                </ContainerUpload>

                <ContainerUpload>
                  <label style={{ color: 'green' }} className="new-third-button">
                    {`Cartão CNPJ`}{' '}
                  </label>

                  {!upFileCnpj && (
                    <AiOutlinePlusCircle
                      size={24}
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRefCnpj.current.click();
                      }}
                    />
                  )}

                  {upFileCnpj && (
                    <ClaimFileItem
                      data={upFileCnpj?.name}
                      onClick={(e) => {
                        handleRemoveFileCnpj(e);
                      }}
                    />
                  )}

                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    style={{ display: 'none' }}
                    ref={fileInputRefCnpj}
                    onChange={(e) => {
                      handleAddFileCnpj(e);
                    }}
                  />
                </ContainerUpload>

                <ContainerUpload>
                  <label style={{ color: 'green' }} className="new-third-button">
                    {`RG`}
                  </label>

                  {!upFileRg && (
                    <AiOutlinePlusCircle
                      size={24}
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRefRg.current.click();
                      }}
                    />
                  )}

                  {upFileRg && (
                    <ClaimFileItem
                      data={upFileRg?.name}
                      onClick={(e) => {
                        handleRemoveFileRg(e);
                      }}
                    />
                  )}

                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    style={{ display: 'none' }}
                    ref={fileInputRefRg}
                    onChange={(e) => {
                      handleAddFileRg(e);
                    }}
                  />
                </ContainerUpload>

                <ContainerUpload>
                  <label style={{ color: 'green' }} className="new-third-button">
                    {`CPF`}
                  </label>

                  {!upFileCpf && (
                    <AiOutlinePlusCircle
                      size={24}
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRefCpf.current.click();
                      }}
                    />
                  )}

                  {upFileCpf && (
                    <ClaimFileItem
                      data={upFileCpf?.name}
                      onClick={(e) => {
                        handleRemoveFileCpf(e);
                      }}
                    />
                  )}

                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    style={{ display: 'none' }}
                    ref={fileInputRefCpf}
                    onChange={(e) => {
                      handleAddFileCpf(e);
                    }}
                  />
                </ContainerUpload>

                <ContainerUpload>
                  <label style={{ color: 'green' }} className="new-third-button">
                    {`Dados bancários`}
                  </label>

                  {!upFileBank && (
                    <AiOutlinePlusCircle
                      size={24}
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRefBank.current.click();
                      }}
                    />
                  )}

                  {upFileBank && (
                    <ClaimFileItem
                      data={upFileBank?.name}
                      onClick={(e) => {
                        handleRemoveFileBank(e);
                      }}
                    />
                  )}

                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    style={{ display: 'none' }}
                    ref={fileInputRefBank}
                    onChange={(e) => {
                      handleAddFileBank(e);
                    }}
                  />
                </ContainerUpload>
              </div>

              <div className="div-separator" />

              <h4 style={{ widht: '100%', marginTop: '30px', textAlign: 'center', marginLeft: '25px', color: primary }}>
                Informações adicionais
              </h4>

              <div style={{ flexDirection: 'column', width: '100%', textAlign: 'center' }}>
                <p style={{ fontSize: '13px' }}> Já trabalha ou trabalhou com seguros? Por favor, relate sua experiência:</p>
                <input
                  className="Input-line"
                  style={{
                    border: '1px solid gray',
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderLeftWidth: 0,
                    width: '93%',
                    outline: 'none',
                    paddingBottom: 10,
                  }}
                  value={questionOne}
                  onChange={(e) => setQuestionOne(e.target.value)}
                />
              </div>

              <h6 style={{ marginTop: 35, width: '100%', textAlign: 'center', fontSize: 14 }}>
                Ao submeter o cadastro declaro que as informações preenchidas são verdadeiras. <br /> Qualquer dúvida entrar em
                contato através do email <a href="mailto:contato@splitrisk.com.br">contato@splitrisk.com.br</a>.
              </h6>

              <Button
                disabled={
                  loadingSubmit ||
                  !personal?.name ||
                  !personal?.cnpj ||
                  !personal?.susep ||
                  !personal?.email ||
                  !personal?.birthdate ||
                  !address?.cep ||
                  !address?.logradouro ||
                  !address?.number ||
                  !address?.bairro ||
                  !address?.localidade ||
                  !address?.uf ||
                  !questionOne ||
                  !upFile?.url ||
                  !upFileRg?.url ||
                  !upFileCpf?.url ||
                  !upFileCnpj?.url ||
                  !upFileBank?.url
                }
                height="35px"
                style={{ marginBottom: 30 }}
                onClick={(e) => handleSubmit(e)}
                loading={loadingSubmit}
              >
                {loadingSearchPartner ? (
                  <ReactLoading color={primary} height={25} width={25} type="spin" />
                ) : (
                  'Enviar informações'
                )}
              </Button>

              <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20, color: 'red' }}>
                {!personal?.name && <small>Por favor, informe o seu Nome/Razão Social.</small>}
                {!personal?.cnpj && <small>Por favor, informe o seu CPF/CNPJ.</small>}
                {!personal?.susep && <small>Por favor, informe a sua inscrição SUSEP.</small>}
                {!personal?.email && <small>Por favor, informe o seu e-mail.</small>}
                {!personal?.birthdate && <small>Por favor, informe a sua data de nascimento.</small>}
                {!address?.cep && <small>Por favor, informe o seu CEP.</small>}
                {!address?.logradouro && <small>Por favor, informe o logradouro do seu endereço.</small>}
                {!address?.number && <small>Por favor, informe o número do seu endereço.</small>}
                {!address?.bairro && <small>Por favor, informe o bairro do seu endereço.</small>}
                {!address?.localidade && <small>Por favor, informe a sua cidade.</small>}
                {!address?.uf && <small>Por favor, informe o seu estado.</small>}

                {!bank?.bank_name && <small>Por favor, informe o seu banco.</small>}
                {!bank?.agency_code && <small>Por favor, informe a agência do seu banco.</small>}
                {!bank?.current_account && <small>Por favor, informe o número da sua conta.</small>}
                {!bank?.digit && <small>Por favor, informe o dígito da sua conta.</small>}

                {!questionOne && <small>Por favor, responda à pergunta adicional.</small>}

                {!upFile?.url && <small>Por favor, envie o seu título de habilitação SUSEP.</small>}
                {!upFileCnpj?.url && <small>Por favor, envie o seu CARTÃO CNPJ.</small>}
                {!upFileRg?.url && <small>Por favor, envie o seu RG.</small>}
                {!upFileCpf?.url && <small>Por favor, envie o seu CPF.</small>}
                {!upFileBank && <small>Por favor, envie o seu comprovante de DADOS BANCÁRIOS.</small>}
              </div>
            </div>
          )}
        </Right>
      </Container>
    </>
  );
}

export default AgentRegistration;
